import React, { forwardRef } from 'react';
import { $Label } from './Label.styles';
/**
 * Label component
 */
const Label = forwardRef(({ children, color = 'primary', text, banner = false, size = 'base', ...props }, ref) => {
    return (React.createElement($Label, { c: color, banner: banner, size: size, ref: ref, ...props }, text || children));
});
Label.displayName = 'Label';
export default Label;
