import { Flex, Checkbox as RadixCheckbox, Strong, Text, } from '@radix-ui/themes';
import React from 'react';
export const Checkbox = (props) => {
    if (props.label) {
        return (React.createElement(Text, { as: "label", style: { cursor: 'pointer' }, size: "2" },
            React.createElement(Flex, { align: "center", as: "span", gap: "2" },
                React.createElement(Flex, { gap: "2" },
                    React.createElement(RadixCheckbox, { "aria-label": props.label, style: { cursor: 'pointer' }, ...props }),
                    React.createElement(Flex, { direction: "column" },
                        React.createElement(Strong, null, props.label),
                        props.legend && (React.createElement(Text, { size: "1", color: "gray" }, props.legend)))))));
    }
    return React.createElement(RadixCheckbox, { ...props, style: { cursor: 'pointer' } });
};
