import {
  PageLayout,
  VisibleWithScope,
  useSnackbarMutations,
} from '@aignostics/components';
import { OrganizationRole } from '@aignostics/core';
import { WsiSlideMetadata } from '@aignostics/extract-wsi-metadata';
import React, { ReactElement, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { type PairUploadLink } from './SetFileMetadataStep';
import { UploadSlidesSteps } from './UploadSlides/UploadSlidesSteps.component';

export interface BlobFileWithChecksumAndMetadata {
  checksum: string;
  file: File;
  metadata: WsiSlideMetadata;
}
export type UploadSlidesStep =
  | {
      name: 'calculateChecksum';
      files: File[];
      csv: File | undefined;
      title: 'Calculate checksum';
    }
  | {
      name: 'setFileMetadata';
      csv: File | undefined;
      files: BlobFileWithChecksumAndMetadata[];
      title: 'Slides metadata';
    }
  | {
      name: 'uploadFiles';
      files: BlobFileWithChecksumAndMetadata[];
      title: 'Upload';
      csv: undefined;
      batchId: string;
      batchName: string;
      filenameToWsiUuids: Map<string, string[]>;
      wsiUuidsToFileIndex: Map<string, number>;
      pairedUploadLinks: PairUploadLink[];
    };

/**
 * Page for admins to upload new Slide files and start the slide onboarding
 * process.
 *
 * NOTE: This is work in progress and not fully functional yet, API endpoints
 * for sending the uploaded files metadata are missing.
 */

type AdminUploadSlidesProps = {
  role: OrganizationRole;
  authToken: string | null;
  apiUrl: string;
  origin: string;
};
export default function AdminUploadSlides({
  role,
  authToken,
  apiUrl,
  origin,
}: AdminUploadSlidesProps): ReactElement {
  const location = useLocation();
  const { addSnackbar } = useSnackbarMutations();

  const files = location.state?.files;
  const csv = location.state?.csv;

  const [step, onStepChanged] = useState<UploadSlidesStep>({
    name: 'calculateChecksum',
    files,
    csv,
    title: 'Calculate checksum',
  });

  if (!Array.isArray(files) || files.length === 0) {
    addSnackbar({
      type: 'warning',
      message:
        'Please select at least one slide file to proceed with the upload process',
    });

    return (
      <Navigate
        to={'/admin/data-onboarding'}
        state={{ shouldOpenDataUploadModal: true }}
      />
    );
  }

  return (
    <PageLayout
      title={step.title}
      description="Please provide information about the slides (you can use the table header to add global values). You may also include a CSV file containing prefilled fields."
    >
      <VisibleWithScope role={role} scope="wsi:upload">
        <UploadSlidesSteps
          origin={origin}
          apiUrl={apiUrl}
          step={step}
          onStepChanged={onStepChanged}
          authToken={authToken}
        />
      </VisibleWithScope>
    </PageLayout>
  );
}
