import { IconButton, Toggle, VStack } from '@aignostics/components';
import { OrganizationRole } from '@aignostics/core';
import {
  AccessorFnColumnDef,
  AccessorKeyColumnDef,
  CellContext,
  HeaderContext,
  createColumnHelper,
} from '@tanstack/react-table';
import React, { useMemo } from 'react';
import { useTheme } from 'styled-components';
import { TiltedHeader } from '../../../../../components';
import { Tagger } from '../../../../../types';
import { StaticOverlayAll } from '../../AdminSubprojectAssignStaticOverlays/AdminSubprojectAssignStaticOverlays.types';
import { AssignUpdates, OverlayAssignment, TableData } from '../Overlays.types';

export interface OverlayNameColumn {
  id: string;
  originalName: string;
  overlayType: 'static' | 'interactive';
  overrideName?: string;
  stage?: Tagger['stage'];
}

function useOverlayColumns(
  overlayNameColumns: OverlayNameColumn[],
  onChange: AssignUpdates,
  userRole: OrganizationRole,
  onDelete?: (interactiveOverlayId: string) => void
): (
  | AccessorKeyColumnDef<TableData<StaticOverlayAll>, string>
  | AccessorFnColumnDef<TableData<StaticOverlayAll>, string>
)[] {
  const theme = useTheme();
  return useMemo(() => {
    const columnHelper = createColumnHelper<TableData<StaticOverlayAll>>();

    return overlayNameColumns.map(
      ({ originalName, overrideName, stage, id, overlayType }, index) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        return columnHelper.accessor(`${originalName}-${index}`, {
          cell: FieldToggle(onChange, originalName, overlayType, id),
          header: ColumnToggle(
            onChange,
            userRole,
            originalName,
            overlayType,
            id,
            stage,
            overrideName,
            onDelete
          ),
          size: theme.spacings.button,
        });
      }
    );
  }, [overlayNameColumns, onChange, onDelete, userRole, theme.spacings.button]);
}

export default useOverlayColumns;

/** Toggle for a full column */
function ColumnToggle(
  onChange: AssignUpdates,
  userRole: OrganizationRole,
  originalName: string,
  overlayType: string,
  overlayId: string,
  stage?: 'dev' | 'prod' | 'debug',
  overrideName?: string,
  onDelete?: (interactiveOverlayName: string) => void
) {
  return function Cell(
    header: HeaderContext<TableData<StaticOverlayAll>, string>
  ): JSX.Element {
    const isInteractiveOverlay = overlayType === 'interactive';

    const availableData = header.header
      .getContext()
      .table.options.data.filter(
        (d) => d.overlays[isInteractiveOverlay ? overlayId : originalName]
      );

    const title = overrideName || originalName;
    const subTitle = overrideName ? originalName : undefined;

    const active = availableData.some(
      (d) => d.overlays[isInteractiveOverlay ? overlayId : originalName].active
    );

    const updates: OverlayAssignment[] = availableData.map((d) => ({
      wsiId: d.id,
      overlayId: d.overlays[isInteractiveOverlay ? overlayId : originalName].id,
    }));

    const hasDeleteButton =
      onDelete &&
      ((stage === 'prod' && userRole.scopes['interactiveOverlay:deleteProd']) ||
        (stage !== 'prod' && userRole.scopes['interactiveOverlay:deleteDev']));

    return (
      <VStack alignItems="center">
        <TiltedHeader stage={stage} title={title} subTitle={subTitle} />
        {hasDeleteButton ? (
          <IconButton
            size="input"
            icon="Trash2"
            onClick={() => {
              onDelete(originalName);
            }}
          />
        ) : null}
        <Toggle
          label={`Toggle ${originalName} for all wsis`}
          active={active}
          onChange={(value) => {
            onChange(value, updates);
          }}
        />
      </VStack>
    );
  };
}

/** Toggle for a single field */
function FieldToggle(
  onChange: AssignUpdates,
  originalName: string,
  overlayType: string,
  overlayId: string
) {
  return function Cell(
    cell: CellContext<TableData<StaticOverlayAll>, string>
  ): JSX.Element {
    const extendedColumn = cell.row;
    const overlay =
      extendedColumn.original.overlays[
        overlayType === 'interactive' ? overlayId : originalName
      ];

    // No overlay, return disabled toggle.
    if (!overlay) return <Toggle disabled={true} />;

    const updates = [
      { wsiId: extendedColumn.original.id, overlayId: overlay.id },
    ];
    return (
      <Toggle
        label={`Toggle ${originalName} for wsi ${extendedColumn.original.name}`}
        active={overlay.active}
        onChange={(value) => {
          onChange(value, updates);
        }}
        id={overlay.id}
      />
    );
  };
}
