import { Header, HStack, IconButton, UserAvatar } from '@aignostics/components';
import { useViewport } from '@aignostics/hooks';
import React, {
  Dispatch,
  MouseEvent,
  ReactElement,
  ReactNode,
  SetStateAction,
} from 'react';
import { useTheme } from 'styled-components';
import { type SideNav } from '../../__Layout/Layout/Layout.Context';
import { UserFeedback } from '../../__Layout/UserFeedback';
import { useAuthUser } from '../../App/AuthProvider';
import { useImpersonation } from '../../App/ImpersonationProvider';
import Crumbs from '../Crumbs/Crumbs';
import { $AppSubHeader } from './AppHeader.styles';
import ViewToggle from './ViewToggle.component';

interface AppHeaderProps {
  children?: ReactNode;
  sideNav: SideNav;
  setSideNav: Dispatch<SetStateAction<SideNav>>;
}

/**
 * Global application header for all views.
 */
const AppHeader = ({
  sideNav,
  setSideNav,
  children,
}: AppHeaderProps): ReactElement => {
  const user = useAuthUser();
  const { impersonatedUserEmail } = useImpersonation();
  const theme = useTheme();
  const sideNavActive = sideNav === 'menu';

  const toggleSideNav = (value: SideNav) => {
    setSideNav(sideNav === value ? null : value);
  };

  const handleUserSideNav = (event: MouseEvent) => {
    event.stopPropagation();
    setSideNav('user');
  };
  const { FULL_EXTENDED_DATA_ONBOARDING: FULL } = useViewport(
    theme.breakpoints
  );
  return (
    <div>
      <Header
        size="button"
        color={theme.colors.dark}
        actionLeft={
          <IconButton
            aria-label="Sidebar Navigation"
            description="Navigation"
            key={sideNavActive ? 'close' : 'open'}
            icon={sideNavActive ? 'X' : 'Menu'}
            onClick={() => {
              toggleSideNav('menu');
            }}
          />
        }
        actionRight={
          <HStack alignItems="center" spacing="base">
            <ViewToggle />
            <UserFeedback />
            <UserAvatar
              user={user}
              isImpersonating={impersonatedUserEmail !== null}
              onClick={handleUserSideNav}
              label="Toggle User SideBar"
            />
          </HStack>
        }
      >
        <Crumbs />
        {/* e.g. <Magnifier/> on slide view */}
        {FULL && children}
      </Header>
      {!FULL && <$AppSubHeader>{children}</$AppSubHeader>}
    </div>
  );
};

export default AppHeader;
