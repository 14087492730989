import { Button } from '@aignostics/components';
import styled from 'styled-components';

export const $FooterWrapper = styled.section`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  position: sticky;
  bottom: 0;
  left: 0;
  pointer-events: none;
  z-index: 2;
`;

export const $Footer = styled.div`
  display: flex;
  justify-content: center;
  height: 72px;
  width: 100%;
  padding: 0;
  pointer-events: auto;
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.1);
  background: ${({ theme }) => theme.colors.white};
`;

export const $FooterElements = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1104px;
  z-index: 2;
`;

export const $SlideCount = styled.div`
  ${({ theme }) => theme.fontStyles.baseBold};
  color: ${({ theme }) => theme.colors.text};
`;

export const $Button = styled(Button)`
  margin-left: ${({ theme }) => `${theme.spacings.large}px`};
`;
