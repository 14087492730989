import {
  InfoModal,
  KeyValueList,
  Loader,
  useSnackbarMutations,
} from '@aignostics/components';
import { OrganizationRole } from '@aignostics/core';
import { useQuery } from '@apollo/client';
import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { Wsi } from '../../types';
import {
  FETCH_WSI_ADMIN_INFO_MODAL_DATA,
  FETCH_WSI_INFO_MODAL_DATA,
} from './FETCH_WSI_INFO_MODAL_DATA';
import MetadataHeader from './MetadataHeader.component';
import { buildSlideMetaDataFields } from './buildSlideMetaDataFields';

const $WsiMetadataModalLoaderContainer = styled.div`
  display: flex;
  min-height: ${({ theme }) => `${theme.spacings['96']}px`};
`;

export type InfoModalState =
  | { state: 'closed' }
  | { state: 'open'; wsiId: string };

export const WsiMetadataModal = ({
  isOpen,
  onClose,
  wsiId,
  subProjectId,
  role,
  rasterTileServerUrl,
  getToken,
}: {
  isOpen: boolean;
  onClose: () => void;
  wsiId: string;
  subProjectId?: string;
  role: OrganizationRole;
  getToken: () => Promise<string>;
  rasterTileServerUrl: string;
}): ReactElement => {
  const { addSnackbar } = useSnackbarMutations();
  const query = subProjectId
    ? FETCH_WSI_INFO_MODAL_DATA
    : FETCH_WSI_ADMIN_INFO_MODAL_DATA;
  const variables = subProjectId
    ? {
        wsiId,
        subProjectId,
      }
    : { wsiId };

  const { data, loading } = useQuery<{ wsi: Wsi }>(query, {
    variables,
    onError: (error) => {
      addSnackbar({
        type: 'error',
        message: error.message,
      });
    },
  });

  const wsi = data?.wsi;

  return (
    <InfoModal isVisible={isOpen} title="Slide Metadata" onClose={onClose}>
      {isOpen && (
        <>
          {loading && (
            <$WsiMetadataModalLoaderContainer>
              <Loader />
            </$WsiMetadataModalLoaderContainer>
          )}
          {wsi && (
            <>
              <MetadataHeader
                wsi={{
                  id: wsi.id,
                  name: wsi.name,
                  originalName: wsi.originalName,
                }}
                disabled={!subProjectId}
                role={role}
                rasterTileServerUrl={rasterTileServerUrl}
                getToken={getToken}
              />
              <KeyValueList fields={buildSlideMetaDataFields(wsi)} copy />
            </>
          )}
        </>
      )}
    </InfoModal>
  );
};
