import { SlideLibrary } from '@aignostics/management-ui';
import React, { ReactElement } from 'react';
import useUserRole from '../../../../hooks/useUserRole';
import { useAuthUser, useGetAuthToken } from '../../../App/AuthProvider';
import { useAppConfig } from '../../../App/ConfigProvider';

export const SlideLibraryWrapper = (): ReactElement => {
  const role = useUserRole();
  const currentUser = useAuthUser();

  const {
    portalServices: { rasterTileServerUrl },
  } = useAppConfig();
  const getToken = useGetAuthToken();

  return (
    <SlideLibrary
      getToken={getToken}
      rasterTileServerUrl={rasterTileServerUrl}
      role={role}
      currentUser={currentUser}
    />
  );
};
