import {
  Filter,
  FilterConfigs,
  GridItemProject,
  GridItemProjectSkeleton,
  HStack,
  ListItemProject,
  ListItemProjectSkeleton,
  LoaderBar,
  PageLayout,
  Pagination,
  PaginationInfo,
  Placeholder,
  Section,
  WsiPlaceholder,
  getFiltersFromQueryParams,
  getPageFromQueryParams,
  useFilters,
  usePagination,
} from '@aignostics/components';
import { OrganizationRole } from '@aignostics/core';
import { useSetQueryParams } from '@aignostics/hooks';
import { useQuery } from '@apollo/client';
import React, { ReactElement, useMemo } from 'react';
import { WsiThumbnail } from '../../components';
import { LayoutMode, useLayoutMode } from '../../hooks';
import { CreatedBy, Pagination as PaginationType, Project } from '../../types';
import { default as FETCH_PROJECTS } from './FETCH_PROJECTS';
import { getProjectFilters } from './Home.utils';

export type FilterKeys = 'name' | 'createdBy';
export const PAGE_SIZE = 12;

interface HomeProps {
  userRole: OrganizationRole;
  texts: {
    home: {
      title: string;
      description: string;
    };
  };
  banner: JSX.Element | undefined;
  rasterTileServerUrl: string;
  getToken: () => Promise<string>;
}

/** Landing Page Client View */
const Home = ({
  userRole,
  texts,
  banner,
  getToken,
  rasterTileServerUrl,
}: HomeProps): ReactElement => {
  const [page, setPage] = usePagination(getPageFromQueryParams());

  const { filterProps, filters } = useFilters(
    PAGE_FILTER_CONFIG,
    getFiltersFromQueryParams(PAGE_FILTER_CONFIG)
  );

  const { data, previousData, error, loading } = useQuery<{
    projects: PaginationType<
      Project,
      { users: CreatedBy[]; availableProjectCount: number }
    >;
  }>(FETCH_PROJECTS, {
    variables: {
      page,
      pageSize: PAGE_SIZE,
      search: filters.name,
      createdBy: filters.createdBy,
      isAdminView: false,
    },
  });

  const projects = useMemo(() => data?.projects?.nodes ?? [], [data]);
  const projectCreators = useMemo(
    () => data?.projects?.collectionAttributes.users ?? [],
    [data]
  );

  const availableProjectCount = useMemo(
    () => data?.projects.pageInfo.totalElements ?? 0,
    [data?.projects.pageInfo.totalElements]
  );

  const { LayoutContainer, LayoutSelector, layoutMode } = useLayoutMode();

  const queryParams = useMemo(
    () => ({ page: page.toString(), ...filters }),
    [page, filters]
  );
  useSetQueryParams(queryParams);

  const filterFields = useMemo(() => {
    return getProjectFilters(userRole.scopes, projects, projectCreators);
  }, [projectCreators, projects, userRole.scopes]);
  const totalPages = (data ?? previousData)?.projects.pageInfo.totalPages ?? 0;

  return (
    <PageLayout
      title={texts.home.title}
      description={texts.home.description}
      expandableDescription={false}
      error={error}
      logo={banner}
      unmountWhileLoading={false}
      currentOrganization={userRole.organization.name}
    >
      <LoaderBar loading={loading} />
      <Section background="lighter">
        <Filter
          fields={filterFields}
          isOpen={false}
          {...filterProps}
          onChange={(filters) => {
            filterProps.onChange(filters);
            setPage(1);
          }}
        />
      </Section>
      <Section>
        <HStack justifyContent="space-between" alignItems="center">
          {data && (
            <PaginationInfo
              totalCount={availableProjectCount}
              currentPage={page}
              itemsPerPage={PAGE_SIZE}
              itemType=""
            />
          )}
          {data && data?.projects.pageInfo.totalElements > 0 && (
            <LayoutSelector
              key="projects-view-type"
              name="projects-view-type"
            />
          )}
        </HStack>
        <>
          {loading ? (
            <LayoutContainer>
              {Array.from({ length: PAGE_SIZE }).map((_, index) =>
                layoutMode === 'Grid' ? (
                  <GridItemProjectSkeleton key={index} />
                ) : (
                  <ListItemProjectSkeleton key={index} />
                )
              )}
            </LayoutContainer>
          ) : (
            <>
              {projects.length === 0 ? (
                <Placeholder
                  title="No projects"
                  description="We could not find any matching projects."
                />
              ) : (
                <LayoutContainer>
                  <ProjectsListMemoed
                    projects={projects}
                    layoutMode={layoutMode}
                    getToken={getToken}
                    rasterTileServerUrl={rasterTileServerUrl}
                  />
                </LayoutContainer>
              )}
            </>
          )}
        </>
        {totalPages > 1 && (
          <div style={{ margin: '0 auto' }}>
            <Pagination
              currentPage={page}
              totalPages={totalPages}
              onPageChanged={setPage}
            />
          </div>
        )}
      </Section>
    </PageLayout>
  );
};

const PAGE_FILTER_CONFIG: FilterConfigs<FilterKeys> = {
  name: { fallbackValue: '', type: 'string' },
  createdBy: { fallbackValue: [], type: 'array' },
};

function ProjectsList({
  layoutMode,
  projects,
  getToken,
  rasterTileServerUrl,
}: {
  layoutMode: LayoutMode;
  projects: Project[];
  rasterTileServerUrl: string;
  getToken: () => Promise<string>;
}): ReactElement {
  return (
    <>
      {projects.map(
        ({ id, name, thumbnailWsiId, subProjectsCount, isVisible }) => {
          const image =
            thumbnailWsiId !== null ? (
              <WsiThumbnail
                wsiId={thumbnailWsiId}
                getToken={getToken}
                rasterTileServerUrl={rasterTileServerUrl}
                size={layoutMode === 'Grid' ? 'large' : 'small'}
              />
            ) : (
              <WsiPlaceholder
                state="error"
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                }}
              />
            );

          return layoutMode === 'Grid' ? (
            <GridItemProject
              key={id}
              title={name}
              to={`project/${id}`}
              image={image}
              subProjectsCount={subProjectsCount}
              isVisible={isVisible}
            />
          ) : (
            <ListItemProject
              key={id}
              title={name}
              to={`project/${id}`}
              image={image}
              subProjectsCount={subProjectsCount}
              isVisible={isVisible}
            />
          );
        }
      )}
    </>
  );
}

const ProjectsListMemoed = React.memo(ProjectsList);

export default Home;
