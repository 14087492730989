import { IconButton } from '@aignostics/components';
import type { CustomCellRendererProps } from 'ag-grid-react';
import React, { useCallback, type ReactElement } from 'react';
import type { GridData, MetadataGridContext } from '../MetadataGrid.types';

export const CustomCellDeleteSlide = ({
  data,
  context,
}: CustomCellRendererProps<
  GridData,
  unknown,
  MetadataGridContext
>): ReactElement | null => {
  const handleClick = useCallback(() => {
    context.deleteSlide(data!);
  }, [context, data]);

  if (!data || data.isHeaderRow || data.type === 'channel') {
    return null;
  }

  return (
    <IconButton
      onClick={handleClick}
      icon="Trash2"
      color="black"
      description={`Remove slide ${data?.slideFile?.filename}`}
    />
  );
};
