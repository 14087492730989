import { Button, Input } from '@aignostics/components';
import React, { ReactElement, useState } from 'react';
import { $RenamingForm } from './Renaming.styles';

export interface RenamingProps {
  onCancel: () => void;
  onSave: (value: string) => void;
  placeholder: string;
}

export const Renaming = ({
  onCancel,
  onSave,
  placeholder = '',
}: RenamingProps): ReactElement => {
  const [updatedValue, setUpdatedValue] = useState<string>(placeholder);

  return (
    <$RenamingForm
      onSubmit={(e) => {
        e.preventDefault();
        onSave(updatedValue);
      }}
    >
      <Input
        placeholder={placeholder}
        value={updatedValue}
        id="Renaming"
        aria-label="Rename slide"
        onChange={setUpdatedValue}
      />
      <Button aria-label="Save" icon="Save" small />
      <Button
        aria-label="Cancel"
        icon="X"
        small
        variant="primaryOutline"
        onClick={onCancel}
        type="button"
      />
    </$RenamingForm>
  );
};
