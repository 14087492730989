import {
  Button,
  FilterConfigs,
  HStack,
  Pagination,
  PaginationInfo,
  ProgressBar,
  Section,
  VStack,
  getFiltersFromQueryParams,
  getPageFromQueryParams,
  useDisclosure,
  useFilters,
  usePagination,
} from '@aignostics/components';
import { OrganizationRole, UserWithRoles } from '@aignostics/core';
import { useSetQueryParams } from '@aignostics/hooks';
import { buildClientWsiUrl, pluralize } from '@aignostics/utils';
import { useQuery } from '@apollo/client';
import React, { ReactElement, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { z } from 'zod';
import { WsiMetadataModal } from '../../../../components';
import {
  SortByDirection,
  useHandleSubprojectQueryErrors,
  useLayoutMode,
  usePrevious,
  useSortBy,
} from '../../../../hooks';
import { Project, SubProject, Wsi } from '../../../../types';
import {
  downloadReport,
  getAnnotationProgress,
  getQueryParams,
  useSelectedOrganizationUuid,
} from '../../../../utils';
import { AssignedSlidesTable } from '../../Admin/AdminSubProjectSlides/AssignedSlidesTable/AssignedSlidesTable';
import { FETCH_SUBPROJECT_WSIS } from '../../FETCH_SUBPROJECT_WSIS';
import { SortByOptions } from '../../Subproject.types';
import { ClientSubprojectSlidesFilters } from './ClientSubProjectSlides.Filters.component';
import { ClientSubProjectSlidesGrid } from './ClientSubProjectSlidesGrid.component';
import {
  ClientSubprojectSlidesFilterKey,
  buildQueryVariables,
} from './SubProject.utils';

type FilterKey =
  | 'filterSearchSlides'
  | 'filterAnnotations'
  | 'filterOverlays'
  | 'filterAnnotatedBy'
  | 'filterAnnotationCategory'
  | 'filterDiseases';

const PAGE_FILTER_CONFIGS: FilterConfigs<FilterKey> = {
  filterAnnotatedBy: { fallbackValue: [], type: 'array' },
  filterAnnotationCategory: { fallbackValue: [], type: 'array' },
  filterAnnotations: { fallbackValue: 'all', type: 'string' },
  filterOverlays: { fallbackValue: 'all', type: 'string' },
  filterSearchSlides: { fallbackValue: '', type: 'string' },
  filterDiseases: { fallbackValue: [], type: 'array' },
};

const paramsSchema = z.object({
  projectId: z.string(),
  subProjectId: z.string(),
});

const PAGE_SIZE = 48;
const ADMIN_ASSIGNED_SLIDES_PAGE_SIZE = 20;

/** Renders a selection of slides with some filters */
export const ClientSubProjectSlides = ({
  rasterTileServerUrl,
  role,
  currentUser,
  getToken,
}: {
  rasterTileServerUrl: string;
  role: OrganizationRole;
  currentUser: UserWithRoles;
  getToken: () => Promise<string>;
}): ReactElement => {
  const theme = useTheme();
  const { projectId, subProjectId } = paramsSchema.parse(useParams());
  const organizationUuid = useSelectedOrganizationUuid();
  const infoModalDisclosure = useDisclosure<Wsi>(false);

  const { filters, filterProps } = useFilters<ClientSubprojectSlidesFilterKey>(
    PAGE_FILTER_CONFIGS,
    getFiltersFromQueryParams(PAGE_FILTER_CONFIGS)
  );

  const queryVariables = useMemo(
    () => buildQueryVariables(filters, projectId, subProjectId),
    [filters, projectId, subProjectId]
  );

  const [page, setPage] = usePagination(getPageFromQueryParams());
  const urlParams = getQueryParams(['sortBy', 'sortDirection']);
  const sortByParam = urlParams?.sortBy as SortByOptions;
  const sortDirectionParam = urlParams?.sortDirection as SortByDirection;
  const { sortBy, setSortByOptions } = useSortBy<SortByOptions>({
    column: sortByParam || 'name',
    sortDirection: sortDirectionParam || 'asc',
  });

  const previousFiltersSearchSlides = usePrevious(filters.filterSearchSlides);

  useEffect(() => {
    if (
      previousFiltersSearchSlides &&
      filters.filterSearchSlides !== previousFiltersSearchSlides
    ) {
      setPage(1);
    }
  }, [setPage, filters.filterSearchSlides, previousFiltersSearchSlides]);

  const queryParams = useMemo(
    () => ({
      page: page.toString(),
      ...(sortBy?.column ? { sortBy: sortBy.column } : {}),
      ...(sortBy?.sortDirection ? { sortDirection: sortBy.sortDirection } : {}),
      ...filters,
    }),
    [page, filters, sortBy?.column, sortBy?.sortDirection]
  );

  useSetQueryParams(queryParams);

  const { data, loading, error, previousData } = useQuery<{
    subProject: SubProject;
    project: Project;
  }>(FETCH_SUBPROJECT_WSIS, {
    variables: {
      ...queryVariables,
      sortBy: sortBy?.column || 'name',
      isAsc: sortBy?.sortDirection === 'asc' ? true : false,
      page,
      pageSize: PAGE_SIZE,
    },
    nextFetchPolicy: 'cache-and-network',
  });

  const subProject = (data || previousData)?.subProject;
  const project = (data || previousData)?.project;
  const projectAssignedUserIds = project?.assignedUsers.map(
    (assignedUser) => assignedUser.id
  );
  // Setup required data entities with fallback values.
  const wsis = useMemo(
    () => (subProject ? subProject.wsis.nodes : []),
    [subProject]
  );

  const annotationFeature = subProject?.annotationFeature || 'OFF';
  const showCsv = subProject?.showCsv || false;

  const totalWsisCount = subProject?.wsis.pageInfo.totalElements ?? 0;

  const { progress, count, total } = getAnnotationProgress(
    subProject?.wsis.collectionAttributes.totalAnnotated,
    totalWsisCount
  );

  const { layoutMode, LayoutSelector } = useLayoutMode(
    undefined,
    `layout-${subProjectId}`
  );

  const errorView = useHandleSubprojectQueryErrors(error);
  if (errorView) {
    return errorView;
  }

  return (
    <>
      <Section background="lighter" loading={loading}>
        {subProject && (
          <ClientSubprojectSlidesFilters
            filterProps={filterProps}
            subprojectId={subProject.id}
            userRole={role}
          />
        )}
        <HStack
          justifyContent="space-between"
          alignItems={
            wsis.length > 0 &&
            subProject &&
            projectAssignedUserIds?.includes(currentUser.id)
              ? 'center'
              : 'flex-end'
          }
        >
          <VStack justifyContent="center">
            {subProject && (
              <HStack>
                <PaginationInfo
                  totalCount={totalWsisCount}
                  currentPage={page}
                  itemsPerPage={PAGE_SIZE}
                  itemType={pluralize('Slide', totalWsisCount)}
                />
              </HStack>
            )}
          </VStack>
          <VStack>
            <HStack spacing="large" alignItems="flex-end">
              {annotationFeature !== 'OFF' && (
                <ProgressBar
                  value={progress}
                  color="primary"
                  label={`${count} of ${total} ${pluralize(
                    'Slide',
                    count
                  )} annotated`}
                />
              )}

              {/* Show project report download buttons according to flags.*/}
              {showCsv && (
                <Button
                  icon="Download"
                  onClick={() => {
                    if (subProject !== undefined) downloadReport(subProject);
                  }}
                  small
                >
                  CSV
                </Button>
              )}

              {wsis.length > 0 && (
                <LayoutSelector key="wsi-view-type" name="wsi-view-type" />
              )}
            </HStack>
          </VStack>
        </HStack>
        <VStack style={{ gap: '40px' }} alignItems="center">
          {layoutMode === 'List' ? (
            <VStack
              style={{
                gap: `${theme.spacings[32]}px`,
                width: '100%',
                alignItems: 'center',
              }}
            >
              <AssignedSlidesTable
                loading={loading}
                subprojectId={subProjectId}
                wsis={wsis}
                getWsiHref={(wsiUuid) =>
                  buildClientWsiUrl(
                    organizationUuid,
                    projectId,
                    subProjectId,
                    wsiUuid
                  )
                }
                setSortBy={setSortByOptions}
                sortBy={sortBy}
                pageSize={ADMIN_ASSIGNED_SLIDES_PAGE_SIZE}
                role={role}
                getToken={getToken}
                rasterTileServerUrl={rasterTileServerUrl}
              />
            </VStack>
          ) : (
            <ClientSubProjectSlidesGrid
              organizationUuid={organizationUuid}
              projectId={projectId}
              subProjectId={subProjectId}
              loading={loading}
              wsis={wsis}
              pageSize={PAGE_SIZE}
              infoModalDisclosure={infoModalDisclosure}
              rasterTileServerUrl={rasterTileServerUrl}
              getToken={getToken}
            />
          )}
          {subProject && subProject.wsis.pageInfo.totalPages > 1 && (
            <div style={{ margin: '0 auto' }}>
              <Pagination
                currentPage={page}
                totalPages={subProject.wsis.pageInfo.totalPages}
                onPageChanged={setPage}
              />
            </div>
          )}

          {infoModalDisclosure.data && (
            <WsiMetadataModal
              isOpen={infoModalDisclosure.isOpen}
              onClose={infoModalDisclosure.close}
              wsiId={infoModalDisclosure.data.id}
              subProjectId={subProjectId}
              rasterTileServerUrl={rasterTileServerUrl}
              role={role}
              getToken={getToken}
            />
          )}
        </VStack>
      </Section>
    </>
  );
};
