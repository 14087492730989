import {
  InteractiveOverlayAll,
  InteractiveOverlayWsi,
} from './AdminSubProjectAssignInteractiveOverlays.types';

export const transformWsisToTaggerMap = (
  wsis: InteractiveOverlayWsi[]
): Map<string, InteractiveOverlayAll> => {
  const uniqueTaggersMap = new Map<string, InteractiveOverlayAll>();
  wsis.forEach((wsi) => {
    wsi.taggersAll.forEach((tagger) => {
      uniqueTaggersMap.set(tagger.id, {
        ...tagger,
      });
    });
  });

  return uniqueTaggersMap;
};

export interface InteractiveOverlayWithAssignedSlides
  extends InteractiveOverlayAll {
  assignedWsis: string[];
  overrideName?: string;
  allWsis: string[];
  sort?: number;
}

export function sortTaggersAssignedBySortValue(
  taggersAssigned: InteractiveOverlayWithAssignedSlides[]
): InteractiveOverlayWithAssignedSlides[] {
  return taggersAssigned.sort((a, b) => (a.sort || 0) - (b.sort || 0));
}

export const addAssignedAndAvailableWsisToTaggersMap = (
  allTaggersMap: Map<string, InteractiveOverlayAll>,
  wsis: InteractiveOverlayWsi[]
): Map<string, InteractiveOverlayWithAssignedSlides> => {
  const taggerMapAssignedWsis = new Map<
    string,
    InteractiveOverlayWithAssignedSlides
  >();

  allTaggersMap.forEach((tagger) => {
    const assignedTaggerWsis = getAssignedTaggerWsis(tagger, wsis);
    const allTaggerWsis = getAllTaggerWsis(tagger, wsis);
    const overrideName = getOverrideName(tagger, wsis);
    taggerMapAssignedWsis.set(tagger.id, {
      id: tagger.id,
      assignedWsis: assignedTaggerWsis,
      allWsis: allTaggerWsis,
      overrideName,
      originalName: tagger.originalName,
      stage: tagger.stage,
      sort: tagger.sort,
    });
  });

  return taggerMapAssignedWsis;
};

const getAssignedTaggerWsis = (
  tagger: InteractiveOverlayAll,
  wsis: InteractiveOverlayWsi[]
): string[] => {
  const assignedWsis: string[] = [];
  wsis.forEach((wsi) => {
    const isTaggerAssignedToWsi = wsi.taggersAssigned.some(
      (taggerAssigned) => taggerAssigned.id === tagger.id
    );
    if (isTaggerAssignedToWsi) {
      assignedWsis.push(wsi.id);
    }
  });
  return assignedWsis;
};

const getAllTaggerWsis = (
  tagger: InteractiveOverlayAll,
  wsis: InteractiveOverlayWsi[]
): string[] => {
  const allWsis: string[] = [];
  wsis.forEach((wsi) => {
    const isTaggerInAllWsis = wsi.taggersAll.some(
      (taggerAll) => taggerAll.id === tagger.id
    );
    if (isTaggerInAllWsis) {
      allWsis.push(wsi.id);
    }
  });
  return allWsis;
};

const getOverrideName = (
  tagger: InteractiveOverlayAll,
  wsis: InteractiveOverlayWsi[]
): string | undefined => {
  for (const wsi of wsis) {
    const taggerAssigned = wsi.taggersAssigned.find(
      (taggerAssigned) => taggerAssigned.id === tagger.id
    );
    if (taggerAssigned?.overrideName) {
      return taggerAssigned.overrideName;
    }
  }
  return undefined;
};
