import firebaseCredentials from '../../firebase-credentials.json';
import { aignxBranding } from './appBrandings';
import { AppConfig, buildEnvSchema } from './appConfig.types';
import { requireEnv } from './utils/requireEnv';

export type { BuildEnv } from './appConfig.types';

/** Central app config with environment and branding settings. */
export const appConfig: AppConfig = {
  buildEnv: buildEnvSchema.parse(process.env.REACT_APP_BUILD_ENV),
  firebaseCredentials,
  origin: requireEnv(process.env.REACT_APP_ORIGIN),
  routerBasename: requireEnv(process.env.REACT_APP_ROUTER_BASENAME, '/'),
  nodeEnv: requireEnv(process.env.NODE_ENV, 'development'),
  portalServices: {
    apiUrl: requireEnv(process.env.REACT_APP_API),
    rasterTileServerUrl: requireEnv(process.env.REACT_APP_TILE_SERVER),
    vectorTileServerUrl: requireEnv(process.env.REACT_APP_VECTOR_TILE_SERVER),
  },
  version: requireEnv(process.env.REACT_APP_PORTAL_VERSION),
  sentry: {
    dsn: requireEnv(process.env.REACT_APP_SENTRY_DSN),
  },
  tracking: {
    domain: requireEnv(process.env.REACT_APP_TRACKING_DOMAIN),
  },
  otelCollectorEndpoint: requireEnv(
    process.env.REACT_APP_OTEL_COLLECTOR_ENDPOINT
  ),
  ...aignxBranding,
};
