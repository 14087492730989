import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
export function useSetQueryParams(params, enabled = true) {
    const navigate = useNavigate();
    useEffect(() => {
        if (!enabled)
            return;
        setQueryParams(params, navigate);
    }, [params, navigate, enabled]);
}
function setQueryParams(params, navigate) {
    const searchParams = new URLSearchParams(window.location.search);
    Object.entries(params).forEach(([key, value]) => {
        const valueIsEmptyString = value === '';
        const valueIsEmptyArray = Array.isArray(value) && value.length === 0;
        if (valueIsEmptyString || valueIsEmptyArray) {
            searchParams.delete(key);
            return;
        }
        if (Array.isArray(value)) {
            searchParams.set(key, value.join(','));
            return;
        }
        searchParams.set(key, value);
    });
    void navigate(`?${searchParams.toString()}`, { replace: true });
}
