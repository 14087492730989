import {
  Loader,
  PageLayout,
  VisibleWithScope,
  useSnackbarMutations,
} from '@aignostics/components';
import { OrganizationRole } from '@aignostics/core';
import React, { ReactElement } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useFetchOnboardingWsisQuery } from '../hooks/useFetchOnboardingWsisQuery';
import { renderSlidesMetadataEditor } from '../utils/renderSlidesMetadataEditor';
import { useSelectedOrganizationUuid } from '../utils/useSelectedOrganizationUuid';
import { convertRecordsToState } from './SetFileMetadataStep/Form/form.state';

/**
 * Page for admins to edit Slides ( WSIS ) from a selected OnnBoardingBatch.
 */

type AdminEditSlidesProps = {
  role: OrganizationRole;
  authToken: string | null;
  apiUrl: string;
};
const AdminEditSlides = ({
  role,
  authToken,
  apiUrl,
}: AdminEditSlidesProps): ReactElement | null => {
  const location = useLocation();
  const navigate = useNavigate();
  const organizationUuid = useSelectedOrganizationUuid();
  const { batchId } = useParams() as { batchId: string };
  const { addSnackbar } = useSnackbarMutations();

  let onboardingBatch = location.state?.onboardingBatch;

  // fetch data, but only if batch data isn't passed via location state
  const { data, loading, error } = useFetchOnboardingWsisQuery({
    variables: { batchId, injectBlackLayerReferences: false },
    skip: Boolean(onboardingBatch),
  });

  // if query is in progress: show loader
  if (loading) {
    return <Loader />;
  }

  if (!onboardingBatch) {
    // no batch data could be loaded (e.g. wrong url), then redirect to
    // the batch list and show a notification
    if (!data || data.onboardingBatch.length === 0 || error) {
      addSnackbar({
        type: 'error',
        message: `Couldn't load batch with id ${batchId}`,
      });

      void navigate(`/${organizationUuid}/admin/data-onboarding`);

      return null;
    }

    onboardingBatch = convertRecordsToState(data.onboardingBatch[0]);
  }

  return (
    <PageLayout
      title="Edit Slides"
      description="Please provide information about the slides (you can use the table header to add global values). You may also include a CSV file containing prefilled fields."
    >
      <VisibleWithScope role={role} scope="wsi:upload">
        {renderSlidesMetadataEditor({
          apiUrl,
          authToken,
          csv: undefined,
          submitLabel: 'update',
          onboardingBatch,
          files: [],
          onSuccessfulUpdate: (updateOnboardingBatchWsis) => {
            void navigate(
              `/${organizationUuid}/admin/data-onboarding/batch/${updateOnboardingBatchWsis.batch_id}`
            );
          },
        })}
      </VisibleWithScope>
    </PageLayout>
  );
};

export default AdminEditSlides;
