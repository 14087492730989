import { FloatingButton, Icon, Modal } from '@aignostics/components';
import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import {
  $NextButton,
  $OnboardingTutorialBody,
  $OnboardingTutorialFooter,
  $OnboardingTutorialHeader,
  $OnboardingTutorialWrapper,
  $PreviousButton,
} from './OnboardingTutorial.styles';
import { Steps, steps } from './onboardingTutorial.data';

interface OnboardingTutorialModalProps {
  initialStep?: Steps;
}
export const OnboardingTutorialModal = ({
  initialStep = Steps.ONBOARDING,
}: OnboardingTutorialModalProps): ReactElement => {
  const isFirstOnboarding = localStorage.getItem('isFirstOnboarding');

  const [step, setStep] = useState(initialStep);
  const [isOpen, setIsOpen] = useState(false);

  const { title, body, next, previous, nextStep, previousStep } = steps[step];

  const handleNextButton = () => {
    if (nextStep) setStep(nextStep);
  };
  const handlePreviousButton = () => {
    if (previousStep) setStep(previousStep);
  };

  const handleToggleModal = useCallback(() => {
    setIsOpen((prev) => {
      if (isFirstOnboarding !== 'false') {
        localStorage.setItem('isFirstOnboarding', 'false');
      }
      return !prev;
    });
  }, [isFirstOnboarding]);

  useEffect(() => {
    if (isFirstOnboarding !== 'false') {
      handleToggleModal();
    }
  }, [handleToggleModal, isFirstOnboarding]);

  useEffect(() => {
    setStep(initialStep);
  }, [initialStep, isOpen]);
  return (
    <>
      <FloatingButton
        body={<Icon icon="AlertCircle" />}
        onClick={handleToggleModal}
        aria-label="tutorial-floating-button"
      />
      <Modal
        aria-label="Onboarding Tutorial"
        isVisible={isOpen}
        hasCloseButton
        onClose={handleToggleModal}
        size="medium"
      >
        <>
          <$OnboardingTutorialWrapper>
            <$OnboardingTutorialHeader>{title}</$OnboardingTutorialHeader>
            <$OnboardingTutorialBody>{body}</$OnboardingTutorialBody>
          </$OnboardingTutorialWrapper>

          <$OnboardingTutorialFooter>
            {previous && (
              <$PreviousButton
                onClick={handlePreviousButton}
                aria-label="tutorial-previous"
              >
                <Icon icon="ChevronsLeft" /> {previous}
              </$PreviousButton>
            )}
            {next && (
              <$NextButton
                onClick={handleNextButton}
                aria-label="tutorial-next"
              >
                {next} <Icon icon="ChevronsRight" />
              </$NextButton>
            )}
          </$OnboardingTutorialFooter>
        </>
      </Modal>
    </>
  );
};
