import {
  CellSelectionModule,
  ClientSideRowModelApiModule,
  ClientSideRowModelModule,
  ClipboardModule,
  ColumnAutoSizeModule,
  ColumnMenuModule,
  ContextMenuModule,
  GridApi,
  PinnedRowModule,
  RenderApiModule,
  RichSelectModule,
  RowApiModule,
  RowAutoHeightModule,
  RowGroupingModule,
  RowStyleModule,
  SelectEditorModule,
  TextEditorModule,
  TooltipModule,
  TreeDataModule,
  ValidationModule,
  type ColDef,
  type RichCellEditorParams,
} from 'ag-grid-enterprise';
import type {
  CancerSite,
  Disease,
  Morphology,
  PreparationType,
  SampleType,
  Scanner,
  Staining,
  Tissue,
} from '../../../types';
import type { MultiChannelSlideFileChannel } from '../../SetFileMetadataStep/Form/form.state.types';
import {
  CustomCellSelectValue,
  type CustomCellSelectValueValidatorProp,
} from './CustomCellRenderers';
import { GridData } from './MetadataGrid.types';

export const GRID_MODULES = [
  CellSelectionModule,
  ClientSideRowModelApiModule,
  ClientSideRowModelModule,
  ClipboardModule,
  ColumnAutoSizeModule,
  ColumnMenuModule,
  ContextMenuModule,
  PinnedRowModule,
  RenderApiModule,
  RichSelectModule,
  RowApiModule,
  RowAutoHeightModule,
  RowStyleModule,
  TextEditorModule,
  TooltipModule,
  RowGroupingModule,
  ValidationModule,
  SelectEditorModule,
  TreeDataModule,
];

export const GEN_PINNED_ROW_TEMPLATE = (): GridData[] => [
  {
    rowId: '%TOP_ROW_PINNED%',
    path: [],
    isHeaderRow: true,
    type: 'single-channel',
    slideFile: {
      checksum: 'AAAAAA==',
      filename: 'placeholder',
      fileIndex: 0,
      size: 0,
      type: 'local',
      source: null,
    },
    caseId: '',
    block: '',
    section: '',
    patientExternalId: '',
    scannerId: null,
    staining: null,
    tissue: null,
    disease: null,
    samplePreparation: null,
    sampleType: null,
    morphology: null,
    cancerSite: null,
    parentTmaRow: null,
    parentTmaCol: null,
    parentSlidePosX: null,
    parentSlidePosY: null,
    parentWsiUuid: null,
    caseUuid: null,
    wsiUuid: null,
  },
];
export const slideNameColId = 'colId:slidename';
export const defaultColDef: ColDef = {
  resizable: true,
  sortable: false,
  filter: false,
  suppressHeaderMenuButton: true,
};
export const collectionValueFormatter = <T>(
  collection: T[],
  valuePropertyName: keyof T,
  renderValue: (arg: T) => string,
  value: string | null | undefined
): string => {
  if (!value) {
    return '';
  } else {
    const matchingObject = collection.find(
      (x) => x[valuePropertyName] === value
    );

    if (matchingObject) {
      return renderValue(matchingObject);
    } else {
      return value;
    }
  }
};

export const generateSelectColDef = <
  Column extends keyof GridData,
  Collection extends { [K in IdColumn]: string },
  IdColumn extends keyof Collection = keyof Collection,
>(
  column: Column,
  {
    headerName,
    collection,
    collectionValues,
    collectionIdColumn,
    renderCollectionItem,
  }: {
    headerName: string;
    collection: Collection[];
    collectionValues: string[];
    collectionIdColumn: IdColumn;
    renderCollectionItem: (i: Collection) => string;
  }
): ColDef<GridData> => ({
  headerName,
  field: column,
  minWidth: 130,
  cellRenderer: CustomCellSelectValue,
  cellRendererParams: {
    checkValidValue: (
      value: GridData[Column],
      dataType: 'multi-channel' | 'single-channel' | 'channel'
    ) => {
      if (dataType === 'multi-channel' || dataType === 'single-channel') {
        // @ts-expect-error same as above
        return collection.some((x) => x[collectionIdColumn] === value);
      }
      return true;
    },
  } as CustomCellSelectValueValidatorProp<GridData[Column]>,
  cellEditor: 'agRichSelectCellEditor',
  valueFormatter: ({ value }) =>
    collectionValueFormatter(
      collection,
      collectionIdColumn,
      renderCollectionItem,
      value as string | null | undefined
    ),
  valueParser: (props) => {
    // if empty string or undefined is being set, set null
    if (props.newValue === '' || props.newValue === undefined) {
      return null;
    }

    return props.newValue as GridData[Column];
  },
  cellEditorParams: {
    values: collectionValues,
    formatValue: (value) =>
      collectionValueFormatter(
        collection,
        collectionIdColumn,
        renderCollectionItem,
        value as string | null | undefined
      ),
    allowTyping: true,
    searchType: 'matchAny',
    filterList: true,
    highlightMatch: true,
    valueListMaxHeight: 220,
  } as RichCellEditorParams<GridData, GridData[Column]>,
  resizable: true,
  editable: (params) => {
    return params?.data?.type !== 'channel';
  },
});

export const generateTextColDef = <column extends keyof GridData>(
  column: column,
  { headerName }: { headerName: string }
): ColDef<GridData, GridData[column]> => ({
  headerName,
  // @ts-expect-error I'm not sure how to make generic work with prop
  // enumeration of GridDataFlat.
  field: column,
  valueParser: (props) => {
    // if null or undefined is being set, set instead empty string
    if (props.newValue === null || props.newValue === undefined) {
      return '' as GridData[column];
    }

    return props.newValue as GridData[column];
  },
  editable: (params) => {
    return params?.data?.type !== 'channel';
  },
  minWidth: 130,
  cellEditor: 'agTextCellEditor',
  tooltipValueGetter: ({ value, valueFormatted }) => valueFormatted ?? value,
  resizable: true,
});
export const renderStaining = (staining: Staining): string => staining.name;
export const renderScanner = (scanner: Scanner): string =>
  scanner.model ? `${scanner.vendor} ${scanner.model}` : scanner.vendor;
export const renderLocalization = (tissue: Tissue): string => tissue.name;
export const renderDisease = (disease: Disease): string => disease.name;
export const renderPreparationType = (
  preparationType: PreparationType
): string => preparationType.name;
export const renderSampleType = (sampleType: SampleType): string =>
  sampleType.name;
export const renderMorphology = (morphology: Morphology): string =>
  morphology.displayName;
export const renderCancerSite = (cancerSite: CancerSite): string =>
  cancerSite.name;

const isChannelsValid = (
  channels: (GridData | undefined)[] | undefined,
  stainings: Staining[]
): boolean => {
  if (channels && channels.length > 0) {
    return channels.every((channel) => {
      if (!channel?.selectedOption) {
        return false;
      }
      return stainings.some(
        (staining) => staining.name === channel.selectedOption
      );
    });
  }
  return false;
};

export const isValidRowDatum = (
  rowDatum: GridData,
  api: GridApi<GridData>,
  stainings: Staining[]
): boolean => {
  if (rowDatum.type === 'single-channel' || rowDatum.type === 'multi-channel') {
    if (rowDatum.type === 'multi-channel' && api) {
      const multiChannel = api.getRowNode(rowDatum.rowId);
      const channels = multiChannel?.allLeafChildren?.map(
        (channel) => channel.data
      );
      const isValidAllChannelsValid = isChannelsValid(channels, stainings);

      const multiChannelData = multiChannel?.data;
      if (!multiChannelData) {
        return false;
      }
      const isMultiChannelEntriesValid =
        Boolean(multiChannelData.scannerId) &&
        Boolean(multiChannelData.tissue) &&
        Boolean(multiChannelData.caseId) &&
        Boolean(multiChannelData.disease) &&
        Boolean(multiChannelData.samplePreparation) &&
        Boolean(multiChannelData.sampleType) &&
        Boolean(multiChannelData.morphology) &&
        Boolean(multiChannelData.cancerSite);

      return isMultiChannelEntriesValid && isValidAllChannelsValid;
    }
    return (
      Boolean(rowDatum.staining) &&
      Boolean(rowDatum.scannerId) &&
      Boolean(rowDatum.tissue) &&
      Boolean(rowDatum.caseId) &&
      Boolean(rowDatum.disease) &&
      Boolean(rowDatum.samplePreparation) &&
      Boolean(rowDatum.sampleType) &&
      Boolean(rowDatum.morphology) &&
      Boolean(rowDatum.cancerSite)
    );
  }
  return true;
};
export function getChannelId(
  channel: MultiChannelSlideFileChannel,
  index: number
): string {
  return channel.id ?? `${channel.name ?? 'channel'}_${index}`;
}
