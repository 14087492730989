import React from 'react';
import Icon from '../Icon';
import { $FormLabel, $FormLabelIconWrapper, } from './FormLabel.styles';
const FormLabel = ({ icon, children, isRequired, ...rest }) => {
    return (React.createElement($FormLabel, { isRequired: isRequired, ...rest },
        icon && (React.createElement($FormLabelIconWrapper, null,
            React.createElement(Icon, { icon: icon, size: "small" }))),
        children));
};
export default FormLabel;
