import React, { forwardRef } from 'react';
import { useTheme } from 'styled-components';
import OneLiner from '../OneLiner';
import { $Header, $HeaderAction, $HeaderContent, $HeaderTitle, } from './Header.styles';
const Header = forwardRef(({ actionLeft, actionRight, children, title, size = 'button', color, ...props }, ref) => {
    const theme = useTheme();
    return (React.createElement($Header, { "data-testid": "header", size: size, color: color || theme.colors.primary, animate: { backgroundColor: color }, ref: ref, ...props },
        React.createElement($HeaderAction, null, actionLeft),
        title && (React.createElement($HeaderTitle, { "data-testid": "header-title" },
            React.createElement(OneLiner, null, title))),
        children && React.createElement($HeaderContent, null, children),
        React.createElement($HeaderAction, null, actionRight)));
});
Header.displayName = 'Header';
export default Header;
