import React from 'react';
import { useField } from 'react-final-form';
import { $FinalFormFieldWrapper, combineValidators, pickValidator, } from '../utils/finalForm/common';
import { SelectSingle, constructInvalidSelectOption, } from './SelectSingle.component';
export const FINAL_FORM_SELECT_SINGLE_METADATA = {
    fieldType: 'select',
};
export const FinalFormSelectSingle = ({ name, label, options, required = false, validate, style, showErrorMessage = true, onClickWithError, onChangeListener, isLoading, isHeadingField = false, disabled, lockValue, isClearable = false, tabIndex, ariaLabel, selectStyle, }) => {
    const valueMissingFromOptionsValidator = (value) => 
    // value is not empty
    value &&
        // value is not in the list of the options
        options.every((option) => option.value !== value)
        ? 'Invalid option'
        : undefined;
    const { input, meta } = useField(name, {
        allowNull: true,
        validate: pickValidator(required, combineValidators(valueMissingFromOptionsValidator, validate)),
        format: (value) => {
            const result = options.find((option) => option.value === value);
            if (result === undefined) {
                return constructInvalidSelectOption(value);
            }
            return result;
        },
        data: FINAL_FORM_SELECT_SINGLE_METADATA,
    });
    const isDropdownDisabled = Boolean(meta.error && onClickWithError);
    return (React.createElement($FinalFormFieldWrapper, { style: style, onClickCapture: (e) => {
            if (isDropdownDisabled) {
                e.preventDefault();
                e.stopPropagation();
                onClickWithError?.();
            }
        } },
        React.createElement(SelectSingle, { ariaLabel: ariaLabel, tabIndex: tabIndex, id: name, onChange: (newValue) => {
                input.onChange(newValue !== null ? newValue.value : null);
                if (onChangeListener)
                    onChangeListener(newValue?.value);
            }, label: label, value: lockValue || (input.value?.value ?? null), allowInvalidValue: true, options: options, required: required, disabled: disabled, isLoading: isLoading, isClearable: isClearable, onFocus: () => {
                input.onFocus();
            }, onBlur: () => {
                input.onBlur();
                if (isHeadingField) {
                    input.onChange(null);
                }
            }, errorMessage: showErrorMessage ? meta.error : '', isInvalid: meta.error && meta.touched, openMenuOnClick: !isDropdownDisabled, openMenuOnFocus: !isDropdownDisabled, selectStyle: selectStyle })));
};
