import {
  Button,
  IconButton,
  Input,
  Tooltip,
  useDisclosure,
  VStack,
} from '@aignostics/components';
import { AnimatePresence } from 'framer-motion';
import React, { ReactElement } from 'react';
import * as uuid from 'uuid';
import { AnnotationCategorySetModule } from '../../../../types';
import {
  $AnnotationCategorySetModulesBlock,
  $AnnotationCategorySetModulesBlockToggle,
  $AnnotationCategorySetModulesBlockToggleText,
  $ButtonContainer,
  $ModuleList,
  $ModuleRow,
  $ModulesListHeader,
  $ModulesTableCell,
  $ModulesTableCellCategoryCount,
  $ModulesTableHeaderName,
} from './AnnotationCategorySetForm.styles';

export const ANNOTATION_CATEGORY_SET_MODULE_NAME_TAKEN_ERROR_TEXT =
  'This module name is already taken.';

const REMOVE_MODULE_TOOLTIP_TEXT =
  'You cannot delete this module if it has attached categories.';

const ToolTip = (): ReactElement => {
  return <div>{REMOVE_MODULE_TOOLTIP_TEXT}</div>;
};

export interface AnnotationCategorySetFormModulesProps {
  mode: 'create' | 'edit';
  modules: AnnotationCategorySetModule[];
  onAddModule: (module: AnnotationCategorySetModule) => void;
  onUpdateModuleName: (index: number, newName: string) => void;
  onRemoveModule: (index: number) => void;
}

export const AnnotationCategorySetFormModules = ({
  mode,
  modules,
  onAddModule,
  onUpdateModuleName,
  onRemoveModule,
}: AnnotationCategorySetFormModulesProps): ReactElement => {
  const { isOpen, close, open } = useDisclosure(true);

  return (
    <VStack spacing="16" style={{ width: '100%' }}>
      <$AnnotationCategorySetModulesBlockToggle
        small
        icon={isOpen ? 'ChevronUp' : 'ChevronDown'}
        onClick={() => {
          if (isOpen) {
            close();
          } else {
            open();
          }
        }}
        variant="ghost"
        aria-expanded={isOpen}
      >
        <$AnnotationCategorySetModulesBlockToggleText>
          Modules
        </$AnnotationCategorySetModulesBlockToggleText>
      </$AnnotationCategorySetModulesBlockToggle>

      <AnimatePresence initial={isOpen}>
        {isOpen && (
          <$AnnotationCategorySetModulesBlock
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
          >
            {modules.length > 0 ? (
              <>
                {mode === 'edit' ? (
                  <$ModulesListHeader>
                    <$ModulesTableHeaderName>Module</$ModulesTableHeaderName>
                    <$ModulesTableCellCategoryCount>
                      Categories
                    </$ModulesTableCellCategoryCount>
                    <$ModulesTableCell>Action</$ModulesTableCell>
                  </$ModulesListHeader>
                ) : null}
                <$ModuleList>
                  {modules.map((module, index) => {
                    const allOtherModuleNames = modules
                      .filter(({ moduleId }) => moduleId !== module.moduleId)
                      .map(({ moduleName }) => moduleName);

                    const isModuleInvalid = allOtherModuleNames.includes(
                      module.moduleName
                    );

                    const cannotDeleteModule =
                      module.categoryCount !== undefined &&
                      module.categoryCount > 0;

                    return (
                      <$ModuleRow
                        key={`${index}-annotation-category-set-module-name-input`}
                      >
                        <Input
                          id={`${index}-annotation-category-set-module-name-input-id`}
                          type="text"
                          onChange={(value) => {
                            onUpdateModuleName(index, value);
                          }}
                          placeholder="Enter Module Name"
                          isInvalid={isModuleInvalid}
                          errorMessage={
                            ANNOTATION_CATEGORY_SET_MODULE_NAME_TAKEN_ERROR_TEXT
                          }
                          value={module.moduleName ?? ''}
                          required={true}
                          aria-label={`${index}-module-name`}
                        />
                        {mode === 'edit' ? (
                          <$ModulesTableCellCategoryCount>
                            {module.categoryCount}
                          </$ModulesTableCellCategoryCount>
                        ) : null}
                        {cannotDeleteModule ? (
                          <Tooltip text={<ToolTip />}>
                            {(tooltipProps) => (
                              <RemoveModuleButton
                                disabled={cannotDeleteModule}
                                index={index}
                                onRemoveModule={onRemoveModule}
                                {...tooltipProps}
                              />
                            )}
                          </Tooltip>
                        ) : (
                          <RemoveModuleButton
                            disabled={cannotDeleteModule}
                            index={index}
                            onRemoveModule={onRemoveModule}
                          />
                        )}
                      </$ModuleRow>
                    );
                  })}
                </$ModuleList>
              </>
            ) : null}
            <Button
              small
              variant="ghost"
              onClick={() => {
                onAddModule({
                  moduleId: uuid.v4(),
                  moduleName: '',
                });
              }}
              disabled={modules.some(
                ({ moduleName }) =>
                  !moduleName || moduleName.trim().length === 0
              )}
            >
              Add new module
            </Button>
          </$AnnotationCategorySetModulesBlock>
        )}
      </AnimatePresence>
    </VStack>
  );
};

const RemoveModuleButton = ({
  onRemoveModule,
  index,
  disabled,
  ...rest
}: {
  onRemoveModule: (index: number) => void;
  index: number;
  disabled: boolean;
}) => {
  return (
    <$ButtonContainer {...rest}>
      <IconButton
        aria-label="remove"
        icon="MinusCircle"
        onClick={() => {
          onRemoveModule(index);
        }}
        disabled={disabled}
        style={{ height: '28px' }}
      />
    </$ButtonContainer>
  );
};
