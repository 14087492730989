import {
  ApolloClient,
  createHttpLink,
  NormalizedCacheObject,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import * as Sentry from '@sentry/react';
import createCache from './createCache';

/**
 * Creates a new Apollo client instance for the given URI and sets request header information.
 * @param uri         The API endpoint URI
 * @param getToken    Function to get the authentication token
 * @param impersonate Optional user ID for impersonation
 * @returns           Apollo client instance
 */
export function createClient(
  uri: string,
  getToken: () => Promise<string>,
  impersonate: string | null
): ApolloClient<NormalizedCacheObject> {
  const httpLink = createHttpLink({ uri });

  /**
   * Send authorization token and optional impersonate user ID via request header.
   */
  const authLink = setContext(async () => {
    const token = await getToken();
    const headers: Record<string, string> = {
      authorization: `Bearer ${token}`,
    };

    if (impersonate !== null) {
      headers.impersonate = impersonate;
    }
    return { headers };
  });

  // Error handling link to log network errors on Sentry
  const errorLink = onError(({ networkError, operation }) => {
    if (networkError) {
      const errorInfo = {
        message:
          networkError instanceof Error
            ? networkError.message
            : String(networkError),
        stack:
          networkError instanceof Error
            ? networkError.stack
            : 'No stack trace available',
        operationName: operation.operationName,
        variables: operation.variables,
        query: operation.query.loc?.source.body,
      };

      Sentry.captureException(
        new Error(`[Network error]: ${errorInfo.message}`),
        {
          extra: errorInfo,
        }
      );

      const networkMessage =
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        networkError?.result.error.message;

      if (
        networkMessage &&
        networkMessage ===
          'The user has not accepted the current privacy policy yet'
      ) {
        networkError.message = networkMessage;
      }
    }
  });

  const link = errorLink.concat(authLink.concat(httpLink));

  return new ApolloClient({ link, cache: createCache() });
}
