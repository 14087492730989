import { Dialog } from '@aignostics/components';
import React, { ReactElement } from 'react';

export interface UnsavedChangesModalProps {
  isVisible: boolean;
  onSave: () => void;
  onDiscard: () => void;
  onClose: () => void;
}

export const UNSAVED_CHANGED_TEXT = 'You have unsaved changes!';

export const UnsavedChangesModal = ({
  isVisible,
  onSave,
  onClose,
  onDiscard,
}: UnsavedChangesModalProps): ReactElement => {
  return (
    <Dialog
      isVisible={isVisible}
      title="Unsaved changes"
      primaryAction={{
        label: 'Save Changes',
        onClick: onSave,
      }}
      secondaryAction={{ label: 'Discard Changes', onClick: onDiscard }}
      onClose={onClose}
    >
      <div> {UNSAVED_CHANGED_TEXT} </div>
    </Dialog>
  );
};
