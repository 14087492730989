import { RevokedAccessModal } from '@aignostics/management-ui';
import React from 'react';
import { useAuthController, useAuthState } from '../../App/AuthProvider';

export const RevokedAccessModalWrapper = (): JSX.Element => {
  const { state } = useAuthState();
  const { signOut } = useAuthController();

  return <RevokedAccessModal state={state} signOut={signOut} />;
};
