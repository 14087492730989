import { Icon } from '@aignostics/components';
import type { CustomCellRendererProps } from 'ag-grid-react';
import React, { ReactElement, useEffect } from 'react';
import styled from 'styled-components';
import { GridData } from '../MetadataGrid.types';

const $TextWrapper = styled.div<{ isInvalid?: boolean }>`
  overflow: hidden;
  text-overflow: ellipsis;
  ${(isInvalid) => (isInvalid ? 'flex: 1;' : '')}
`;

const $InvalidValueWrapper = styled.div`
  display: flex;
  align-content: stretch;
  gap: 5px;
`;

export type CustomCellSelectValueValidatorProp<T> = {
  data: GridData | undefined;
  checkValidValue: (
    value: T,
    dataType: 'multi-channel' | 'single-channel' | 'channel'
  ) => boolean;
};

const alertIconStyle = { alignSelf: 'center' } as const;

type CustomCellSelectValueProps<TData, TValue> = CustomCellRendererProps<
  TData,
  TValue
> &
  CustomCellSelectValueValidatorProp<TValue>;

/**
 * Custom text cell renderer for selects, displays invalid icon when incorrect
 * value is specified.
 */
export const CustomCellSelectValue = <TData, TValue>({
  data,
  setTooltip,
  value,
  valueFormatted,
  checkValidValue,
  colDef,
}: CustomCellSelectValueProps<TData, TValue>): ReactElement => {
  const renderValue = String(valueFormatted ?? value ?? '');
  let isValid = true;
  const dataType = data?.type;
  if (value !== null && dataType !== undefined) {
    isValid = checkValidValue(value as TValue, dataType);
  }

  useEffect(() => {
    setTooltip(
      isValid
        ? renderValue
        : `"${renderValue}" is invalid value for ${colDef!.headerName}`
    );
  }, [renderValue, setTooltip, isValid, colDef]);

  if (!isValid) {
    return (
      <$InvalidValueWrapper>
        <$TextWrapper isInvalid>{renderValue}</$TextWrapper>
        <Icon
          icon="AlertTriangle"
          size="input"
          color="error"
          style={alertIconStyle}
        />
      </$InvalidValueWrapper>
    );
  }

  return <$TextWrapper>{renderValue}</$TextWrapper>;
};
