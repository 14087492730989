import React from 'react';
import { useLocation } from 'react-router-dom';
import { $SubNavigation, $SubNavigationList, $TabLink, } from './SubNavigation.styles';
export const SubNavigation = ({ routes }) => {
    const { pathname } = useLocation();
    const activeRoute = routes.findLast(({ to = '' }) => pathname.search(to) > -1);
    return (React.createElement($SubNavigation, { className: "SubNavigationClass", "aria-label": "SubNavigation" },
        React.createElement($SubNavigationList, null, routes.map(({ to, name }) => (React.createElement("li", { key: to || 'ROOT', style: { display: 'flex' } },
            React.createElement($TabLink, { to: to ? `../${to}` : '../', className: activeRoute?.to === to ? 'active' : '' }, name)))))));
};
