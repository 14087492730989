import React, { ReactElement, ReactNode } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuthUser } from '../../App/AuthProvider';

const getOrganizationUUIDFromPath = (pathname: string): string | undefined => {
  const match = /\/([0-9a-fA-F-]{36})(\/|$)/.exec(pathname);
  return match ? match[1] : undefined;
};

export function UserEnabledGuard({
  children,
}: {
  children: ReactNode;
}): ReactElement {
  const location = useLocation();
  const navigate = useNavigate();
  const user = useAuthUser();

  const currentOrganizationUUID = getOrganizationUUIDFromPath(
    location.pathname
  );

  const isUserRoleEnabledInCurrentOrg = user.roles.some(
    (role) =>
      role.organization.uuid === currentOrganizationUUID && !role.isDisabled
  );

  if (!isUserRoleEnabledInCurrentOrg) {
    const firstEnabledRole = user.roles.find((role) => !role.isDisabled);

    if (firstEnabledRole) {
      void navigate(
        `/${firstEnabledRole.organization.uuid}/${location.search}${location.hash}`
      );
    } else {
      const returnUrlParam = encodeURIComponent(
        `${location.pathname}${location.search}${location.hash}`
      );
      void navigate(
        `/login/sign-in${returnUrlParam ? `?returnUrl=${returnUrlParam}` : ''}`
      );
    }
  }

  return <>{children}</>;
}
