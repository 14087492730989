export const getMenuOptions = (
  availableCoefficients: {
    registrationId: string;
    registrationName: string;
    referenceId: string;
  }[],
  registrationId: string | null
): Array<{
  value: string;
  label: string;
}> => {
  if (!registrationId) {
    return availableCoefficients.map((coef) => ({
      value: coef.registrationId,
      label: coef.registrationName,
    }));
  }

  return availableCoefficients
    .slice()
    .sort((a, b) => {
      if (a.registrationId === registrationId) return -1;
      if (b.registrationId === registrationId) return 1;
      return 0;
    })
    .map((coef) => ({
      value: coef.registrationId,
      label: coef.registrationName,
    }));
};
