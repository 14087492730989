import { useDebounce } from '@aignostics/hooks';
import React, { useCallback, useEffect, useRef, useState, } from 'react';
import Icon from '../Icon';
import IconButton from '../IconButton';
import OneLiner from '../OneLiner';
import { CopyContainer, ExpandSubRowButton, ExpandSubRowContainer, TableCell, TableCellContent, } from './TableComponent.styles';
const TableCellComponent = ({ row, header, isOpen, haveSubRowsOpen, toggleSubRow, enableSelection, selector, }) => {
    const renderValue = header.renderCell(row);
    const [mouseOver, setIsMouseOver] = useState(false);
    const [isCopiedValue, setCopiedValue] = useState(false);
    useEffect(() => {
        setTimeout(() => {
            setCopiedValue(false);
        }, 5000);
    }, [isCopiedValue]);
    const isMouseOver = useDebounce(mouseOver, 500);
    const textRef = useRef(null);
    const setMouseOver = useCallback((value) => {
        if (header.copyToClipboard === undefined)
            return;
        setIsMouseOver(value);
    }, [header.copyToClipboard]);
    const isExpandable = header.withSubRows &&
        (header.subRowsConfig?.data(row).length || header.renderSubRows);
    return (React.createElement(TableCell, { enableSelection: enableSelection, maxWidth: header.maxWidth, minWidth: header.minWidth, align: header.align, onClick: () => {
            if (header.withSubRows) {
                toggleSubRow(header);
            }
        }, onMouseEnter: () => {
            setMouseOver(true);
        }, onMouseLeave: () => {
            setMouseOver(false);
        }, "aria-label": `${header.id} on row ${selector}` },
        React.createElement(TableCellContent, { ref: textRef, align: header.align, isOpen: haveSubRowsOpen },
            isExpandable && renderValue ? (React.createElement(ExpandSubRowButton, { role: "button", "aria-label": `expand ${header.id}`, align: header.align, maxWidth: header.maxWidth },
                React.createElement(ExpandSubRowContainer, null, renderValue),
                React.createElement(Icon, { icon: isOpen ? 'ArrowUp' : 'ArrowDown', size: "xsmall" }))) : typeof renderValue === 'string' ? (React.createElement(OneLiner, { truncate: "middle" }, renderValue)) : (renderValue),
            isMouseOver &&
                !header.withSubRows &&
                header.copyToClipboard !== undefined && (React.createElement(CopyContainer, { isOpen: haveSubRowsOpen },
                React.createElement(IconButton, { icon: isCopiedValue ? 'Check' : 'Copy', size: 'input', onClick: async (e) => {
                        if (header.copyToClipboard) {
                            await navigator.clipboard.writeText(header.copyToClipboard(row));
                        }
                        setCopiedValue(true);
                        e?.stopPropagation();
                    } }))))));
};
export default TableCellComponent;
