import {
  ErrorPageNotFound,
  ErrorPageUnauthorized,
} from '@aignostics/components';
import {
  E_PROJECT_NOT_FOUND,
  E_PROJECT_UNAUTHORIZED,
  E_SUBPROJECT_NOT_FOUND,
  E_SUBPROJECT_UNAUTHORIZED,
  E_WSI_NOT_FOUND,
  E_WSI_UNAUTHORIZED,
} from '@aignostics/core';
import { type ApolloError } from '@apollo/client';
import React, { type ReactElement } from 'react';

type ErrorPagePair = [string, ReactElement];
// hoh for higher order hook
const hohHandleQueryErrors = (errorPagePairs: ErrorPagePair[]) => {
  return function useHandleQueryErrors(
    apolloError: ApolloError | undefined
  ): ReactElement | null {
    for (const [error, page] of errorPagePairs) {
      if (apolloError?.graphQLErrors.some(({ message }) => message === error)) {
        return page;
      }
    }

    return null;
  };
};
/**
 * Returns not authorized / not found view if graphql query return those errors.
 */
export const useHandleProjectQueryErrors = hohHandleQueryErrors(
  /* eslint-disable react/jsx-key */ [
    [E_PROJECT_UNAUTHORIZED, <ErrorPageUnauthorized entityType="project" />],
    [E_PROJECT_NOT_FOUND, <ErrorPageNotFound entityType="project" />],
  ]
  /* eslint-enable react/jsx-key */
);

/**
 * Returns not authorized / not found view if graphql query return those errors.
 */
export const useHandleSubprojectQueryErrors = hohHandleQueryErrors(
  /* eslint-disable react/jsx-key */ [
    [
      E_SUBPROJECT_UNAUTHORIZED,
      <ErrorPageUnauthorized entityType="subproject" />,
    ],
    [E_SUBPROJECT_NOT_FOUND, <ErrorPageNotFound entityType="subproject" />],
  ]
  /* eslint-enable react/jsx-key */
);

/**
 * Returns not authorized / not found view if graphql query return those errors.
 */
export const useHandleSlideQueryErrors = hohHandleQueryErrors(
  /* eslint-disable react/jsx-key */ [
    [E_WSI_UNAUTHORIZED, <ErrorPageUnauthorized entityType="slide" />],
    [E_WSI_NOT_FOUND, <ErrorPageNotFound entityType="slide" />],
  ]
  /* eslint-enable react/jsx-key */
);
