import {
  Button,
  Icon,
  Link,
  TableCell,
  TableRow,
  Tooltip,
} from '@aignostics/components';
import { buildClientWsiUrl } from '@aignostics/utils';
import { AnimatePresence } from 'framer-motion';
import React, { ReactElement, useState } from 'react';
import { WsiThumbnail } from '../../../../../../components';
import { SubProject, Wsi } from '../../../../../../types';
import {
  $ThumbnailWrapper,
  $WarningTooltip,
  $WsiRowRightContainer,
} from '../EditStainingsModal.styles';
import { EditStainingsFluorescenceRow } from './EditStainingsFluorescenceRow';
import { EditStainingsStainingRow } from './EditStainingsStainingRow';

export const EditStainingsWsiRow = ({
  wsi,
  subproject,
  switchStaining,
  isLoading,
  getToken,
  rasterTileServerUrl,
  organizationUuid,
}: {
  subproject: SubProject;
  wsi: Wsi;
  switchStaining: (wsiId: string, originType: string, value: boolean) => void;
  isLoading: boolean;
  rasterTileServerUrl: string;
  getToken: () => Promise<string>;
  organizationUuid: string;
}): ReactElement => {
  const [showStainings, setShowStainings] = useState(false);

  const activeDisplayedCount =
    (wsi.stainings?.filter(({ isActive }) => isActive).length ?? 0) +
    (!wsi.isBlackLayer
      ? wsi.fluorescence.files.filter(
          (f) => f.isVisible && f.isParentBlackLayer
        ).length ?? 0
      : 0);

  return (
    <React.Fragment key={wsi.id}>
      <tr style={{ height: '8px' }} />

      <TableRow aria-label={`Assigned ${wsi.id}`}>
        <TableCell>
          <$ThumbnailWrapper>
            <WsiThumbnail
              wsiId={wsi.id}
              getToken={getToken}
              rasterTileServerUrl={rasterTileServerUrl}
              size="small"
            />
          </$ThumbnailWrapper>
        </TableCell>
        <TableCell tooltipText={wsi.name} style={{ maxWidth: '20vw' }}>
          <Link
            href={buildClientWsiUrl(
              organizationUuid,
              subproject.projectId,
              subproject.id,
              wsi.id
            )}
            target="_blank"
          >
            {wsi.name}
          </Link>
        </TableCell>
        <TableCell tooltipText={wsi.scanner.vendor}>
          {wsi.scanner.vendor}
        </TableCell>
        <TableCell tooltipText={wsi.staining || undefined}>
          {wsi.staining}
        </TableCell>
        <TableCell colSpan={2} align="right">
          <$WsiRowRightContainer>
            {!isLoading && activeDisplayedCount < wsi.layersVisibleCount && (
              <Tooltip
                text={
                  <$WarningTooltip>
                    Some active stainings are hidden behind by Block/Section
                    matching condition.
                  </$WarningTooltip>
                }
              >
                {(tooltipProps) => (
                  <div {...tooltipProps} style={{ cursor: 'pointer' }}>
                    <Icon icon={'AlertCircle'} color="error" />
                  </div>
                )}
              </Tooltip>
            )}
            {(!wsi.isBlackLayer && wsi.fluorescence.files.length > 0) ||
            wsi.stainings?.length ? (
              <Button
                small
                variant="ghost"
                onClick={() => {
                  setShowStainings((p) => !p);
                }}
              >
                {showStainings ? 'Hide ' : 'Show '} Stainings
              </Button>
            ) : null}
          </$WsiRowRightContainer>
        </TableCell>
      </TableRow>
      <AnimatePresence>
        {showStainings && (
          <>
            <>
              {wsi.stainings?.map((staining) => {
                return (
                  <EditStainingsStainingRow
                    stainingLayer={staining}
                    key={staining.id}
                    subProjectId={subproject.id}
                    switchStaining={switchStaining}
                    rasterTileServerUrl={rasterTileServerUrl}
                    getToken={getToken}
                  />
                );
              })}
            </>
            <>
              {!wsi.isBlackLayer &&
                wsi.fluorescence?.files.length > 0 &&
                wsi.fluorescence.files.map((group, index) => {
                  const origin = group.uuid;
                  const isFluorescenceVisible = group.isVisible;
                  return (
                    <EditStainingsFluorescenceRow
                      fluorescence={group.channels}
                      subProjectId={subproject.id}
                      origin={origin}
                      fluorescenceVisible={isFluorescenceVisible}
                      key={`fluorescence ${index}`}
                      // used for backward compatibility
                      name={group.wsiId ?? wsi.name}
                      switchStaining={switchStaining}
                      getToken={getToken}
                      rasterTileServerUrl={rasterTileServerUrl}
                    />
                  );
                })}
            </>
          </>
        )}
      </AnimatePresence>
    </React.Fragment>
  );
};
