import { Flex } from '@radix-ui/themes';
import { AnimatePresence } from 'framer-motion';
import React, {
  ReactElement,
  ReactNode,
  useContext,
  useRef,
  useState,
} from 'react';
import { createPortal } from 'react-dom';
import { UserSidebar } from '../../UserSidebar';
import { MenuSidebar } from '../../__Navigation';
import AppHeader from '../../__Navigation/AppHeader';
import {
  LayoutContext,
  LayoutContextProvider,
  SideNav,
} from './Layout.Context';

export function Layout({ children }: { children: ReactNode }): ReactElement {
  const [sideNav, setSideNav] = useState<SideNav | null>(null);
  const closeSideNav = () => {
    setSideNav(null);
  };
  const headerContentRef = useRef<HTMLDivElement>(null);

  return (
    <LayoutContextProvider value={{ sideNav, setSideNav, headerContentRef }}>
      {/* Main Page Layout Container */}
      <Flex
        direction="column"
        style={{
          width: '100vw',
          position: 'relative',
          zIndex: '0',
          height: '100dvh',
        }}
      >
        <AppHeader sideNav={sideNav} setSideNav={setSideNav}>
          <div
            style={{ display: 'flex', flexGrow: 1 }}
            ref={headerContentRef}
          />
        </AppHeader>

        <Flex direction="column" style={{ flexGrow: 1, overflow: 'auto' }}>
          {children}
        </Flex>
      </Flex>

      {/* Sidebars */}
      <AnimatePresence>
        <>
          {sideNav === 'user' && <UserSidebar closeSideNav={closeSideNav} />}
          {sideNav === 'menu' && <MenuSidebar closeSideNav={closeSideNav} />}
        </>
      </AnimatePresence>
    </LayoutContextProvider>
  );
}

export function HeaderContent({
  children,
}: {
  children: ReactNode;
}): ReactElement | null {
  const { headerContentRef } = useContext(LayoutContext);

  // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
  if (headerContentRef === null || headerContentRef.current === null) {
    return null;
  }
  return createPortal(children, headerContentRef.current);
}
