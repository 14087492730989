import { SENTRY_ENABLED_ENVIRONMENTS, User } from '@aignostics/core';
import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import {
  Routes,
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import { BuildEnv, appConfig } from './components/App/appConfig';

/** Initialize Sentry logging service */
export const initSentry = (dsn: string, buildEnv: BuildEnv): void => {
  // Enable Sentry only for production builds, disable during e2e cypress tests

  if (
    !SENTRY_ENABLED_ENVIRONMENTS.includes(buildEnv) ||
    window.NO_THIRDPARTY_RESOURCES
  ) {
    return;
  }

  Sentry.init({
    environment: buildEnv,
    dsn,
    release: appConfig.version,
    integrations: [
      Sentry.reactRouterV7BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration(),
      Sentry.browserTracingIntegration(),
    ],
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1.0,
    tracesSampleRate: 0.1,
    ignoreErrors: [
      // Happens too often and is not actionable
      'OTLPExporterError',
      // Not actionable
      'ECONNREFUSED',
      // Not actionable
      'auth/network-request-failed',
    ],
  });
};

/** Pass user information to Sentry */
export const setUserSentry = (user: User): void => {
  const { id, email, name } = user;
  Sentry.setUser({
    id,
    email: email ?? undefined,
    username: name ?? undefined,
  });
};

Sentry.feedbackIntegration();
export const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouterV7(createBrowserRouter);

export const SentryRoutes = Sentry.withSentryReactRouterV7Routing(Routes);
