import styled from 'styled-components';
const commonStyle = {
    appearance: 'none',
    outline: 'none',
};
const commonDisabledStyle = (theme) => ({
    cursor: 'not-allowed',
    borderColor: theme.colors.mid,
    color: theme.colors.transparentDark,
    backgroundColor: theme.colors.white,
});
const commonBorderStyle = (theme, type) => ({
    transition: 'border-color 150ms ease-in-out, box-shadow 150ms ease-in-out',
    border: type === 'search'
        ? `1px solid ${theme.colors.base}`
        : `1px solid ${theme.colors.mid}`,
    borderRadius: `${theme.spacings.radius}px`,
    outline: `${type === 'search' ? `3px solid ${theme.colors.light}` : ''} `,
});
const commonInvalidStyle = (theme) => ({
    borderColor: theme.colors.error,
});
const commonFontPadding = (theme, sizeVariant, regularLargePadding) => {
    switch (sizeVariant) {
        case 'small':
            return {
                ...theme.fontStyles.small,
                height: '18px',
                padding: `${theme.spacings.min}px ${theme.spacings.small}px`,
            };
        case 'regular':
            return {
                ...theme.fontStyles.small,
                height: `${theme.spacings.input}px`,
                padding: regularLargePadding,
            };
        case 'large':
            return {
                ...theme.fontStyles.base,
                height: `${theme.spacings.button}px`,
                padding: regularLargePadding,
            };
    }
};
export const $InputStaticPrefix = styled.div(({ theme, invalid, disabled, type, sizeVariant = 'regular' }) => ({
    ...commonStyle,
    flexGrow: 0,
    display: 'flex',
    alignItems: 'center',
    ...(disabled ? commonDisabledStyle(theme) : {}),
    backgroundColor: theme.colors.light,
    ...commonBorderStyle(theme, type),
    borderRight: 'none',
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    ...(invalid ? commonInvalidStyle(theme) : {}),
    ...commonFontPadding(theme, sizeVariant, `0 ${theme.spacings.base - 2}px 0 ${theme.spacings.base}px`),
}));
export const $Input = styled.input(({ theme, type, sizeVariant = 'regular', hasStaticPrefix }) => ({
    ...commonStyle,
    flexGrow: 1,
    width: '100%',
    '&:disabled': commonDisabledStyle(theme),
    ...commonBorderStyle(theme, type),
    ...(hasStaticPrefix
        ? { borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }
        : {}),
    '&:focus': {
        borderColor: theme.colors.primary,
        boxShadow: theme.glows.focus,
        outline: 'none',
    },
    '&::-webkit-search-cancel-button': {
        display: 'none',
    },
    '&::-webkit-search-decoration': {
        appearance: 'none',
    },
    '&[aria-invalid=true]': {
        ...commonInvalidStyle(theme),
        '&:focus': commonInvalidStyle(theme),
    },
    ...commonFontPadding(theme, sizeVariant, hasStaticPrefix
        ? `0 ${theme.spacings.base}px 0 ${theme.spacings.base - 2}px`
        : `0 ${theme.spacings.base}px`),
}));
export const $InputIconContainer = styled.div `
  position: absolute;
  right: 5px;
`;
export const $InputActions = styled.div `
  background-color: ${({ theme }) => theme.colors.light};
`;
