import { AdminSubprojectAlgorithms } from '@aignostics/algorithms-ui';
import { PageLayout, SubNavigation } from '@aignostics/components';
import { OrganizationRole } from '@aignostics/core';
import { useQuery } from '@apollo/client';
import React, { ReactElement } from 'react';
import { Navigate, Route, useParams } from 'react-router-dom';
import { useHandleSubprojectQueryErrors } from '../../../hooks/useHandleQueryErrors';
import { SubProject } from '../../../types';
import { FETCH_ADMIN_SUB_PROJECT_BASE_DETAILS } from '../../Projects/Admin/FETCH_ADMIN_SUB_PROJECT_DETAILS';
import AdminSubprojectAssignInteractiveOverlays from './AdminSubProjectAssignInteractiveOverlays/AdminSubProjectAssignInteractiveOverlays.component';
import { AdminSubProjectDetails } from './AdminSubProjectDetails';
import { AdminSubProjectSlides } from './AdminSubProjectSlides';
import { AdminSubprojectAnnotations } from './AdminSubprojectAnnotations';
import AdminSubprojectAssignStaticOverlays from './AdminSubprojectAssignStaticOverlays/AdminSubprojectAssignStaticOverlays.component';
import { DeleteSubproject } from './Delete';

interface AdminSubProjectProps {
  role: OrganizationRole;
  sentryRoutes: ReactElement;
  organizationUuid: string;
  rasterTileServerUrl: string;
  getToken: () => Promise<string>;
}

export const AdminSubProject = ({
  role,
  sentryRoutes,
  organizationUuid,
  rasterTileServerUrl,
  getToken,
}: AdminSubProjectProps): ReactElement => {
  const { projectId, subProjectId } = useParams<{
    projectId: string;
    subProjectId: string;
  }>();

  /** Fetch Sub Project */
  const {
    data: fetchSubprojectData,
    loading: fetchSubprojectLoading,
    error: fetchSubprojectError,
  } = useQuery<{
    subProject: SubProject;
  }>(FETCH_ADMIN_SUB_PROJECT_BASE_DETAILS, {
    skip: subProjectId === 'create',
    variables: { subProjectId },
  });

  const subproject = fetchSubprojectData?.subProject;

  const errorView = useHandleSubprojectQueryErrors(fetchSubprojectError);

  if (errorView) {
    return errorView;
  }

  if (!subProjectId || !projectId) {
    // If one pastes this url into a new tab the the location state will be
    // undefined.
    return <Navigate to="admin" />;
  }

  const subprojectUrlPrefix = `subproject/${subproject?.id}`;

  /* SubProject sections */
  const subRoutes = [
    { name: 'Details' },
    { name: 'Slides', to: `${subprojectUrlPrefix}/wsis` },
    { name: 'Algorithms', to: `${subprojectUrlPrefix}/algorithms` },
    { name: 'Annotations', to: `${subprojectUrlPrefix}/annotations` },
    { name: 'Static Overlays', to: `${subprojectUrlPrefix}/static-overlays` },
    {
      name: 'Interactive Overlays',
      to: `${subprojectUrlPrefix}/interactive-overlays`,
    },
    ...(role.scopes['subproject:delete']
      ? [{ name: 'Delete', to: `${subprojectUrlPrefix}/delete` }]
      : []),
  ];

  return (
    <PageLayout
      title={subproject?.name}
      description={subproject?.description}
      loading={fetchSubprojectLoading}
      error={fetchSubprojectError}
      currentOrganization={role.organization.name}
      expandableDescription
    >
      <SubNavigation routes={subRoutes} />
      {React.cloneElement(
        sentryRoutes,
        {},
        <>
          {/* Details */}
          <Route path="/" element={<AdminSubProjectDetails />} />

          {/* Slides */}
          <Route
            path={`wsis`}
            element={
              <AdminSubProjectSlides
                subprojectId={subProjectId}
                userRole={role}
                organizationUuid={organizationUuid}
                rasterTileServerUrl={rasterTileServerUrl}
                getToken={getToken}
              />
            }
          />
          <Route
            path={`algorithms`}
            element={
              <AdminSubprojectAlgorithms
                projectId={projectId}
                subprojectId={subProjectId}
                userRole={role}
                organizationUuid={organizationUuid}
              />
            }
          />

          {/* Annotations */}
          <Route
            path={`annotations`}
            element={<AdminSubprojectAnnotations />}
          />

          {/* Static Overlays */}
          <Route
            path={`static-overlays`}
            element={
              <AdminSubprojectAssignStaticOverlays
                subprojectId={subProjectId}
                projectId={projectId}
                organizationUuid={organizationUuid}
                userRole={role}
              />
            }
          />

          {/* Interactive Overlays */}
          <Route
            path={`interactive-overlays`}
            element={
              <AdminSubprojectAssignInteractiveOverlays
                projectId={projectId}
                subprojectId={subProjectId}
                organizationUuid={organizationUuid}
                userRole={role}
              />
            }
          />

          {/* Delete Subproject */}
          {role.scopes['subproject:delete'] && (
            <Route
              path={`delete`}
              element={
                <DeleteSubproject
                  projectId={projectId}
                  subProjectId={subProjectId}
                  organizationUuid={organizationUuid}
                />
              }
            />
          )}
        </>
      )}
    </PageLayout>
  );
};

export default React.memo(AdminSubProject);
