import {
  Button,
  Section,
  Tooltip,
  useDisclosure,
  useSnackbarMutations,
} from '@aignostics/components';
import { gql, useMutation, useQuery } from '@apollo/client';
import React, { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import { cacheDeleteSubProject } from '../../../../utils';
import { DELETE_SUB_PROJECT } from './DELETE_SUBPROJECT.queries';
import DeleteSubprojectModal from './DeleteSubprojectModal.component';

export const GET_DELETION_INFO = gql`
  query GET_DELETION_INFO(
    $subProjectId: ID!
    $pageSize: Int! = 20
    $page: Int! = 1
  ) {
    subProject(id: $subProjectId) {
      id
      name
      projectId
      wsisCount
      annotatedWsisCount
      annotationCategories {
        id
      }
    }
    algorithmJobs(
      subprojectId: $subProjectId
      pageSize: $pageSize
      page: $page
    ) {
      nodes {
        id
        status
      }
      pageInfo {
        totalElements
      }
    }
  }
`;

interface DeleteSubprojectProps {
  projectId: string;
  subProjectId: string;
  organizationUuid: string;
}

function DeleteSubproject({
  projectId,
  subProjectId,
  organizationUuid,
}: DeleteSubprojectProps): ReactElement {
  const navigate = useNavigate();

  const { data, loading: queryLoading } = useQuery(GET_DELETION_INFO, {
    variables: { subProjectId },
    fetchPolicy: 'network-only',
  });

  const { addSnackbar } = useSnackbarMutations();
  const [deleteSubproject, { loading }] = useMutation<
    void,
    {
      id: string;
      hasSlidesAssigned?: boolean;
      hasAnnotationsAssigned?: boolean;
      hasAnnotationCategoriesAssigned?: boolean;
      hasAlgorithmRunsAssigned?: boolean;
    }
  >(DELETE_SUB_PROJECT, {
    onError: (error) => {
      addSnackbar({ type: 'error', message: error.message });
    },
  });

  const deleteSubprojectModal = useDisclosure();

  const hasSlidesAssigned = data?.subProject.wsisCount > 0;
  const hasAnnotationsAssigned = data?.subProject.annotatedWsisCount > 0;
  const hasAnnotationCategoriesAssigned =
    data?.subProject.annotationCategories.length > 0;
  const hasAlgorithmRunsAssigned =
    data?.algorithmJobs.pageInfo.totalElements > 0;

  const hasItemsAssigned =
    hasSlidesAssigned ||
    hasAnnotationsAssigned ||
    hasAnnotationCategoriesAssigned ||
    hasAlgorithmRunsAssigned;

  const handleClick = () => {
    if (hasItemsAssigned) {
      deleteSubprojectModal.open();
    } else {
      void deleteSubproject({
        variables: { id: subProjectId },
        update: cacheDeleteSubProject(projectId),
      }).then(() => {
        void navigate(
          `/${organizationUuid}/admin/projects/${projectId}/subprojects`
        );
      });
    }
  };

  const disabled =
    queryLoading ||
    data?.algorithmJobs?.nodes?.some(
      ({
        status,
      }: {
        status: 'scheduled' | 'running' | 'success' | 'failure';
      }) => status === 'scheduled' || status === 'running'
    );

  return (
    <>
      <Section title="Delete Subproject" loading={loading || queryLoading}>
        <Tooltip text="Cannot delete a subproject if an algorithm is running or scheduled.">
          {(tooltipProps) => (
            <Button
              onClick={handleClick}
              aria-label="Delete Subproject"
              disabled={disabled}
              {...tooltipProps}
            >
              Delete Subproject
            </Button>
          )}
        </Tooltip>
      </Section>
      {deleteSubprojectModal.isOpen && (
        <DeleteSubprojectModal
          isVisible={deleteSubprojectModal.isOpen}
          onClose={deleteSubprojectModal.close}
          hasAlgorithmRunsAssigned={hasAlgorithmRunsAssigned}
          hasAnnotationCategoriesAssigned={hasAnnotationCategoriesAssigned}
          hasAnnotationsAssigned={hasAnnotationsAssigned}
          hasSlidesAssigned={hasSlidesAssigned}
          organizationUuid={organizationUuid}
          projectId={projectId}
          subProjectId={subProjectId}
          subprojectName={data.subProject.name}
        />
      )}
    </>
  );
}

export default DeleteSubproject;
