import { useOnboardingPageTitleIndicator } from '@aignostics/onboarding-ui';
import React, { ReactElement } from 'react';
import { Helmet } from 'react-helmet';
import { type AppConfig } from './appConfig.types';

export interface AppHeadProps {
  appConfig: AppConfig;
}

export const AppHead = ({ appConfig }: AppHeadProps): ReactElement => {
  const defaultTitle = [appConfig.name, appConfig.brand].join(' | ');

  return (
    /* Update dynamic page title and favicon */
    <Helmet>
      <title>
        {useOnboardingPageTitleIndicator(defaultTitle) || defaultTitle}
      </title>

      <link rel="shortcut icon" id="favicon" href={appConfig.favicon} />
    </Helmet>
  );
};
