import { useClickOutside } from '@aignostics/hooks';
import React, { useLayoutEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { useDisclosure } from '../../../hooks';
import { $Menu, Container } from './SelectMenu.styles';
const SelectMenu = ({ menuItems, children, maxHeight = 200, }) => {
    const disclosure = useDisclosure(false);
    const triggerRef = useRef(null);
    const menuRef = useRef(null);
    const open = () => {
        disclosure.open();
    };
    const close = () => {
        disclosure.close();
        triggerRef.current?.focus();
    };
    useLayoutEffect(() => {
        if (disclosure.isOpen)
            menuRef.current?.focus();
    }, [disclosure]);
    useClickOutside([menuRef, triggerRef], () => {
        disclosure.close();
    });
    useLayoutEffect(() => {
        const menuElement = menuRef.current;
        const triggerElement = triggerRef.current;
        if (!disclosure.isOpen || menuElement === null || triggerElement === null) {
            return;
        }
        const reposition = () => {
            const triggerBBox = triggerElement.getBoundingClientRect();
            if (menuRef?.current) {
                const rightPosition = triggerBBox.left + menuRef?.current?.clientWidth;
                const leftPosition = rightPosition > window.innerWidth
                    ? innerWidth - menuRef?.current?.clientWidth - 16
                    : triggerBBox.left;
                menuElement.style.top = `${triggerBBox.bottom}px`;
                menuElement.style.left = `${leftPosition}px`;
                menuElement.style.width = 'auto';
                menuElement.style.maxHeight = `${Math.max(triggerBBox.height, maxHeight)}px`;
            }
        };
        reposition();
        document.addEventListener('scroll', reposition, { capture: true });
        return () => {
            document.removeEventListener('scroll', reposition);
        };
    }, [disclosure, maxHeight]);
    return (React.createElement(Container, { onClick: () => {
            if (disclosure.isOpen) {
                close();
            }
            else {
                open();
            }
        }, role: "button", "aria-label": "open select menu", "aria-haspopup": "menu", "aria-expanded": disclosure.isOpen },
        React.createElement("div", { ref: triggerRef }, children),
        createPortal(React.createElement($Menu, { role: "menu", ref: menuRef, tabIndex: -1, style: { display: disclosure.isOpen ? undefined : 'none' } },
            React.createElement(React.Fragment, null, menuItems)), document.getElementById('menu-root') || document.body)));
};
export default SelectMenu;
