import type {
  MultiChannelFormRow,
  SingleChannelFormRow,
} from '../../SetFileMetadataStep/Form/form.state.types';
import { GridData, TreeData } from './MetadataGrid.types';
import { getChannelId } from './MetadataGrid.utils';

export function buildGridTreeData(
  data: (SingleChannelFormRow | MultiChannelFormRow)[]
): TreeData {
  const result: TreeData = [];

  data.forEach((dataItem) => {
    const parentPath = [dataItem.slideFile.filename];

    if (dataItem.type === 'multi-channel') {
      const { parentAssigned, slideFile, channels } = dataItem;
      result.push({
        ...dataItem,
        rowId: slideFile.filename,
        path: parentPath,
      });
      if (channels && channels.length > 0) {
        channels.forEach((channel, index) => {
          if (channel.staining) {
            const id = getChannelId(channel, index);
            result.push({
              rowId: `${parentAssigned.name}_${id}`, // rowId must be unique!
              type: 'channel',
              path: [...parentPath, channel.staining],
              parentName: slideFile.filename,
              selectedOption: channel.originalStaining ?? '',
              staining: channel.staining,
              originalStaining: channel.originalStaining,
              name: channel.name,
              isHeaderRow: false,
              sampleType: null,
              scannerId: null,
              tissue: null,
              disease: null,
              samplePreparation: null,
              morphology: null,
              cancerSite: null,
              caseId: null,
              patientExternalId: null,
              block: null,
              section: null,
            });
          }
        });
      }
    }

    if (dataItem.type === 'single-channel') {
      result.push({
        ...dataItem,
        rowId: dataItem.slideFile.filename,
        path: parentPath,
      });
    }
  });

  return result;
}
export function flattenGridTreeData(treeData: TreeData): GridData[] {
  const flattened: GridData[] = [];

  treeData.forEach((item) => {
    switch (item.type) {
      case 'single-channel': {
        const singleChannelItem = item as SingleChannelFormRow & {
          rowId: string;
          path: string[];
        };
        flattened.push({
          ...singleChannelItem,
          rowId: singleChannelItem.rowId,
          path: singleChannelItem.path,
          type: 'single-channel',
          caseId: singleChannelItem.caseId ?? null,
          block: singleChannelItem.block ?? null,
          patientExternalId: singleChannelItem.patientExternalId ?? null,
        });
        break;
      }
      case 'multi-channel': {
        const multiChannelItem = item as MultiChannelFormRow & {
          rowId: string;
          path: string[];
        };
        flattened.push({
          ...multiChannelItem.parentAssigned,
          rowId: multiChannelItem.rowId,
          path: multiChannelItem.path,
          type: 'multi-channel',
          slideFile: multiChannelItem.slideFile,
          staining: null,
          caseId: multiChannelItem.parentAssigned.caseId ?? null,
          block: multiChannelItem.parentAssigned.block ?? null,
          patientExternalId:
            multiChannelItem.parentAssigned.patientExternalId ?? null,
        });
        break;
      }
      case 'channel': {
        flattened.push({
          name: item.name || '',
          originalStaining: item.originalStaining,
          parentName: item.parentName,
          path: item.path,
          rowId: item.rowId,
          selectedOption: item.selectedOption,
          staining: item.staining,
          sampleType: null,
          type: 'channel',
          isHeaderRow: false,
          scannerId: null,
          tissue: null,
          disease: null,
          samplePreparation: null,
          morphology: null,
          cancerSite: null,
          caseId: null,
          patientExternalId: null,
          block: null,
          section: null,
        });
        break;
      }
      default:
        break;
    }
  });

  return flattened;
}

export function revertFlattenedData(
  gridData: GridData[] | null
): (SingleChannelFormRow | MultiChannelFormRow)[] {
  const multiChannelMap = new Map<string, MultiChannelFormRow>();

  const result: (SingleChannelFormRow | MultiChannelFormRow)[] = [];
  if (!gridData) {
    return result;
  }
  gridData.forEach((item) => {
    switch (item.type) {
      case 'single-channel': {
        if (
          item.slideFile === null ||
          item.caseId === null ||
          item.patientExternalId === null ||
          item.block === null ||
          item.section === null ||
          item.section === undefined ||
          item.slideFile === null ||
          item.slideFile === undefined
        ) {
          return;
        }
        const singleChannelRow: SingleChannelFormRow = {
          ...item,
          type: 'single-channel',
          slideFile: item.slideFile,
          caseId: item.caseId,
          block: item.block,
          section: item.section,
          patientExternalId: item.patientExternalId,
        };
        result.push(singleChannelRow);
        break;
      }
      case 'multi-channel': {
        if (
          item.slideFile === null ||
          item.slideFile === undefined ||
          item.caseId === null ||
          item.patientExternalId === null ||
          item.block === null ||
          item.section === null ||
          item.section === undefined
        ) {
          return;
        }
        const multiChannelRow: MultiChannelFormRow = {
          type: 'multi-channel',
          slideFile: item.slideFile,
          parentAssigned: {
            ...item,
            type: 'temporary-parent',
            caseId: item.caseId,
            block: item.block,
            section: item.section,
            patientExternalId: item.patientExternalId,
            scannerId: item.scannerId,
            tissue: item.tissue,
            disease: item.disease,
            samplePreparation: item.samplePreparation,
            sampleType: item.sampleType,
            morphology: item.morphology,
            cancerSite: item.cancerSite,
            csvLineMapping: item.csvLineMapping,
            id: item.id,
            parentTmaCol: item.parentTmaCol,
            parentTmaRow: item.parentTmaRow,
            parentSlidePosX: item.parentSlidePosX,
            parentSlidePosY: item.parentSlidePosY,
            parentWsiUuid: item.parentWsiUuid,
            caseUuid: item.caseUuid,
            wsiUuid: item.wsiUuid,
            originalWsiUuid: item.originalWsiUuid,
            name: item.slideFile.filename,
          },
          channels: [],
        };
        multiChannelMap.set(item.rowId, multiChannelRow);
        result.push(multiChannelRow);
        break;
      }

      case 'channel': {
        const parentMulti = multiChannelMap.get(item.parentName ?? '');
        if (!parentMulti) {
          return;
        }
        parentMulti.channels.push({
          name: item.name ?? '',
          staining: item.selectedOption ?? null,
          originalStaining: item.originalStaining ?? null,
        });
        break;
      }

      default:
        break;
    }
  });

  return result;
}
