import { Icon, OneLiner } from '@aignostics/components';
import React, { ReactElement } from 'react';
import { useLocation } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { $Crumb, $CrumbIcon, $CrumbLink } from './Crumbs.styles';

export type CrumbProps = {
  title: string;
  url: string;
};

const Crumb = ({ title, url }: CrumbProps): ReactElement => {
  const theme = useTheme();
  const { pathname } = useLocation();
  const isExact = url === pathname;

  const variants = {
    animate: {
      color: isExact ? theme.colors.white : theme.colors.mid,
      backgroundColor: theme.colors.dark,
    },
    hover: {
      cursor: isExact ? 'initial' : 'pointer',
      color: theme.colors.white,
      backgroundColor: isExact ? theme.colors.dark : theme.colors.base,
    },
  };

  const crumbsVariants = {
    initial: { opacity: 0, width: 0, x: -100 },
    animate: {
      opacity: 1,
      width: 'auto',
      x: 0,
    },
    exit: {
      opacity: 0,
      width: 0,
      x: -100,
    },
  };

  return (
    <$Crumb
      variants={crumbsVariants}
      initial="initial"
      animate="animate"
      exit="exit"
      transition={{ duration: 0.5 }}
      style={{ display: 'flex' }}
    >
      {/* Divider between Crumb items */}
      <$CrumbIcon>
        <Icon icon="ChevronsRight" size="input" color="mid" />
      </$CrumbIcon>

      {/* Single navigation crumb item */}
      <$CrumbLink
        to={url}
        tabIndex={isExact ? -1 : 0}
        variants={variants}
        animate="animate"
        whileFocus="hover"
        whileHover="hover"
      >
        <OneLiner>{title}</OneLiner>
      </$CrumbLink>
    </$Crumb>
  );
};

export default Crumb;
