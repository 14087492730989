/**
 * Project Updaters
 */
import { User } from '@aignostics/core';
import {
  ApolloCache,
  gql,
  MutationUpdaterFn,
  MutationUpdaterFunction,
} from '@apollo/client';
import { Project, SubProject } from '../types';

export const LOCAL_UPDATE_PROJECT = gql`
  fragment LocalUpdateProject on Project {
    id
    name
    description
    createdBy
    createdAt
    updatedAt
  }
`;

export const LOCAL_UPDATE_SUB_PROJECT = gql`
  fragment LocalUpdateSubProject on Project {
    updatedAt
    subProjects
  }
`;

export const cacheCreateProject =
  (
    project: Partial<Project>,
    subProject: Partial<SubProject>,
    currentUser: User
  ): MutationUpdaterFn<{
    createProject: { id: string; subProjectId: string };
  }> =>
  (cache, { data }) => {
    const id = data?.createProject.id;
    const subProjectId = data?.createProject.subProjectId;
    const createdAt = new Date().toUTCString();
    const updatedAt = createdAt;

    if (id) {
      cache.modify({
        fields: {
          projects(existingProjectRefs) {
            const newProjectRef = cache.writeFragment({
              id: `Project:${id}`,
              data: {
                ...project,
                id,
                createdBy: { __ref: `User:${currentUser.id}` },
                createdAt,
                updatedAt,
              },
              fragment: LOCAL_UPDATE_PROJECT,
            });

            return {
              ...existingProjectRefs,
              nodes: [...(existingProjectRefs?.nodes ?? []), newProjectRef],
            };
          },
        },
      });
    }
    if (subProjectId) {
      const updatedSubProject: Partial<Omit<SubProject, 'createdBy'>> & {
        createdBy: { __ref: string };
      } = {
        ...subProject,
        id: subProjectId,
        createdBy: { __ref: `User:${currentUser.id}` },
        createdAt,
        updatedAt,
      };

      cache.writeFragment({
        id: `Project:${id}`,
        fragment: LOCAL_UPDATE_SUB_PROJECT,
        data: {
          updatedAt,
          subProjects: { nodes: [updatedSubProject] },
        },
      });
    }
  };

/** Updates local cache after remote sub project create */
export const cacheCreateSubProject =
  (
    subProject: Partial<SubProject>,
    currentUser: User
  ): MutationUpdaterFn<{ createSubProject: string }> =>
  (cache, { data }) => {
    const subProjectId = data?.createSubProject;
    const createdAt = new Date().toUTCString();
    const updatedAt = createdAt;

    if (subProjectId) {
      const newSubProject = cache.writeFragment({
        data: {
          ...subProject,
          id: subProjectId,
          createdBy: { __ref: `User:${currentUser.id}` },
          createdAt,
          updatedAt,
          __typename: 'SubProject',
        },
        fragment: gql`
          fragment newSubproject on SubProject {
            __typename
            id
            name
            description
            showCsv
            createdBy
            createdAt
            updatedAt
          }
        `,
      });

      cache.modify({
        id: `Project:${subProject.projectId}`,
        fields: {
          updatedAt: () => updatedAt,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          subProjects: (prevSubprojects: Record<string, any>) => ({
            ...prevSubprojects,
            nodes: [...prevSubprojects.nodes, newSubProject],
            pageInfo: {
              ...prevSubprojects.pageInfo,
              totalElements: prevSubprojects.pageInfo.totalElements + 1,
            },
          }),
        },
      });
    }
  };

export const cacheUpdateSubProject =
  (
    subProject: Partial<SubProject>
  ): MutationUpdaterFn<{ updateSubProject: string }> =>
  (cache, { data }) => {
    const id = data?.updateSubProject;
    const updatedAt = new Date().toUTCString();

    subProject = {
      ...subProject,
      id,
      updatedAt,
    };

    if (id) {
      cache.writeFragment({
        id: `SubProject:${id}`,
        fragment: gql`
          fragment LocalUpdate on SubProject {
            id
            name
            description
            annotationFeature
            otherAnnotationVisibility
            showCsv
            updatedAt
            createdAt
            annotationDrawingTools
          }
        `,
        data: subProject,
      });
    }
  };

export const cacheDeleteSubProject =
  (
    projectId: string
  ): MutationUpdaterFunction<
    unknown,
    { id: string },
    unknown,
    ApolloCache<unknown>
  > =>
  (cache, _result, { variables }) => {
    const id = variables?.id;

    cache.modify({
      id: `Project:${projectId}`,
      fields: {
        subProjects(existingSubProjectRefs, { readField }) {
          return {
            ...existingSubProjectRefs,
            nodes: existingSubProjectRefs.nodes.filter(
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              (subProjectRef: any) => {
                return id !== readField('id', subProjectRef);
              }
            ),
            pageInfo: {
              ...existingSubProjectRefs.pageInfo,
              totalElements: existingSubProjectRefs.pageInfo.totalElements - 1,
            },
          };
        },
      },
    });
  };
