import { Algorithm, Job, JobWsi } from '@aignostics/algorithms';
import { ApolloError, gql, useQuery } from '@apollo/client';
import { Pagination } from '../types';

type JobWithAlgorithmAndWsis = Job & {
  jobWsis: Pagination<
    JobWsi & {
      wsi: {
        uuid: string;
        name: string;
      };
    }
  >;
  algorithm: Algorithm;
};

export const getJob = gql`
  query GET_JOB(
    $jobId: ID!
    $pageSize: Int!
    $page: Int!
    $wsiName: String
    $status: AlgorithmJobStatus
  ) {
    algorithmJob(id: $jobId) {
      id
      algorithmId
      algorithm {
        name
      }
      triggeredAt
      jobWsis(
        pageSize: $pageSize
        page: $page
        wsiName: $wsiName
        status: $status
      ) {
        nodes {
          wsi {
            uuid
            name
          }
          status
        }
        pageInfo {
          page
          totalPages
          totalElements
        }
      }
    }
  }
`;

export function useJob(
  getJobParams: {
    jobId: string;
    pageSize: number;
    page: number;
    wsiName?: string;
    status: 'scheduled' | 'running' | 'success' | 'failure' | undefined;
  },
  onError?: (error: ApolloError) => void
): [JobWithAlgorithmAndWsis | undefined, { loading: boolean; error: unknown }] {
  const { data, previousData, loading, error } = useQuery<{
    algorithmJob: JobWithAlgorithmAndWsis;
  }>(getJob, { variables: getJobParams, onError });

  const job = data?.algorithmJob;

  const previousJob = previousData?.algorithmJob;

  return [job ?? previousJob, { loading, error }];
}
