import {
  Button,
  HStack,
  prettyFormatBytes,
  Section,
  UploadModal,
  useDisclosure,
  useSnackbarMutations,
} from '@aignostics/components';
import { pluralize } from '@aignostics/utils';
import * as Sentry from '@sentry/react';
import { FORM_ERROR, ValidationErrors, type Mutator } from 'final-form';
import arrayMutators, { Mutators } from 'final-form-arrays';
import setFieldTouched from 'final-form-set-field-touched';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  type ReactElement,
} from 'react';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import { useTheme } from 'styled-components';
import { CSVTemplateDownloadButton } from '../../components/OnboardingBatches/CSVTemplateDownloadButton.component';
import { OnboardingTutorialModal } from '../../components/OnboardingBatches/OnboardingTutorial/OnboardingTutorial.component';
import { Steps } from '../../components/OnboardingBatches/OnboardingTutorial/onboardingTutorial.data';
import {
  getMatchingWsisSubQueries,
  getMatchingWsisTissueCaseIdAndBlocks,
  useMatchingWsis,
} from '../../hooks/useMatchingWsis';
import { useOnboardingMetricsHook } from '../../providers/sentry/OnboardingMetricsProvider';
import type { Association } from '../../types';
import { useSelectedOrganizationUuid } from '../../utils/useSelectedOrganizationUuid';
import { BatchMetadataFieldset } from '../SetFileMetadataStep/BatchMetadataFieldset';
import {
  DEFAULT_CANCER_SITE_VALUE,
  DEFAULT_DISEASE_VALUE,
  DEFAULT_MORPHOLOGY_VALUE,
  DEFAULT_PREPARATION_TYPE_VALUE,
  DEFAULT_SAMPLE_TYPE_VALUE,
} from '../SetFileMetadataStep/const';
import type {
  BatchName,
  SelectData,
} from '../SetFileMetadataStep/Form/form.queries';
import {
  convertStateToRecords,
  convertStateToUpdateWsiRecords,
  generateGroupedOnboardingErrors,
  generateInitialSlides,
  getUniquePatientCaseIdPairs,
  mapSlidesToSlidesWithScannerIds,
} from '../SetFileMetadataStep/Form/form.state';
import type {
  BatchCreateForm,
  BatchCreateFormFilled,
  BatchEditForm,
  MultiChannelFormRow,
  SingleChannelFormRow,
  StainingsMismatchErrors,
} from '../SetFileMetadataStep/Form/form.state.types';
import {
  useCreateOnboardingBatch,
  type CreateOnboardingBatchMutationResult,
  type CreateOnboardingBatchWsiRecord,
} from '../SetFileMetadataStep/hooks/useCreateOnboardingBatch';
import {
  UpdateOnboardingBatchMutationResults,
  useUpdateOnboardingBatchWsis,
} from '../SetFileMetadataStep/hooks/useUpdateOnboardingBatchWsis';
import {
  csvParser,
  MULTIPLE_STAININGS_SEPARATOR,
  type CSVParserInputWRIRow,
} from '../SetFileMetadataStep/parser/parser';
import {
  $Container,
  $FileMetadataFileSetWrapper,
  $Form,
  $HeaderButtonsContainer,
  $SubHeader,
} from '../SetFileMetadataStep/SetFileMetadataStep.styles';
import StainingsMismatchModal from '../SetFileMetadataStep/StainingsMismatchModal/StainingsMismatchModal.component';
import { OnboardingCloudFileWithMetadata } from '../TransferCloudSlides/RequestMetadataStep.component';
import { OnboardingLocalFileWithMetadata } from '../UploadSlides/UploadSlidesSteps.component';
import { MetadataGrid } from './MetadataGrid/MetadataGrid.component';
import { revertFlattenedData } from './MetadataGrid/MetadataGrid.DataFormater';
import { type MetadataGridRefHandle } from './MetadataGrid/MetadataGrid.types';
import { SetFileMetadataStepFooter } from './SetFileMetadataStepFooter';

export type OnboardingFileWithMetadata =
  | OnboardingLocalFileWithMetadata
  | OnboardingCloudFileWithMetadata;

export interface SetFileMetadataStepProps {
  csv: File | undefined;
  onSuccessfulCreate?: (args: {
    createOnboardingBatch: CreateOnboardingBatchMutationResult['createOnboardingBatch'];
    records: CreateOnboardingBatchWsiRecord[];
  }) => void;
  onSuccessfulUpdate?: (args: {
    updateOnboardingBatchWsis: UpdateOnboardingBatchMutationResults['updateOnboardingBatchWsis'];
    batch_id: string;
  }) => void;
  submitLabel: 'submit' | 'transfer' | 'update';
  files: OnboardingFileWithMetadata[];
  onboardingBatch?: BatchEditForm;
  authToken: string | null;
  apiUrl: string;
  userAssignedAssociation: Pick<Association, 'name'> | null;
  onboardingBatchNames: BatchName[];
  selectData: SelectData;
  /** Used for the automated tests. */
  supressAgGridVirtualization?: boolean;
}

export const SNACKBAR_TIMEOUT = 8_000;

export type TotalAndValidSlideCount = {
  total: number;
  totalByteSize: number;
  validFilenames: Set<string>;
  invalidFilenames: Set<string>;
};

export function SetFileMetadataStep({
  csv,
  files,
  onSuccessfulCreate,
  onSuccessfulUpdate,
  submitLabel,
  onboardingBatch,
  authToken,
  apiUrl,
  userAssignedAssociation,
  onboardingBatchNames,
  selectData: {
    associations,
    currentDate,
    species,
    stainings,
    scanners,
    tissues,
    diseases,
    samplePreparations,
    sampleTypes,
    morphologies,
    cancerSites,
  },
  supressAgGridVirtualization = false,
}: SetFileMetadataStepProps): ReactElement | null {
  const { endRecordTransferSlidesInteraction } = useOnboardingMetricsHook();
  const theme = useTheme();
  const uploadCsvModal = useDisclosure();
  const stainingsErrorsModal = useDisclosure();
  const organizationUuid = useSelectedOrganizationUuid();
  const { addSnackbar, clearSnackbar } = useSnackbarMutations();

  const [csvProcessed, setCsvProcessed] = useState(false);
  const [csvIsProcessing, setCsvIsProcessing] = useState(false);
  const [selectedCsvFile, setSelectedCsvFile] = useState(csv ?? null);
  // TODO: Address this in [FE-5235]
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, sonarjs/no-unused-vars, sonarjs/no-dead-store
  const [stainingsMismatchErrors, setStainingsMismatchErrors] =
    useState<StainingsMismatchErrors>([]);
  const [updatedStainingsValues, setUpdatedStainingsValues] =
    useState<StainingsMismatchErrors>([]);
  const [stainingsMismatchModalSkip, setStainingMismatchModalSkip] =
    useState<boolean>(false);
  const gridRef = useRef<MetadataGridRefHandle>(null);
  const [gridReadiness, setGridReadiness] = useState(false);
  const handleGridReady = useCallback(() => {
    setGridReadiness(true);
  }, []);

  const assertGridReadiness: () => MetadataGridRefHandle = useCallback(() => {
    if (gridReadiness) {
      if (!gridRef.current) {
        throw new Error('grid should had been ready');
      }

      return gridRef.current;
    }

    throw new Error('grid is not ready');
  }, [gridReadiness]);
  const [totalAndValidSlideCount, setTotalAndValidSlideCount] =
    useState<TotalAndValidSlideCount | null>(null);

  const onFormReset = () => {
    clearSnackbar();
    setCsvProcessed(false);
  };

  const createOnboardingBatchMutation = useCreateOnboardingBatch({
    onboardingType: submitLabel === 'submit' ? 'local' : 'remote',
  });
  const updateOnboardingBatchWsisMutation = useUpdateOnboardingBatchWsis(
    onboardingBatch?.batch_id
  );

  const showValidationErrors = (errors: ValidationErrors) => {
    clearSnackbar();
    if (errors?.[FORM_ERROR]) {
      addSnackbar({
        type: 'error',
        message: errors[FORM_ERROR],
        closesAfter: SNACKBAR_TIMEOUT,
      });
    }
    for (const message of generateGroupedOnboardingErrors(errors)) {
      addSnackbar({
        type: 'error',
        message,
        closesAfter: SNACKBAR_TIMEOUT,
      });
    }
  };

  const parseCsv = async (
    csvFile: File,
    data: SelectData,
    slides: Array<SingleChannelFormRow | MultiChannelFormRow>
  ) => {
    const {
      tissues,
      stainings,
      scanners,
      diseases,
      samplePreparations,
      sampleTypes,
    } = data;
    const wsis: CSVParserInputWRIRow[] = [];
    for (const slide of slides.values()) {
      const slideFile =
        slide.type === 'single-channel' ? slide : slide.parentAssigned;
      wsis.push({
        type: slide.type,
        Filename: slide.slideFile.filename,
        Localization: slideFile.tissue,
        Staining:
          slide.type === 'single-channel'
            ? slide.staining
            : slide.channels
                .map((channel) => channel.staining)
                .join(MULTIPLE_STAININGS_SEPARATOR),
        scannerId: slideFile.scannerId,
        'Case ID': slideFile.caseId,
        'Patient ID': slideFile.patientExternalId,
        Block: slideFile.block,
        Section: slideFile.section,
        Disease: slideFile.disease ?? DEFAULT_DISEASE_VALUE,
        'Preparation Type':
          slideFile.samplePreparation ?? DEFAULT_PREPARATION_TYPE_VALUE,
        'Sample Type': slideFile.sampleType ?? DEFAULT_SAMPLE_TYPE_VALUE,
        Morphology: slideFile.morphology ?? DEFAULT_MORPHOLOGY_VALUE,
        'Cancer Site': slideFile.cancerSite ?? DEFAULT_CANCER_SITE_VALUE,
        parent_tma_row: slideFile.parentTmaRow,
        parent_tma_col: slideFile.parentTmaCol,
        parent_slide_pos_x: slideFile.parentSlidePosX,
        parent_slide_pos_y: slideFile.parentSlidePosY,
        parent_wsi_uuid: slideFile.parentWsiUuid,
        wsi_uuid: slideFile.wsiUuid,
        case_uuid: slideFile.caseUuid,
      });
    }
    const result = await csvParser(
      csvFile,
      wsis,
      tissues,
      stainings,
      scanners,
      diseases,
      samplePreparations,
      sampleTypes,
      morphologies,
      cancerSites
    );
    clearSnackbar();
    if (result.ok) {
      const grid = assertGridReadiness();
      grid.processCsvEntries(result);
      result.warnings.forEach((warning) =>
        addSnackbar({
          type: 'warning',
          message: warning,
          closesAfter: SNACKBAR_TIMEOUT,
        })
      );
    } else {
      addSnackbar({
        type: 'error',
        message: result.error,
        closesAfter: SNACKBAR_TIMEOUT,
      });
    }
  };

  useEffect(() => {
    if (
      stainingsMismatchErrors.length &&
      !stainingsMismatchModalSkip &&
      !csvIsProcessing
    ) {
      stainingsErrorsModal.open();
    }
  }, [
    stainingsErrorsModal,
    stainingsMismatchErrors,
    stainingsMismatchModalSkip,
    csvIsProcessing,
  ]);

  useEffect(() => {
    endRecordTransferSlidesInteraction();
  }, [endRecordTransferSlidesInteraction]);

  const initialValues: BatchCreateForm = useMemo(
    () =>
      onboardingBatch
        ? {
            ...onboardingBatch,
            areFieldsSyncing: false,
            slides: mapSlidesToSlidesWithScannerIds(
              onboardingBatch.slides,
              scanners
            ),
          }
        : {
            batch_name: '',
            association: null,
            species: null,
            areFieldsSyncing: false,
            slides: generateInitialSlides(stainings, files),
          },
    [onboardingBatch, files, scanners, stainings]
  );

  const {
    handleCompletedRefetchMatchingWsisGroups,
    refetchMatchingWsis,
    refetchMatchingWsisLoadingStatus,
  } = useMatchingWsis();

  return (
    <>
      <FinalForm<BatchCreateForm, BatchCreateForm>
        mutators={{
          ...(arrayMutators as unknown as Record<
            keyof Mutators,
            Mutator<BatchCreateForm, BatchCreateForm>
          >),
          ...{
            setFieldTouched: setFieldTouched as Mutator<
              BatchCreateForm,
              BatchCreateForm
            >,
          },
        }}
        onSubmit={async (values) => {
          const grid = assertGridReadiness();
          const invalidFilenames = Array.from(
            totalAndValidSlideCount!.invalidFilenames
          );
          if (invalidFilenames.length) {
            addSnackbar({
              type: 'error',
              message: `Slide ${pluralize('file', invalidFilenames.length)} ${invalidFilenames.join(', ')} has incorrect values`,
            });
            return;
          }

          if (refetchMatchingWsisLoadingStatus) {
            addSnackbar({
              type: 'error',
              message: 'Validation is in progress, can not submit',
              closesAfter: 3000,
            });
            return;
          }
          const slides = grid.getValues();

          const formatSlides = revertFlattenedData(slides);
          const resultValues = {
            ...values,
            slides: formatSlides,
          } as BatchCreateFormFilled;

          try {
            const batch_id = onboardingBatch?.batch_id;
            if (
              batch_id &&
              updateOnboardingBatchWsisMutation !== null &&
              onSuccessfulUpdate
            ) {
              const { updateOnboardingBatchWsis } =
                await updateOnboardingBatchWsisMutation({
                  batch_id,
                  wsis: convertStateToUpdateWsiRecords(resultValues.slides),
                  association: onboardingBatch.association,
                });
              clearSnackbar();
              onSuccessfulUpdate({
                updateOnboardingBatchWsis,
                batch_id,
              });
            } else if (onSuccessfulCreate) {
              const records = convertStateToRecords(files, resultValues.slides);
              const { createOnboardingBatch } =
                await createOnboardingBatchMutation({
                  batch_name: resultValues.batch_name,
                  species: resultValues.species,
                  association:
                    userAssignedAssociation !== null
                      ? userAssignedAssociation.name
                      : resultValues.association,
                  wsis: records,
                });
              clearSnackbar();
              onSuccessfulCreate({
                createOnboardingBatch,
                records,
              });
            }

            return;
          } catch (error) {
            Sentry.captureException(error, {
              extra: {
                values: resultValues,
              },
            });
            clearSnackbar();
            addSnackbar({
              type: 'error',
              message: String(error),
              closesAfter: SNACKBAR_TIMEOUT,
            });
          }
        }}
        initialValues={initialValues}
        subscription={{ submitting: true }}
        render={({ form, handleSubmit, submitting }) => (
          <>
            <Section background="white" loading={submitting}>
              <HStack justifyContent="space-between">
                <span
                  aria-label="Form Metadata header text"
                  style={{
                    ...theme.fontStyles.displayBold,
                  }}
                >
                  {totalAndValidSlideCount
                    ? `${totalAndValidSlideCount.total} ${pluralize('slide', totalAndValidSlideCount.total)} - Total size: ${prettyFormatBytes(totalAndValidSlideCount.totalByteSize)}`
                    : '0 slides'}
                </span>
                <$HeaderButtonsContainer>
                  <CSVTemplateDownloadButton />
                  {!selectedCsvFile ? (
                    <Button onClick={uploadCsvModal.open} small>
                      Add CSV File
                    </Button>
                  ) : (
                    <Button
                      onClick={() => {
                        setSelectedCsvFile(null);
                        onFormReset();
                        const grid = assertGridReadiness();
                        grid.reset();
                      }}
                      small
                    >{`Reset form & remove "${selectedCsvFile.name}"`}</Button>
                  )}
                </$HeaderButtonsContainer>
              </HStack>
            </Section>
            <Section
              aria-label="SetFileMetadataStep Section"
              innerDontConstrainWidth
              style={{ paddingBottom: 0, paddingLeft: 0, paddingRight: 0 }}
              innerStyle={{
                flexGrow: 1,
              }}
            >
              <$Form onSubmit={handleSubmit} noValidate>
                {/*
                 * without this button first Button click in the form will be triggered
                 * which is currently ToggleExtraFields
                 * see: https://stackoverflow.com/a/51507806
                 */}
                <button type="submit" disabled style={{ display: 'none' }} />
                <$Container>
                  <div style={{ width: theme.breakpoints.FULL }}>
                    <$SubHeader>
                      <span>*Mandatory data</span>
                    </$SubHeader>
                    <FormSpy
                      subscription={{ values: true }}
                      render={({ values, form }) => (
                        <BatchMetadataFieldset
                          isDisabled={Boolean(onboardingBatch)}
                          availableOnboardingBatchNames={onboardingBatchNames}
                          availableAssociations={associations}
                          userAssignedAssociation={userAssignedAssociation}
                          availableSpecies={species}
                          formSlides={values.slides}
                          formBatchName={values.batchName}
                          formChange={form.change}
                          initialState={initialValues}
                        />
                      )}
                    />
                  </div>
                  <$FileMetadataFileSetWrapper>
                    <MetadataGrid
                      ref={gridRef}
                      stainings={stainings}
                      scanners={scanners}
                      tissues={tissues}
                      diseases={diseases}
                      preparationTypes={samplePreparations}
                      sampleTypes={sampleTypes}
                      morphologies={morphologies}
                      cancerSites={cancerSites}
                      initialValues={
                        initialValues.slides as SingleChannelFormRow[]
                      }
                      apiUrl={apiUrl}
                      authToken={authToken}
                      organizationUuid={organizationUuid}
                      updateValidCount={setTotalAndValidSlideCount}
                      onGridReady={handleGridReady}
                      supressAgGridVirtualization={supressAgGridVirtualization}
                    />
                  </$FileMetadataFileSetWrapper>
                </$Container>
                <SetFileMetadataStepFooter
                  onReset={() => {
                    const grid = assertGridReadiness();
                    onFormReset();
                    grid.reset();
                  }}
                  onSubmit={showValidationErrors}
                  totalAndValidSlideCount={totalAndValidSlideCount}
                />
                <FormSpy
                  subscription={{ values: true }}
                  onChange={({ values: { slides, association } }) => {
                    const associationParam =
                      association ?? userAssignedAssociation?.name;
                    const matchingWsisTissueCaseIdAndBlocks =
                      getMatchingWsisTissueCaseIdAndBlocks(slides);

                    if (
                      (getUniquePatientCaseIdPairs(slides).length !== 0 ||
                        matchingWsisTissueCaseIdAndBlocks.length !== 0) &&
                      Boolean(associationParam)
                    ) {
                      void refetchMatchingWsis({
                        variables: {
                          association: associationParam,
                          subqueries: getMatchingWsisSubQueries(
                            slides,
                            associationParam
                          ),
                          blockCaseIdTissuesTriples:
                            getMatchingWsisTissueCaseIdAndBlocks(slides),
                        },
                        onCompleted(data) {
                          handleCompletedRefetchMatchingWsisGroups(
                            slides,
                            data
                          );
                        },
                      });
                    }
                  }}
                  render={() => null} // need this line just for avoiding error messages in unit tests
                />
                <FormSpy
                  subscription={{ values: true }}
                  render={() => {
                    /**
                       1. Using render instead of onChange because it requires access to the FormAPI.
                       2. This is triggered not only by changes in values but also by changes in selectData, selectedCsvFile,
                       and even when the uploadCsvModal opens or closes.
                       Thus, for this component, it functions similarly to useEffect, but also provides access to the FormAPI and the current values of slides.
                     */
                    if (gridReadiness && selectedCsvFile && !csvProcessed) {
                      const grid = assertGridReadiness();
                      const slides = grid.getValues();
                      if (!slides) {
                        return;
                      }
                      const revertedSlides = revertFlattenedData(slides);
                      setCsvIsProcessing(true);
                      void parseCsv(
                        selectedCsvFile,
                        {
                          associations,
                          currentDate,
                          species,
                          stainings,
                          scanners,
                          tissues,
                          diseases,
                          samplePreparations,
                          sampleTypes,
                          morphologies,
                          cancerSites,
                        },
                        revertedSlides
                      );
                      setCsvIsProcessing(false);
                      setCsvProcessed(true);
                    }
                    return null;
                  }}
                />
                <StainingsMismatchModal
                  isOpen={stainingsErrorsModal.isOpen}
                  onCloseModal={() => {
                    setStainingMismatchModalSkip(true);
                    stainingsErrorsModal.close();
                  }}
                  stainingsMismatchErrors={stainingsMismatchErrors}
                  updatedStainingsValues={updatedStainingsValues}
                  stainings={stainings}
                  changeField={form.change}
                  setFieldTouched={form.mutators.setFieldTouched}
                  setUpdatedStainingsValues={setUpdatedStainingsValues}
                />
              </$Form>
            </Section>
          </>
        )}
      />
      <UploadModal
        isVisible={uploadCsvModal.isOpen}
        accept=".csv"
        acceptMultipleFiles={false}
        onClose={uploadCsvModal.close}
        onDropAccepted={([uploadedCsv]) => {
          setSelectedCsvFile(uploadedCsv);
          uploadCsvModal.close();
          setStainingMismatchModalSkip(false);
        }}
        title="Add your CSV document"
      />
      <OnboardingTutorialModal initialStep={Steps.METADATA} />
    </>
  );
}
