import { useNavigate } from 'react-router-dom';

export const useRedirectToNextRoute = (): (() => void) => {
  const navigate = useNavigate();

  const redirectToNextRoute = () => {
    const params = new URLSearchParams(window.location.search);
    const returnUrl = params.get('returnUrl');
    void navigate(returnUrl || '/', { replace: true });
  };

  return redirectToNextRoute;
};
