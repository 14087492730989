import {
  AGGridTable,
  Button,
  HStack,
  InfoModal,
  Link,
  PageLayout,
  Section,
  VisibleWithScope,
  useDisclosure,
} from '@aignostics/components';
import { OrganizationRole } from '@aignostics/core';
import { ApolloClient, DocumentNode, useApolloClient } from '@apollo/client';
import {
  ColDef,
  IServerSideDatasource,
  SetFilterModel,
  TextFilterModel,
} from 'ag-grid-enterprise';
import { CustomCellRendererProps } from 'ag-grid-react';
import React, { ReactElement, useMemo } from 'react';
import styled, { useTheme } from 'styled-components';
import { capitalize } from '../../utils/capitalize';
import { CreateUserForm } from './Create/CreateUserForm.component';
import { useCreateUser } from './Create/useCreateUser';
import { default as FETCH_USERS_LIST } from './FETCH_USERS_LIST';

type ColumnFilterModel = TextFilterModel | SetFilterModel;
type FilterModel = Record<string, ColumnFilterModel>;

type UserData = {
  id: string;
  name: string;
  email: string;
  role: { roleName: string; isDisabled: boolean };
};

const createServerSideDatasource = (
  client: ApolloClient<object>,
  queryDocument: DocumentNode
): IServerSideDatasource<UserData> => {
  return {
    getRows: async (params) => {
      try {
        const {
          startRow: maybeStartRow,
          endRow: maybeEndRow,
          sortModel,
          filterModel,
        } = params.request;

        const startRow = maybeStartRow ?? 0;
        const endRow = maybeEndRow ?? startRow;

        const computedPageSize = endRow - startRow;

        if (computedPageSize <= 0) {
          throw new Error(
            `Invalid computed page size: startRow=${startRow}, endRow=${endRow}`
          );
        }
        const pageSize: number = computedPageSize;
        const page: number = Math.floor(startRow / pageSize) + 1;
        const fm = filterModel as FilterModel;

        const extractTextFilter = (model?: ColumnFilterModel): string => {
          return model?.filterType === 'text' ? model.filter ?? '' : '';
        };
        const extractRoleFilter = (model?: ColumnFilterModel): string[] => {
          if (
            model?.filterType === 'set' &&
            model.values &&
            model.values.length > 0
          ) {
            return model.values as string[];
          }
          return ['all'];
        };

        const extractStatusFilter = (model?: ColumnFilterModel): string => {
          if (
            model?.filterType === 'set' &&
            model.values &&
            model.values.length > 0
          ) {
            return model.values[0]!.toLowerCase() || '';
          }
          return '';
        };
        const searchName = extractTextFilter(fm['name']);
        const searchEmail = extractTextFilter(fm['email']);
        const roleNames = extractRoleFilter(fm['role']);
        const isDisabled = extractStatusFilter(fm['status']);

        const variables = {
          page,
          pageSize,
          searchName,
          searchEmail,
          roleNames,
          isDisabled,
          sortBy: sortModel?.[0]?.colId || 'name',
          sortDirection: sortModel?.[0]?.sort || 'asc',
        };

        const { data } = await client.query({
          query: queryDocument,
          variables,
          fetchPolicy: 'network-only',
        });

        const rowData = data?.users?.nodes ?? [];
        const totalElements =
          data?.users?.pageInfo.totalElements ?? rowData.length;

        params.success({ rowData, rowCount: totalElements });
      } catch {
        params.fail();
      }
    },
  };
};

const $CreateUserModalWrapper = styled.div`
  padding: 32px;
`;

const UserNameCell = (params: CustomCellRendererProps<UserData, string>) => {
  const theme = useTheme();
  const displayName = params.value;
  if (!params.data) return null;
  return (
    <Link
      href={params.data.id}
      style={{
        color: params.value ? theme.colors.primaryLight : theme.colors.linkBlue,
        paddingRight: '16px',
      }}
    >
      {displayName}
    </Link>
  );
};

const AdminUserList = ({
  userRole,
  organizationUuid,
}: {
  userRole: OrganizationRole;
  organizationUuid: string;
}): ReactElement => {
  const createUserDialog = useDisclosure();
  const { createUser, loading: createUserLoading } = useCreateUser({
    organizationUuid,
  });

  const client = useApolloClient();
  const datasource = useMemo(
    () => createServerSideDatasource(client, FETCH_USERS_LIST),
    [client]
  );
  const columnDefs = useMemo(() => {
    return [
      {
        headerName: 'Name',
        field: 'name',
        sortable: true,
        filter: 'agTextColumnFilter',
        filterParams: {
          filterOptions: ['contains'],
          maxNumConditions: 1,
        },
        tooltipField: 'name',
        cellRenderer: UserNameCell,
      },
      {
        headerName: 'Email',
        field: 'email',
        sortable: true,
        filter: 'agTextColumnFilter',
        filterParams: {
          filterOptions: ['contains'],
          maxNumConditions: 1,
        },
        tooltipField: 'email',
      },
      {
        headerName: 'Role',
        colId: 'role',
        sortable: true,
        filter: 'agSetColumnFilter',
        valueGetter: (params) => capitalize(params.data?.role.roleName || ''),
        tooltipValueGetter: (params) =>
          capitalize(params.data?.role.roleName || ''),
        filterParams: {
          values: ['Admin', 'Developer', 'Editor', 'Viewer'],
        },
      },
      {
        headerName: 'Status',
        colId: 'status',
        sortable: true,
        filter: 'agSetColumnFilter',
        valueGetter: (params) =>
          params.data?.role.isDisabled ? 'Disabled' : 'Enabled',
        filterParams: {
          values: ['Disabled', 'Enabled'],
        },
      },
    ] satisfies ColDef<UserData>[];
  }, []);

  return (
    <>
      <PageLayout
        title={'Users'}
        currentOrganization={userRole.organization.name}
      >
        <Section>
          <HStack justifyContent="flex-end">
            <VisibleWithScope role={userRole} scope="user:create">
              <Button
                small
                icon="PlusCircle"
                onClick={() => {
                  createUserDialog.open();
                }}
              >
                Create User
              </Button>
            </VisibleWithScope>
          </HStack>
          <div style={{ height: '100%' }}>
            <AGGridTable
              columnDefs={columnDefs}
              serverSideDatasource={datasource}
              suppressCsvExport
            />
          </div>
        </Section>
        <InfoModal
          title="Create new user"
          isVisible={createUserDialog.isOpen}
          onClose={createUserDialog.close}
        >
          <$CreateUserModalWrapper>
            <CreateUserForm
              onSubmit={createUser}
              isSubmitting={createUserLoading}
              currentUserRole={userRole}
            />
          </$CreateUserModalWrapper>
        </InfoModal>
      </PageLayout>
    </>
  );
};

export default AdminUserList;
