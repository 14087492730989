import { isEqual } from 'lodash';
import React, { useMemo } from 'react';
import { SortableTableHeader } from './Table.HeaderCell.component';
import TableRowComponent from './Table.Row.component';
import { HeaderCell, HeaderRow, Table } from './TableComponent.styles';
import DynamicColumnsMenu from './components/DynamicColumnsMenu/DynamicColumnsMenu.component';
import SelectAllCheckbox from './components/SelectAllCheckbox/SelectAllCheckbox';
/**
 * TableComponent component that can be used within Table component
 * @type  RData                   Type of the input data
 * @param data                                     Array of data to be displayed
 * @param columns                                  Array of columns and params of each column
 * @param columns.id                               unique identifier for the column, will be used to identify column in sorting and will be used to generate keys
 * @param columns.label                            column label will be displayed in the header
 * @param columns.copyToClipboard                  callback to copy to clipboard data from cell/column
 * @param columns.renderCell                       callback to render data in cell
 * @param columns.disableSorting                   disable column sorting
 * @param columns.align                            align cell / header content left | center | right
 * @param columns.maxWidth                         max width for the cell
 * @param columns.minWidth                         min width for the cell
 * @param columns.withSubRows                      boolean define if the column can have subrows
 * @param columns.renderSubRows                    callback function to render subrows for column should return and array of rows
 *
 * @param columns.subRowsConfig                    Object config to render subRows
 * @param columns.subRowsConfig.data               callback to get data for subRow
 *
 * @param columns.subRowsConfig.columns            array of objects rows
 * @param columns.subRowsConfig.columns.id         unique identifier for the column
 * @param columns.subRowsConfig.columns.renderCell callback to render data in cell
 * @param columns.subRowsConfig.columns.align      align cell / header content left | center | right
 *
 * @param rowSelector                              key of RData, used to identify row in selection, and used to generate keys
 * @param sorting                                  Sorting state column and direction
 * @param setSorting                               callback function when sorting is applied
 * @param selection                                Array of selected rows
 * @param setSelection                             callback for selecting provide row data and selection value
 * @param onSelectMultiple                         callback for select all feature can enable all rows in all pages, current page, or disable everything
 * @param isAllSelected                            boolean should to check whether all rows are selected
 * @returns                                        Table Header with sorting indicator and Icon if needed
 */
const TableComponent = ({ data, columns, rowSelector, sorting, setSorting, dynamicColumns = true, setColumnsState, ...props }) => {
    const renderSelectAllRow = useMemo(() => {
        if (props.enableSelection) {
            const { selection, onSelectMultiple, isAllSelected } = props;
            const isSelected = isEqual(props.selection.slice().sort((a, b) => a.localeCompare(b)), 
            // eslint-disable-next-line @typescript-eslint/require-array-sort-compare, sonarjs/no-alphabetical-sort
            data.map((row) => row[rowSelector]).sort());
            return (React.createElement(HeaderCell, { enableSelection: props.enableSelection, key: 'select', "aria-label": 'select all checkbox' },
                React.createElement(SelectAllCheckbox, { checked: isSelected, partialCheck: selection.length > 0 && !isSelected, onSelectChange: onSelectMultiple, isAllSelected: isAllSelected })));
        }
        return null;
    }, [data, props, rowSelector]);
    return (React.createElement(Table, { enableSelection: props.enableSelection },
        React.createElement("thead", null,
            React.createElement(HeaderRow, null,
                renderSelectAllRow,
                columns.map((header) => {
                    if (header.checked) {
                        return (React.createElement(SortableTableHeader, { key: `header_${header.id}`, enableSelection: props.enableSelection, header: header, sortDirection: sorting?.column === header.id ? sorting.direction : 'none', onSorting: (params) => {
                                if (params) {
                                    setSorting({ ...params });
                                }
                                else if (sorting?.column === header.id) {
                                    setSorting(null);
                                }
                            } }));
                    }
                    return null;
                }),
                dynamicColumns && (React.createElement(DynamicColumnsMenu, { columns: columns, setColumnsState: setColumnsState })))),
        React.createElement("tbody", null, data.map((row) => {
            const selector = row[rowSelector];
            return (React.createElement(TableRowComponent, { key: `row_${selector}`, enableSelection: props.enableSelection, row: row, headers: columns, onSelect: (value) => {
                    if (props.enableSelection) {
                        props.setSelection(row, value);
                    }
                }, isSelected: props.enableSelection &&
                    props.selection.includes(row[rowSelector]), rowSelector: rowSelector, dynamicColumns: dynamicColumns }));
        }))));
};
export default TableComponent;
