import { useToggle } from '@aignostics/hooks';
import { AnimatePresence } from 'framer-motion';
import React, { useState } from 'react';
import { useTheme } from 'styled-components';
import Button from '../Button';
import { DateRangePicker } from '../DateRangePicker';
import { Grid } from '../Grid';
import { Input } from '../Input';
import { SelectMultiple, SelectSingle } from '../Select';
import { VStack } from '../Stack';
import { Textarea } from '../Textarea';
import { ToggleButtonGroup } from '../ToggleButtonGroup';
import { isTouchDevice } from '../utils/isTouchDevice';
import { $Filter, $FilterContentFixedWith, $FilterContentWrapper, $FilterControls, $FilterHeader, $FilterSearch, } from './Filter.styles';
/**
 * A generic filter component to be used on various pages.
 */
function Filter({ title = 'Filter', fields, onChange, isOpen = true, value, onReset, isDefault, disabled = false, onOpen, showHeader = true, }) {
    const [isOpenInternal, toggleIsOpen] = useToggle(!isDefault || isOpen);
    const theme = useTheme();
    const fixedWidthFields = [];
    const fluidWidthFields = [];
    let searchField = undefined;
    Object.entries(fields).forEach((entry) => {
        const [id, field] = entry;
        const handleFieldChange = (fieldValue) => {
            onChange({ ...value, [id]: fieldValue });
        };
        switch (field.type) {
            case 'text':
                fluidWidthFields.push(React.createElement(Input, { key: id, id: id, label: field.label, value: value[id], onChange: handleFieldChange, disabled: disabled }));
                break;
            case 'search':
                searchField = (React.createElement(Input, { style: { width: theme.spacings.tile }, key: id, icon: field.icon, innerIcon: "Search", id: id, type: field.type, value: String(value[id]), onChange: handleFieldChange, disabled: field.disabled, placeholder: field.placeholder, required: field.isRequired, copy: field.copy, name: "searchField", "aria-label": field.label, autoFocus: !isTouchDevice(), "data-autofocus": !isTouchDevice() }));
                break;
            case 'daterange':
                fluidWidthFields.push(React.createElement(DateRangePicker, { id: id, key: id, value: value[id], label: field.label, icon: field.icon, onChange: handleFieldChange, dateRange: field.dateRange }));
                break;
            case 'textarea':
                fluidWidthFields.push(React.createElement(Textarea, { key: id, icon: field.icon, id: id, label: field.label, value: String(value[id]), onChange: handleFieldChange, disabled: field.disabled, placeholder: field.placeholder, required: field.isRequired, minRows: 5 }));
                break;
            case 'select':
                fluidWidthFields.push(React.createElement(SelectSingle, { key: id, icon: field.icon, label: field.label, id: id, value: String(value[id]), options: 'options' in field ? field.options : field.getOptions(value), onChange: (value) => {
                        handleFieldChange(value.value);
                    }, disabled: field.disabled }));
                break;
            case 'multiselect':
                fluidWidthFields.push(React.createElement(SelectMultiple, { disabled: field.disabled, icon: field.icon, id: id, key: id, label: field.label, onChange: (value) => {
                        handleFieldChange(value.map(({ value }) => value));
                    }, options: 'options' in field ? field.options : field.getOptions(value), value: value[id], placeholder: field.placeholder }));
                break;
            case 'radio':
                fixedWidthFields.push(React.createElement(ToggleButtonGroup, { key: id, icon: field.icon, label: field.label, id: id, value: String(value[id]), name: id, options: 'options' in field ? field.options : field.getOptions(value), onChange: handleFieldChange, isRequired: field.isRequired }));
                break;
            case 'checkbox':
            case 'color':
            default:
                break;
        }
    });
    const isFiltersOpen = onOpen ? isOpen : isOpenInternal;
    const [isFilterContentAnimating, setFilterContentAnimating] = useState(false);
    return (React.createElement($Filter, { "data-testid": "filter" },
        React.createElement(VStack, { style: { gap: '16px' } },
            showHeader && (React.createElement($FilterHeader, null,
                React.createElement($FilterControls, null,
                    !disabled &&
                        (fixedWidthFields.length > 0 ||
                            fluidWidthFields.length > 0 ||
                            onOpen) && (React.createElement(Button, { small: true, icon: isFiltersOpen ? 'ChevronUp' : 'Union', onClick: onOpen || toggleIsOpen, variant: "white", disabled: disabled, "aria-expanded": isFiltersOpen }, title)),
                    !isDefault && (React.createElement(Button, { small: true, onClick: onReset, icon: "XCircle", variant: "black" }, "Clear filters"))),
                React.createElement($FilterSearch, null, searchField))),
            React.createElement(AnimatePresence, { initial: false }, isFiltersOpen && (React.createElement($FilterContentWrapper, { initial: { height: 0 }, animate: { height: '100%' }, exit: { height: 0, overflow: 'hidden' }, isFilterContentAnimating: isFilterContentAnimating, onAnimationStart: () => {
                    setFilterContentAnimating(true);
                }, onAnimationComplete: () => {
                    setFilterContentAnimating(false);
                } },
                fluidWidthFields.length > 0 && React.createElement(Grid, null, fluidWidthFields),
                fixedWidthFields.length > 0 && (React.createElement($FilterContentFixedWith, null, fixedWidthFields))))))));
}
export default Filter;
