import { ErrorPageBase } from '@aignostics/components';
import React, { ReactElement, ReactNode, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { validate as validateUUID } from 'uuid';

import { useAuthUser } from '../../App/AuthProvider';

export function SelectedOrganizationGuard({
  children,
}: {
  children: ReactNode;
}): ReactElement | null {
  const user = useAuthUser();
  const navigate = useNavigate();
  const location = useLocation();
  const { organizationUuid } = useParams();
  const defaultOrganizationUuid = user.roles[0].organization.uuid;
  if (!organizationUuid) {
    throw new Error(
      'Invariant violation. This component must have a :organizationUuid URL parameter'
    );
  }

  useEffect(() => {
    // Old URLs will not have valid UUIDs as the first part of the URL
    if (!validateUUID(organizationUuid)) {
      void navigate(
        `/${defaultOrganizationUuid}${location.pathname}${location.search}${location.hash}`
      );
    }
  }, [defaultOrganizationUuid, location, navigate, organizationUuid]);

  const selectedOrganization = user.roles.find(
    ({ organization: { uuid } }) => uuid === organizationUuid
  )?.organization;
  if (!selectedOrganization) {
    return (
      <ErrorPageBase
        title="You don't have access to this organization"
        subtitle=""
      />
    );
  }

  return <>{children}</>;
}
