import {
  flexRender,
  getCoreRowModel,
  TableOptions,
  useReactTable,
} from '@tanstack/react-table';
import React from 'react';
import {
  $BlockTable,
  $TableBodyRow,
  $TableHeader,
  $TableHeaderRow,
} from './BlockTable.styles';

const defaultTableOptions = { getCoreRowModel: getCoreRowModel() };

function BlockTable<T extends object>({
  caption,
  ...tableOptions
}: Omit<TableOptions<T>, 'getCoreRowModel'> & {
  caption?: string;
}): JSX.Element {
  const { getHeaderGroups, getRowModel } = useReactTable({
    ...defaultTableOptions,
    ...tableOptions,
  });

  return (
    <$BlockTable>
      {caption && <caption>{caption}</caption>}

      {/* Table Header */}
      <$TableHeader className="TableRow">
        {getHeaderGroups().map((headerGroup, index) => {
          return (
            <$TableHeaderRow key={index} role="columnheader">
              {headerGroup.headers.map((header) => {
                return (
                  <div
                    key={header.id}
                    id={'headerColumn'}
                    style={{ width: `${header.getSize()}px` }}
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                  </div>
                );
              })}
            </$TableHeaderRow>
          );
        })}
      </$TableHeader>

      {/* Table Body */}
      <div
        style={{
          height: '350px',
          overflowY: 'auto',
          width: 'fit-content',
        }}
      >
        {getRowModel().rows.map((row) => {
          return (
            <$TableBodyRow key={row.id}>
              {row.getVisibleCells().map((cell) => {
                return (
                  // eslint-disable-next-line react/jsx-key
                  <div
                    className="td"
                    style={{ width: `${cell.getContext().column.getSize()}px` }}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </div>
                );
              })}
            </$TableBodyRow>
          );
        })}
      </div>
    </$BlockTable>
  );
}

export default BlockTable;
