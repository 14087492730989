import { ResetStyle } from '@aignostics/theme';
import '@radix-ui/themes/styles.css';
import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './components/App';
import './fonts.css';
import { unregisterServiceWorker } from './unregisterServiceWorker';

declare global {
  interface Window {
    /**
     * This prop might be set by cypress e2e test runner on the window object,
     * used to disable request to 3rd party resources, such as tracking,
     * error reporting, etc.
     **/
    NO_THIRDPARTY_RESOURCES?: true;
  }
}

const appRoot = createRoot(getRootNode());

/** Initial Bootstrap Function */
async function bootstrap() {
  await unregisterServiceWorker();

  appRoot.render(
    <>
      <ResetStyle />
      <App />
    </>
  );
}

void bootstrap();

function getRootNode(): HTMLElement {
  let rootNode = document.getElementById('root');

  if (rootNode) {
    rootNode.innerHTML = '';
  } else {
    rootNode = document.createElement('div');
    rootNode.id = 'root';
    document.body.appendChild(rootNode);
  }

  return rootNode;
}
