"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SCOPES = void 0;
exports.SCOPES = [
    'admin:access',
    'algorithm_job:view',
    'algorithm_job:create',
    'analytics:access',
    'annotation:readAll',
    'interactiveOverlay:deleteDev',
    'interactiveOverlay:deleteProd',
    `project:readAll`,
    `project:create`,
    `project:delete`,
    `project:manage`,
    'project:edit',
    'server:debug',
    'subproject:create',
    'subproject:delete',
    'subproject:edit',
    'subproject:readAll',
    'subproject:manage',
    'user:create',
    'user:delete',
    'user:edit',
    'user:impersonate',
    'user:readAll',
    'user:toggleAdmin',
    'wsi:edit',
    'wsi:readAll',
    'wsi:upload',
    'preset:create',
    'preset:readAll',
    'preset:editAll',
    'preset:edit',
    'preset:delete',
    'preset:deleteAll',
];
