import { ErrorPageBase } from '@aignostics/components';
import React, { ReactElement, ReactNode } from 'react';
import useUserRole from '../../../../hooks/useUserRole';

export interface RequireAuthenticationGuardProps {
  children: ReactNode;
}

export function AnnotationManagementGuard({
  children,
}: RequireAuthenticationGuardProps): ReactElement {
  const user = useUserRole();

  if (user.scopes['admin:access']) {
    return <>{children}</>;
  } else {
    return (
      <ErrorPageBase
        title="Cannot access"
        subtitle="Sorry, you don't have access to this page"
      />
    );
  }
}
