import {
  Button,
  Checkbox,
  HStack,
  Input,
  Textarea,
  VStack,
} from '@aignostics/components';
import React, { useState } from 'react';
import styled from 'styled-components';

const $FormSectionLegend = styled.span`
  ${({ theme }) => theme.fontStyles.smallBold}
`;

export interface CreateSubprojectFormValues {
  name: string;
  description: string;
  showCsv: boolean;
  isVisible: boolean;
}

interface CreateSubprojectFormProps {
  onSubmit: (values: CreateSubprojectFormValues) => void;
  subprojectNames: string[];
}

const CreateSubprojectForm = ({
  onSubmit,
  subprojectNames,
}: CreateSubprojectFormProps): JSX.Element => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [isVisible, setIsVisible] = useState(false);
  const [showCsv, setShowCsv] = useState(false);

  const handleReset = () => {
    setName('');
    setDescription('');
    setIsVisible(false);
    setShowCsv(false);
  };

  const trimmedName = name.replace(/\s+/g, ' ').trim();
  const isSubprojectNameTaken = subprojectNames.includes(trimmedName);
  const canSubmit = trimmedName.length && !isSubprojectNameTaken;

  return (
    <VStack spacing="24" alignItems="stretch">
      <Input
        id="create-subproject-name-input"
        type="text"
        value={name}
        onChange={(val) => {
          setName(val);
        }}
        required={true}
        isInvalid={isSubprojectNameTaken}
        errorMessage="Subproject name is already taken."
        label="Subproject name"
      />
      <Textarea
        id="create-subproject-description-input"
        value={description}
        onChange={(val) => {
          setDescription(val);
        }}
        required={true}
        label="Description"
        minRows={5}
      />
      <$FormSectionLegend>Allow the following:</$FormSectionLegend>
      <Checkbox
        id="create-subproject-is-visible-input"
        label="Visibility"
        checked={isVisible}
        onCheckedChange={(val: boolean) => {
          setIsVisible(val);
        }}
      />

      <Checkbox
        id="create-subproject-show-csv-input"
        label="CSV File"
        checked={showCsv}
        onCheckedChange={(val: boolean) => {
          setShowCsv(val);
        }}
      />

      <HStack spacing="8">
        <Button
          disabled={!canSubmit}
          variant="primary"
          onClick={() => {
            onSubmit({
              name: trimmedName,
              description,
              showCsv,
              isVisible,
            });
          }}
        >
          Create
        </Button>
        <Button variant="primaryOutline" onClick={handleReset}>
          Reset
        </Button>
      </HStack>
    </VStack>
  );
};

export default CreateSubprojectForm;
