import { useSnackbarMutations } from '@aignostics/components';
import { ApolloError, MutationResult, useMutation } from '@apollo/client';
import { useCallback, useMemo } from 'react';
import { CREATE_ANNOTATION_CATEGORIES } from '../../../../components';
import {
  AnnotationCategoryInput,
  AnnotationDrawingTools,
  AnnotationFeatureType,
  OtherAnnotationVisibility,
} from '../../../../types';
import { FETCH_ANNOTATION_CATEGORY_SETS } from '../../../AnnotationManagement';
import { FETCH_ADMIN_SUB_PROJECT_DETAILS } from '../../../Projects/Admin/FETCH_ADMIN_SUB_PROJECT_DETAILS';
import { GET_DELETION_INFO } from '../Delete/DeleteSubproject.component';
import { FETCH_SUB_PROJECT_ANNOTATIONS_SETTINGS } from './AdminSubprojectCategories/FETCH_SUB_PROJECT_ANNOTATIONS_SETTINGS.queries';
import { UPDATE_SUBPROJECT_ANNOTATIONS_SETTINGS_AND_CATEGORIES } from './UPDATE_SUBPROJECT_ANNOTATIONS_SETTINGS_AND_CATEGORIES';

interface AnnotationCategoryArgs {
  annotationCategoryId: string;
  subProjectId: string;
  color?: string;
}

type UpdateSubProjectAnnotations = ({
  updatedSubProject,
  addedAnnotationCategories,
  removedAnnotationCategories,
  newAnnotationCategories,
}: {
  updatedSubProject: {
    id: string | undefined;
    projectId: string | undefined;
    annotationFeature: AnnotationFeatureType;
    otherAnnotationVisibility: OtherAnnotationVisibility;
    annotationDrawingToolsInput: AnnotationDrawingTools;
  };
  addedAnnotationCategories: AnnotationCategoryArgs[];
  removedAnnotationCategories: AnnotationCategoryArgs[];
  newAnnotationCategories: AnnotationCategoryInput[];
}) => void;

export const useUpdateSubProjectAnnotations = ({
  onUpdateCompleted,
}: {
  onUpdateCompleted: () => void;
}): {
  updateSubProjectAnnotations: UpdateSubProjectAnnotations;
  errors: Record<
    'subProject' | 'createAnnotationCategories',
    ApolloError | undefined
  >;
  loading: boolean;
} => {
  const { addSnackbar } = useSnackbarMutations();

  const [
    createAnnotationCategories,
    {
      error: createAnnotationCategoriesError,
      loading: createAnnotationCategoriesLoading,
    },
  ] = useMutation(CREATE_ANNOTATION_CATEGORIES, {
    onError: (error) => {
      addSnackbar({
        type: 'error',
        message: error.message,
      });
    },
  });

  const [
    updateSubProjectAnnotationsSettingsAndCategories,
    {
      loading: updateSubProjectAnnotationsSettingsAndCategoriesLoading,
      error: updateSubProjectAnnotationsSettingsAndCategoriesError,
    },
  ] = useMutation<
    MutationResult,
    {
      id: string;
      projectId: string;
      annotationFeature: AnnotationFeatureType;
      otherAnnotationVisibility: OtherAnnotationVisibility;
      annotationDrawingToolsInput: AnnotationDrawingTools;
      addedAnnotationCategories: AnnotationCategoryArgs[];
      removedAnnotationCategories: AnnotationCategoryArgs[];
    }
  >(UPDATE_SUBPROJECT_ANNOTATIONS_SETTINGS_AND_CATEGORIES, {
    onError: (error) => {
      addSnackbar({
        type: 'error',
        message: error.message,
      });
    },
    onCompleted: (data) => {
      if (data) {
        addSnackbar({
          type: 'success',
          message: 'Changes have been saved',
          closesAfter: 1000,
        });
      } else {
        addSnackbar({
          type: 'error',
          message: 'Something went wrong. Please try again',
        });
      }
      onUpdateCompleted();
    },
  });

  const errors = useMemo(
    () => ({
      subProject: updateSubProjectAnnotationsSettingsAndCategoriesError,
      createAnnotationCategories: createAnnotationCategoriesError,
    }),
    [
      updateSubProjectAnnotationsSettingsAndCategoriesError,
      createAnnotationCategoriesError,
    ]
  );

  const loading =
    updateSubProjectAnnotationsSettingsAndCategoriesLoading ||
    createAnnotationCategoriesLoading;

  const updateSubProjectAnnotations = useCallback<UpdateSubProjectAnnotations>(
    async ({
      updatedSubProject,
      addedAnnotationCategories,
      removedAnnotationCategories,
      newAnnotationCategories,
    }) => {
      const {
        id,
        projectId,
        annotationFeature,
        otherAnnotationVisibility,
        annotationDrawingToolsInput,
      } = updatedSubProject;

      if (newAnnotationCategories.length > 0) {
        await createAnnotationCategories({
          variables: {
            annotationCategories: newAnnotationCategories,
          },
        });
      }

      await updateSubProjectAnnotationsSettingsAndCategories({
        variables: {
          id: id as string,
          projectId: projectId as string,
          annotationFeature,
          otherAnnotationVisibility,
          annotationDrawingToolsInput,
          addedAnnotationCategories,
          removedAnnotationCategories,
        },
        refetchQueries: [
          {
            query: FETCH_ADMIN_SUB_PROJECT_DETAILS,
            variables: { subProjectId: id },
          },
          {
            query: FETCH_SUB_PROJECT_ANNOTATIONS_SETTINGS,
            variables: { subProjectId: id },
          },
          {
            query: FETCH_ANNOTATION_CATEGORY_SETS,
          },
          {
            query: GET_DELETION_INFO,
            variables: { subProjectId: id },
          },
        ],
      });
    },
    [
      updateSubProjectAnnotationsSettingsAndCategories,
      createAnnotationCategories,
    ]
  );

  return {
    updateSubProjectAnnotations,
    errors,
    loading,
  };
};
