import { AdminDataOnboardingBatch } from '@aignostics/onboarding-ui';
import React from 'react';
import useUserRole from '../../../hooks/useUserRole';
import { useAuthUser } from '../../App/AuthProvider';

export const AdminDataOnboardingBatchWrapper = (): JSX.Element => {
  const currentUser = useAuthUser();
  const role = useUserRole();
  return <AdminDataOnboardingBatch role={role} currentUser={currentUser} />;
};
