import {
  Button,
  Checkbox,
  HStack,
  Icon,
  Loader,
  Modal,
  RadioButtonWithLegend,
  Section,
  useDisclosure,
  useSnackbarMutations,
  VStack,
} from '@aignostics/components';
import { useQuery } from '@apollo/client';
import { Flex } from '@radix-ui/themes';
import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { useParams } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { SortByDirection, useSortBy } from '../../../../hooks';
import {
  AnnotationCategoriesSortByOptions,
  AnnotationCategory,
  AnnotationCategoryChange,
  AnnotationCategoryInput,
  AnnotationDrawingTools,
  AnnotationFeatureType,
  OtherAnnotationVisibility,
} from '../../../../types';
import { getQueryParams } from '../../../../utils';
import {
  $AnnotationsFeatureSelectContainer,
  $AnnotationsFormUnsavedChangesWarning,
  $Card,
  $DrawingTool,
  $Footer,
  $FooterWrapper,
  $NoAnnotationDrawingToolSelectedWarning,
} from './AdminSubprojectAnnotations.styles';
import { AdminSubprojectCategories } from './AdminSubprojectCategories';
import { FETCH_SUB_PROJECT_ANNOTATIONS_SETTINGS } from './AdminSubprojectCategories/FETCH_SUB_PROJECT_ANNOTATIONS_SETTINGS.queries';
import { getUpdatedAnnotationCategories } from './AdminSubprojectCategories/utils';
import { useUpdateSubProjectAnnotations } from './useUpdateSubProjectAnnotations';
import { getAddedOrRemovedAnnotationCategories } from './utils';

interface AdminSubprojectAnnotationsForm {
  annotationFeature: AnnotationFeatureType;
  otherAnnotationVisibility: OtherAnnotationVisibility;
  annotationDrawingTools: AnnotationDrawingTools;
  annotationCategories: AnnotationCategory[];
}

export const NO_DRAWING_TOOL_WARNING_TEXT =
  'You need to select at least one drawing tool.';

export const INVALID_ANNOTATION_STATE_WARNING_TEXT = `You have enabled the option to create, edit & delete annotations
  without adding annotation categories. Please add at least one
  annotation category to work with annotations on a slide. Otherwise
  disable the option to create, edit & delete annotations.`;

const AdminSubprojectAnnotations = (): ReactElement => {
  const theme = useTheme();
  const [shouldTurnPenToolOnByDefault, setShouldTurnPenToolOnByDefault] =
    useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const urlParams = getQueryParams(['sortBy', 'sortDirection']);
  const sortByParam = urlParams?.sortBy as AnnotationCategoriesSortByOptions;
  const sortDirectionParam = urlParams?.sortDirection as SortByDirection;

  const { sortBy, setSortByOptions } =
    useSortBy<AnnotationCategoriesSortByOptions>({
      column: sortByParam || 'name',
      sortDirection: sortDirectionParam || 'asc',
    });

  const { projectId, subProjectId } = useParams<{
    projectId: string;
    subProjectId: string;
  }>();

  const { addSnackbar } = useSnackbarMutations();

  const {
    data: subprojectAnnotationsSettings,
    loading: subprojectAnnotationsSettingsLoading,
    error: subprojectAnnotationsSettingsError,
  } = useQuery<{
    subprojectAnnotationCategories: AnnotationCategory[];
    subProject: {
      annotationDrawingTools: AnnotationDrawingTools;
      annotationFeature: AnnotationFeatureType;
      otherAnnotationVisibility: OtherAnnotationVisibility;
    };
  }>(FETCH_SUB_PROJECT_ANNOTATIONS_SETTINGS, {
    variables: { subProjectId },
  });

  const {
    updateSubProjectAnnotations,
    errors,
    loading: updateSubProjectAnnotationsLoading,
  } = useUpdateSubProjectAnnotations({
    onUpdateCompleted: () => {
      setNewAnnotationCategories([]);
      setIsSubmitted(true);
    },
  });

  const [newAnnotationCategories, setNewAnnotationCategories] = useState<
    AnnotationCategoryInput[]
  >([]);

  const initialValues = useMemo(() => {
    if (
      subprojectAnnotationsSettingsLoading ||
      subprojectAnnotationsSettingsError ||
      !subprojectAnnotationsSettings
    ) {
      return null;
    }
    const {
      subProject: {
        annotationDrawingTools: { pen, brush, picker },
        annotationFeature,
        otherAnnotationVisibility,
      },
      subprojectAnnotationCategories,
    } = subprojectAnnotationsSettings;
    return {
      annotationFeature: annotationFeature ?? 'OFF',
      otherAnnotationVisibility: otherAnnotationVisibility ?? 'OFF',
      annotationDrawingTools: {
        pen,
        picker,
        brush,
      },
      annotationCategories: subprojectAnnotationCategories ?? [],
    };
  }, [
    subprojectAnnotationsSettings,
    subprojectAnnotationsSettingsLoading,
    subprojectAnnotationsSettingsError,
  ]);

  const invalidFormStateWarningDisclosure = useDisclosure();

  useEffect(() => {
    if (
      initialValues &&
      Object.values(initialValues.annotationDrawingTools).every((tool) => !tool)
    ) {
      setShouldTurnPenToolOnByDefault(true);
    }
  }, [initialValues, setShouldTurnPenToolOnByDefault]);

  const handleSubmit = (formValues: AdminSubprojectAnnotationsForm) => {
    if (initialValues) {
      const { annotationCategories, annotationFeature } = formValues;

      setIsSubmitted(true);

      const { otherAnnotationVisibility, annotationDrawingTools } = formValues;

      const addedAnnotationCategories = getAddedOrRemovedAnnotationCategories(
        subProjectId as string,
        initialValues.annotationCategories,
        annotationCategories,
        'added'
      );

      const removedAnnotationCategories = getAddedOrRemovedAnnotationCategories(
        subProjectId as string,
        initialValues.annotationCategories,
        annotationCategories,
        'removed'
      );

      const updatedSubproject = {
        id: subProjectId as string,
        projectId: projectId as string,
        annotationFeature,
        otherAnnotationVisibility,
        annotationDrawingToolsInput: annotationDrawingTools,
      };

      updateSubProjectAnnotations({
        updatedSubProject: updatedSubproject,
        addedAnnotationCategories,
        removedAnnotationCategories,
        newAnnotationCategories: newAnnotationCategories.map(
          ({
            __typename,
            id,
            color,
            inParent,
            name,
            annotationCategorySet,
          }) => {
            return {
              __typename,
              id,
              color,
              name,
              inParent,
              ...(annotationCategorySet?.kind !== 'unset'
                ? { annotationCategorySet }
                : null),
            };
          }
        ),
      });
    }
  };

  const loading =
    updateSubProjectAnnotationsLoading || subprojectAnnotationsSettingsLoading;

  const error =
    subprojectAnnotationsSettingsError ||
    errors.subProject ||
    errors.createAnnotationCategories;

  return (
    <Section
      title="Configure Annotations"
      description="Define who can create, view, edit and delete annotations."
      loading={loading}
      error={error}
      style={{
        paddingBottom: `${theme.spacings['96']}px`,
      }}
    >
      {subprojectAnnotationsSettingsLoading && <Loader />}
      {!subprojectAnnotationsSettingsLoading && initialValues && (
        <Form<AdminSubprojectAnnotationsForm, AdminSubprojectAnnotationsForm>
          onSubmit={handleSubmit}
          initialValues={initialValues}
          mutators={{
            setValue: ([field, value], state, { changeValue }) => {
              changeValue(state, field, () => value);
            },
          }}
          // this property is needed to prevet visual "flickering" to initial values upon submit
          keepDirtyOnReinitialize
          render={(formRenderProps) => {
            const { handleSubmit, form, dirtySinceLastSubmit, dirty } =
              formRenderProps;

            const { getState, reset, setConfig } = form;
            const { values } = getState();
            const {
              annotationFeature,
              otherAnnotationVisibility,
              annotationDrawingTools,
              annotationCategories,
            } = values;
            const { pen, brush, picker } = annotationDrawingTools;

            // dirty will always be false after isSubmitted is true
            // dirtySinceLastSubmit will always be false until isSubmitted is true
            // we have to use dirty before first submit, and dirtySinceLastSubmit afterwards
            const inputsChanged = !isSubmitted ? dirty : dirtySinceLastSubmit;
            const isDrawingToolSelected = pen || brush || picker;
            const isAnnotationFeatureOnAndDrawingToolSelected =
              annotationFeature === 'ON' && isDrawingToolSelected;

            const canSubmit =
              inputsChanged &&
              (isAnnotationFeatureOnAndDrawingToolSelected ||
                annotationFeature !== 'ON');

            return (
              <>
                <$AnnotationsFeatureSelectContainer>
                  <Field
                    name="annotationFeature"
                    render={({
                      input: { onChange: onAnnotationFeatureChange },
                    }) => (
                      <VStack spacing="base" style={{ width: '100%' }}>
                        <$Card
                          role="group"
                          aria-label="Disabled Annotations"
                          style={{ backgroundColor: theme.colors.white }}
                        >
                          <RadioButtonWithLegend
                            name="annotationFeature"
                            value={'OFF' as AnnotationFeatureType}
                            id="annotation-disabled"
                            label="Disabled"
                            legend="It disables the feature for viewers on this subproject: annotations can't be created, viewed nor edited."
                            checked={annotationFeature === 'OFF'}
                            onChange={() => {
                              onAnnotationFeatureChange('OFF');
                            }}
                          />
                        </$Card>
                        <$Card
                          role="group"
                          aria-label="Annotations read"
                          style={{ backgroundColor: theme.colors.white }}
                        >
                          <RadioButtonWithLegend
                            name="annotationFeature"
                            value={'READ' as AnnotationFeatureType}
                            id="annotations-read"
                            label="Read-Only"
                            legend="User can only view own existing annotations. No editing allowed."
                            checked={annotationFeature === 'READ'}
                            onChange={() => {
                              onAnnotationFeatureChange('READ');
                            }}
                          />
                          <Field
                            name="otherAnnotationVisibility"
                            render={({
                              input: {
                                onChange: onOtherAnnotationVisibilityChange,
                              },
                            }) => (
                              <$Card
                                style={{
                                  backgroundColor: theme.colors.lighter,
                                }}
                              >
                                <Checkbox
                                  label="User can also view others annotations"
                                  legend="It allows user to also view, without editing, annotations from other users."
                                  data-testid="read-others-visibility"
                                  checked={
                                    ['READ_ONLY', 'READ_ANONYMOUS'].includes(
                                      otherAnnotationVisibility
                                    ) && annotationFeature === 'READ'
                                  }
                                  onCheckedChange={(checked) => {
                                    onOtherAnnotationVisibilityChange(
                                      checked ? 'READ_ONLY' : 'OFF'
                                    );
                                  }}
                                  disabled={annotationFeature !== 'READ'}
                                />

                                {['READ_ONLY', 'READ_ANONYMOUS'].includes(
                                  otherAnnotationVisibility
                                ) &&
                                  annotationFeature === 'READ' && (
                                    <Checkbox
                                      label="Anonymize annotators"
                                      legend="It will anonymize other
                                          anotators' names"
                                      checked={
                                        otherAnnotationVisibility ===
                                          'READ_ANONYMOUS' &&
                                        annotationFeature === 'READ'
                                      }
                                      onCheckedChange={(checked) => {
                                        onOtherAnnotationVisibilityChange(
                                          checked
                                            ? 'READ_ANONYMOUS'
                                            : 'READ_ONLY'
                                        );
                                      }}
                                      disabled={annotationFeature !== 'READ'}
                                    />
                                  )}
                              </$Card>
                            )}
                          />
                        </$Card>
                        <$Card
                          role="group"
                          aria-label="Annotations edit"
                          style={{ backgroundColor: theme.colors.white }}
                        >
                          <RadioButtonWithLegend
                            name="annotationFeature"
                            value={'ON' as AnnotationFeatureType}
                            id="annotations-edit"
                            label="Create, Edit & Delete"
                            legend="User can create, view, edit and delete own annotations on this subproject."
                            checked={annotationFeature === 'ON'}
                            onChange={() => {
                              onAnnotationFeatureChange('ON');
                              if (shouldTurnPenToolOnByDefault) {
                                form.mutators.setValue(
                                  'annotationDrawingTools.pen',
                                  true
                                );
                                setShouldTurnPenToolOnByDefault(false);
                              }
                            }}
                          />
                          <Field
                            name="otherAnnotationVisibility"
                            render={({
                              input: {
                                onChange: onOtherAnnotationVisibilityChange,
                              },
                            }) => (
                              <$Card
                                style={{
                                  backgroundColor: theme.colors.lighter,
                                }}
                              >
                                <Checkbox
                                  data-testid="read-others-visibility"
                                  label="User can also view others annotations"
                                  legend="It allows user to also view, without
                                      editing, annotations from other users."
                                  checked={
                                    ['READ_ONLY', 'READ_ANONYMOUS'].includes(
                                      otherAnnotationVisibility
                                    ) && annotationFeature === 'ON'
                                  }
                                  onCheckedChange={(checked) => {
                                    onOtherAnnotationVisibilityChange(
                                      checked ? 'READ_ONLY' : 'OFF'
                                    );
                                  }}
                                  disabled={annotationFeature !== 'ON'}
                                />

                                {['READ_ONLY', 'READ_ANONYMOUS'].includes(
                                  otherAnnotationVisibility
                                ) &&
                                  annotationFeature === 'ON' && (
                                    <Checkbox
                                      id="annotations-edit-others-visibility-anonymous"
                                      label="Anonymize annotators"
                                      legend="It will anonymize other
                                          annotators' names"
                                      checked={
                                        otherAnnotationVisibility ===
                                          'READ_ANONYMOUS' &&
                                        annotationFeature === 'ON'
                                      }
                                      onCheckedChange={(checked) => {
                                        onOtherAnnotationVisibilityChange(
                                          checked
                                            ? 'READ_ANONYMOUS'
                                            : 'READ_ONLY'
                                        );
                                      }}
                                      disabled={annotationFeature !== 'ON'}
                                    />
                                  )}
                              </$Card>
                            )}
                          />

                          <p style={theme.fontStyles.smallBold}>
                            Configure the drawing options that can be used to
                            annotate:
                          </p>
                          <HStack
                            justifyContent="space-between"
                            alignItems="center"
                            style={{
                              width: '100%',
                              backgroundColor: theme.colors.white,
                            }}
                            data-testid="drawing-tool-checkbox"
                          >
                            <Field
                              name="annotationDrawingTools.pen"
                              render={({
                                input: {
                                  onChange: onAnnotationDrawingToolsChange,
                                },
                              }) => (
                                <$DrawingTool>
                                  <Flex as="span" gap="2" align="center">
                                    <Checkbox
                                      label="Pen tool"
                                      legend="Draw precise outlines on slides."
                                      data-testid="penTool"
                                      checked={annotationDrawingTools.pen}
                                      onCheckedChange={
                                        onAnnotationDrawingToolsChange
                                      }
                                      disabled={annotationFeature !== 'ON'}
                                    />

                                    <Icon
                                      style={{ marginLeft: 12 }}
                                      size="line"
                                      icon="Pen"
                                    />
                                  </Flex>
                                </$DrawingTool>
                              )}
                            />

                            <Field
                              name="annotationDrawingTools.brush"
                              render={({
                                input: {
                                  onChange: onAnnotationDrawingToolsChange,
                                },
                              }) => (
                                <$DrawingTool>
                                  <Flex as="span" gap="2" align="center">
                                    <Checkbox
                                      id="brushTool"
                                      label="Brush tool"
                                      legend="Quickly mark regions on slides."
                                      checked={annotationDrawingTools.brush}
                                      onCheckedChange={
                                        onAnnotationDrawingToolsChange
                                      }
                                      disabled={annotationFeature !== 'ON'}
                                    />
                                    <Icon
                                      style={{ marginLeft: 12 }}
                                      size="line"
                                      icon="Brush"
                                    />
                                  </Flex>
                                </$DrawingTool>
                              )}
                            />

                            <Field
                              name="annotationDrawingTools.picker"
                              render={({
                                input: {
                                  onChange: onAnnotationDrawingToolsChange,
                                },
                              }) => (
                                <$DrawingTool>
                                  <Flex as="span" gap="2" align="center">
                                    <Checkbox
                                      id="pickerTool"
                                      label="Picker tool"
                                      legend="Pick cells of interactive overlays."
                                      checked={annotationDrawingTools.picker}
                                      onCheckedChange={
                                        onAnnotationDrawingToolsChange
                                      }
                                      disabled={annotationFeature !== 'ON'}
                                    />
                                    <Icon
                                      style={{ marginLeft: 12 }}
                                      size="line"
                                      icon="Crosshair"
                                    />
                                  </Flex>
                                </$DrawingTool>
                              )}
                            />
                          </HStack>
                          {annotationFeature === 'ON' &&
                          !isDrawingToolSelected ? (
                            <$NoAnnotationDrawingToolSelectedWarning>
                              {NO_DRAWING_TOOL_WARNING_TEXT}
                            </$NoAnnotationDrawingToolSelectedWarning>
                          ) : null}
                        </$Card>
                      </VStack>
                    )}
                  />
                </$AnnotationsFeatureSelectContainer>
                <Field
                  name="annotationCategories"
                  render={({
                    input: { onChange: onAnnotationCategoriesChange },
                  }) =>
                    isAnnotationFeatureOnAndDrawingToolSelected && (
                      <AdminSubprojectCategories
                        annotationCategories={annotationCategories}
                        sortBy={sortBy}
                        setSortByOptions={setSortByOptions}
                        onUpdateAnnotationCategories={(
                          args: AnnotationCategoryChange[]
                        ) => {
                          onAnnotationCategoriesChange(
                            getUpdatedAnnotationCategories({
                              categoryChanges: args,
                              annotationCategories,
                            })
                          );
                        }}
                        onImportAnnotationCategoriesFromSubProject={(
                          importedCategories
                        ) => {
                          const updatedAnnotationCategories =
                            annotationCategories.map((annotationCategory) => {
                              const importedCategory = importedCategories.find(
                                (c) => c.id === annotationCategory.id
                              );
                              return {
                                ...annotationCategory,
                                inParent: importedCategory
                                  ? true
                                  : annotationCategory.inParent,
                                color: importedCategory
                                  ? importedCategory.color
                                  : annotationCategory.color,
                              };
                            });
                          onAnnotationCategoriesChange(
                            updatedAnnotationCategories
                          );
                        }}
                        onCreateAnnotationCategory={(newCategory) => {
                          setNewAnnotationCategories([
                            ...newAnnotationCategories,
                            newCategory,
                          ]);
                          onAnnotationCategoriesChange([
                            ...annotationCategories,
                            newCategory,
                          ]);
                        }}
                      />
                    )
                  }
                />
                <$FooterWrapper>
                  <$Footer>
                    <HStack
                      spacing="large"
                      justifyContent="start"
                      alignItems="center"
                    >
                      {canSubmit && (
                        <$AnnotationsFormUnsavedChangesWarning>
                          You have unsaved changes. if you leave without saving,
                          they will be lost.
                        </$AnnotationsFormUnsavedChangesWarning>
                      )}
                    </HStack>
                    <HStack spacing="large" justifyContent="end">
                      <Button
                        variant="primaryOutline"
                        onClick={() => {
                          setConfig('keepDirtyOnReinitialize', false);
                          reset();
                          setConfig('keepDirtyOnReinitialize', true);
                          addSnackbar({
                            type: 'info',
                            message: 'The configuration has been reset',
                            closesAfter: 1000,
                          });
                        }}
                        disabled={loading || !canSubmit}
                      >
                        Reset changes
                      </Button>
                      <Button
                        onClick={async () => {
                          const isAnyCategoryAssigned =
                            annotationCategories.some(
                              (category) => category.inParent
                            );

                          if (
                            annotationFeature === 'ON' &&
                            !isAnyCategoryAssigned
                          ) {
                            invalidFormStateWarningDisclosure.open();
                          } else {
                            await handleSubmit();
                          }
                        }}
                        disabled={loading || !canSubmit}
                      >
                        Save changes
                      </Button>
                    </HStack>
                  </$Footer>
                </$FooterWrapper>
                {invalidFormStateWarningDisclosure.isOpen && (
                  <Modal
                    isVisible={invalidFormStateWarningDisclosure.isOpen}
                    onClose={invalidFormStateWarningDisclosure.close}
                    hasCloseButton
                    size="small"
                  >
                    <Section>
                      <VStack spacing="16">
                        <p>{INVALID_ANNOTATION_STATE_WARNING_TEXT}</p>
                        <HStack
                          spacing="8"
                          style={{ justifyContent: 'end', width: '100%' }}
                        >
                          <Button
                            onClick={() => {
                              form.mutators.setValue(
                                'annotationFeature',
                                'OFF'
                              );
                              invalidFormStateWarningDisclosure.close();
                            }}
                          >
                            Disable create, edit & delete annotations
                          </Button>
                          <Button
                            onClick={invalidFormStateWarningDisclosure.close}
                            variant="primaryOutline"
                          >
                            Cancel
                          </Button>
                        </HStack>
                      </VStack>
                    </Section>
                  </Modal>
                )}
              </>
            );
          }}
        />
      )}
    </Section>
  );
};
export default AdminSubprojectAnnotations;
