"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const date_fns_1 = require("date-fns");
const formatDate = (date) => {
    try {
        return (0, date_fns_1.format)(new Date(date), 'yyyy-MM-dd HH:mm');
    }
    catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        return date;
    }
};
exports.default = formatDate;
