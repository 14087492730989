import { VisibleWithScope } from '@aignostics/components';
import { useViewport } from '@aignostics/hooks';
import React, {
  ReactElement,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useTheme } from 'styled-components';
import useUserRole from '../../../hooks/useUserRole';
import { $Leaf, $MenuList, $MenuSidebar } from './MenuSidebar.styles';

interface LeafProps {
  to: string;
  light?: boolean;
  active?: boolean;
  children: ReactNode;
}

const Leaf = ({
  children,
  to,
  active = false,
  light = false,
}: LeafProps): ReactElement => {
  const theme = useTheme();
  const ref = useRef<HTMLAnchorElement>(null);

  const scrollIntoView = () => {
    if (ref.current) {
      ref.current.parentElement?.scrollTo({
        top: ref.current.offsetTop,
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    if (active) scrollIntoView();
  }, [active]);

  return (
    <$Leaf
      ref={ref}
      to={to}
      initial={{
        backgroundColor: theme.colors.text,
      }}
      animate={{
        backgroundColor: active
          ? theme.colors.warning
          : light
            ? theme.colors.lighter
            : // active ? theme.colors.warning : theme.colors.lighter,
              theme.colors.dark,
      }}
      exit={{
        backgroundColor: theme.colors.text,
      }}
      whileHover={{
        backgroundColor: theme.colors.base,
      }}
    >
      {children}
    </$Leaf>
  );
};

interface MenuSidebarProps {
  closeSideNav: () => void;
}

/** MenuSidebar navigation component */
const MenuSidebar = ({ closeSideNav }: MenuSidebarProps): ReactElement => {
  const theme = useTheme();
  const location = useLocation();
  const [pathname, setPathname] = useState(location.pathname);
  const params = useParams();
  const role = useUserRole();

  useEffect(() => {
    /** Store pathname on mount to detect route changes */
    setPathname(location.pathname);
  }, [location.pathname]);

  useEffect(() => {
    /** Handle route change – close sidenav except on wsi pages */
    if (pathname !== location.pathname) {
      closeSideNav();
    }
  }, [location.pathname, closeSideNav, pathname]);

  const menuRef = useRef<HTMLElement>(null);

  // Get viewport height
  const { height } = useViewport(theme.breakpoints);

  return (
    <$MenuSidebar
      ref={menuRef}
      initial={{ x: '-100%' }}
      animate={{ x: '0%' }}
      exit={{ x: '-100%' }}
      transition={{
        ease: 'easeInOut',
        x: { duration: 0.3 },
      }}
      style={{ height: height - theme.spacings.button }}
      aria-label="Slide navigation"
    >
      {/* Admin UI */}
      <VisibleWithScope role={role} scope="admin:access">
        <$MenuList>
          <Leaf to={`/${params.organizationUuid}/admin/projects`}>
            Projects
          </Leaf>
          <VisibleWithScope role={role} scope="user:readAll">
            <Leaf to={`/${params.organizationUuid}/admin/users`}>Users</Leaf>
          </VisibleWithScope>
          <Leaf to={`/${params.organizationUuid}/admin/data-onboarding`}>
            Data Onboarding
          </Leaf>
          <Leaf to={`/${params.organizationUuid}/admin/slide-library`}>
            Slide Library
          </Leaf>
        </$MenuList>
      </VisibleWithScope>
    </$MenuSidebar>
  );
};

export default MenuSidebar;
