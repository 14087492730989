import {
  Disclosure,
  GridItemSlide,
  GridItemSlideSkeleton,
  Placeholder,
  VStack,
} from '@aignostics/components';
import { buildClientWsiUrl } from '@aignostics/utils';
import React, { ReactElement } from 'react';
import { useLocation } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { WsiThumbnail } from '../../../../components';
import { useLayoutMode } from '../../../../hooks';
import { Wsi } from '../../../../types';

type ClientSubProjectSlidesGridProps = {
  organizationUuid: string;
  projectId: string;
  subProjectId: string;
  loading: boolean;
  wsis: Wsi[];
  pageSize: number;
  infoModalDisclosure: Disclosure<Wsi>;
  rasterTileServerUrl: string;
  getToken: () => Promise<string>;
};
export const ClientSubProjectSlidesGrid = ({
  organizationUuid,
  projectId,
  subProjectId,
  loading,
  wsis,
  pageSize,
  infoModalDisclosure,
  rasterTileServerUrl,
  getToken,
}: ClientSubProjectSlidesGridProps): ReactElement => {
  const { LayoutContainer } = useLayoutMode(
    undefined,
    `layout-${subProjectId}`
  );

  const theme = useTheme();
  const location = useLocation();

  const gridItem = (wsi: Wsi) => {
    const { id, name } = wsi;

    const props = {
      key: id,
      title: name,
      image: (
        <WsiThumbnail
          wsiId={wsi.id}
          getToken={getToken}
          rasterTileServerUrl={rasterTileServerUrl}
        />
      ),
      active: id === subProjectId,
      to: buildClientWsiUrl(
        organizationUuid,
        projectId,
        subProjectId,
        id,
        location.search
      ),
      annotationsCount: wsi.annotationsCount,
      overlaysCount: wsi.overlaysCount,
      taggersCount: wsi.taggersCount,
      brightfieldCount: wsi.brightfieldCount,
      fluorescenceCount: wsi.fluorescenceCount,
      regionsOfInterestCount: wsi.regionsOfInterestCount,
    };

    return (
      <GridItemSlide
        {...props}
        infoModalDisclosure={{
          ...infoModalDisclosure,
          open: () => {
            infoModalDisclosure.open(wsi);
          },
        }}
      />
    );
  };

  return (
    <VStack
      style={{
        gap: `${theme.spacings[32]}px`,
        width: '100%',
        alignItems: 'center',
      }}
    >
      {loading && (
        <LayoutContainer>
          {Array.from({ length: pageSize }).map((_, index) => (
            <GridItemSlideSkeleton key={index} />
          ))}
        </LayoutContainer>
      )}
      {!loading && wsis.length === 0 && (
        <Placeholder
          title="No slides"
          description="We could not find any matching slides."
        />
      )}
      {!loading && wsis.length > 0 && (
        <LayoutContainer>{wsis.map((wsi) => gridItem(wsi))}</LayoutContainer>
      )}
    </VStack>
  );
};
