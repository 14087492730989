import styled from 'styled-components';

export const $GridWrapper = styled.div`
  height: 600px;
  max-height: 1200px;

  .ag-row-header {
    background-color: ${({ theme }) => theme.colors.light};
    font-weight: bold;

    .ag-cell {
      border-right: none;
    }
  }
`;
