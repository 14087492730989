import {
  AG_GRID_LICENSE_KEY,
  useSnackbarMutations,
} from '@aignostics/components';
import {
  EditableCallbackParams,
  ICellRendererParams,
  LicenseManager,
  themeQuartz,
  type Theme as AGGridTheme,
  type CellEditingStoppedEvent,
  type ColDef,
  type GetRowIdFunc,
  type GridApi,
  type GridOptions,
  type GridReadyEvent,
  type RowClassParams,
} from 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
  type ReactElement,
} from 'react';
import { useTheme } from 'styled-components';
import type { SingleChannelFormRow } from '../../SetFileMetadataStep/Form/form.state.types';

import {
  CustomCellDeleteSlide,
  CustomCellSelectValue,
  CustomCellSlideName,
} from './CustomCellRenderers';
import {
  buildGridTreeData,
  flattenGridTreeData,
} from './MetadataGrid.DataFormater';
import { $GridWrapper } from './MetadataGrid.styles';
import {
  GridData,
  MetadataGridContext,
  MetadataGridProps,
  MetadataGridRefHandle,
} from './MetadataGrid.types';
import {
  collectionValueFormatter,
  defaultColDef,
  GEN_PINNED_ROW_TEMPLATE,
  generateSelectColDef,
  generateTextColDef,
  GRID_MODULES,
  isValidRowDatum,
  renderCancerSite,
  renderDisease,
  renderLocalization,
  renderMorphology,
  renderPreparationType,
  renderSampleType,
  renderScanner,
  renderStaining,
  slideNameColId,
} from './MetadataGrid.utils';

LicenseManager.setLicenseKey(AG_GRID_LICENSE_KEY);

export const MetadataGrid = forwardRef<
  MetadataGridRefHandle,
  MetadataGridProps
>(function MetadataGrid(
  {
    tissues,
    stainings,
    scanners,
    diseases,
    preparationTypes,
    sampleTypes,
    morphologies,
    cancerSites,
    initialValues,
    apiUrl,
    authToken,
    organizationUuid,
    updateValidCount,
    onGridReady,
    supressAgGridVirtualization,
  },
  ref
): ReactElement {
  const theme = useTheme();
  const gridTheme = useMemo<AGGridTheme>(
    () =>
      themeQuartz.withParams({
        columnBorder: true,
        headerFontWeight: 'bold',
        headerBackgroundColor: theme.colors.light,
      }),
    [theme.colors.light]
  );

  const { addSnackbar } = useSnackbarMutations();
  const gridRef = useRef<AgGridReact<GridData>>(null);
  const [gridIsReady, setGridIsReady] = useState(true);

  const generateInitialRowData = useCallback(() => {
    const data = initialValues.map((value) => ({
      ...value,
      rowId: value.slideFile.filename,
    }));

    const treeData = buildGridTreeData(data);
    const flattenedData = flattenGridTreeData(treeData);

    return flattenedData;
  }, [initialValues]);

  const rowData = useMemo<GridData[]>(
    () => generateInitialRowData(),
    [generateInitialRowData]
  );

  const getRowId: GetRowIdFunc<GridData> = useMemo(
    () =>
      ({ data }) => {
        return data.rowId;
      },
    []
  );

  const assertGridReadiness: () => GridApi<GridData> = useCallback(() => {
    if (gridIsReady) {
      if (!gridRef.current?.api) {
        throw new Error('grid should had been ready');
      }

      return gridRef.current.api;
    }

    throw new Error('grid is not ready');
  }, [gridIsReady]);

  const scheduledCounterUpdateTimeout = useRef<number | null>(null);

  const updateCounters = useCallback(() => {
    const api = assertGridReadiness();
    scheduledCounterUpdateTimeout.current = null;
    let total = 0;
    let totalByteSize = 0;
    const validFilenames = new Set<string>();
    const invalidFilenames = new Set<string>();
    api.forEachNode(({ data }) => {
      if (
        (data?.type === 'multi-channel' || data?.type === 'single-channel') &&
        data?.slideFile
      ) {
        total++;
        totalByteSize += data.slideFile.size;
        const isValidRow = isValidRowDatum(data, api, stainings);
        if (isValidRow) {
          validFilenames.add(data.slideFile.filename);
        } else {
          invalidFilenames.add(data.slideFile.filename);
        }
      }
    });
    updateValidCount({
      total,
      totalByteSize,
      validFilenames,
      invalidFilenames,
    });
  }, [assertGridReadiness, updateValidCount, stainings]);

  const scheduleUpdateCounters = useCallback(() => {
    if (!scheduledCounterUpdateTimeout.current) {
      scheduledCounterUpdateTimeout.current = window.setTimeout(() => {
        updateCounters();
      }, 0);
    }
  }, [updateCounters]);

  // whenever updateCounters reference change we need to update timeout
  // if it's set
  useEffect(() => {
    if (scheduledCounterUpdateTimeout.current) {
      window.clearTimeout(scheduledCounterUpdateTimeout.current);
      scheduledCounterUpdateTimeout.current = window.setTimeout(() => {
        updateCounters();
      }, 0);
    }
  }, [updateCounters]);

  useEffect(
    () => () => {
      if (scheduledCounterUpdateTimeout.current) {
        window.clearTimeout(scheduledCounterUpdateTimeout.current);
      }
    },
    []
  );

  const resetPinnedRow = useCallback(() => {
    setPinnedTopRowData(GEN_PINNED_ROW_TEMPLATE());
  }, []);

  const handleCellEditingStopped: (
    event: CellEditingStoppedEvent<GridData>
  ) => void = useCallback(
    ({ data, colDef, api }) => {
      resetPinnedRow();

      if (data!.isHeaderRow) {
        const column = colDef.field as keyof GridData;

        if (column) {
          const list: GridData[] = [];
          api.forEachNode((x) => {
            if (
              x.data &&
              (x.data.type === 'multi-channel' ||
                x.data.type === 'single-channel')
            ) {
              if (x.data.type === 'multi-channel' && column === 'staining') {
                // In MultiChannel, we don't want to allow editing the staining, since Channels will have their own staining
                return;
              } else {
                list.push({ ...x.data, [column]: data![column] });
              }
            }
          });
          api.applyTransaction({ update: list });
          scheduleUpdateCounters();
        }
      }
    },
    [resetPinnedRow, scheduleUpdateCounters]
  );

  const context: MetadataGridContext = useMemo(
    () => ({
      apiUrl,
      authToken,
      organizationUuid,
      deleteSlide: (slideRowData) => {
        const api = assertGridReadiness();
        let rowCount = 0;
        api.forEachNode(() => {
          rowCount++;
        });
        if (rowCount === 1) {
          addSnackbar({
            type: 'warning',
            message: "Can't remove last slide file",
          });
        } else {
          if (slideRowData.type === 'multi-channel') {
            const multiChannelRow = api.getRowNode(slideRowData.rowId);
            const channels = multiChannelRow?.allLeafChildren?.map((leaf) => {
              return leaf.data;
            }) as GridData[];
            if (channels && channels.length > 0) {
              api.applyTransaction({ remove: channels });
            }
          }
          if (
            slideRowData.type === 'single-channel' ||
            slideRowData.type === 'multi-channel'
          ) {
            api.applyTransaction({ remove: [slideRowData] });
            if (slideRowData.slideFile) {
              addSnackbar({
                type: 'success',
                message: `Slide ${slideRowData.slideFile.filename} removed`,
              });
            }
            scheduleUpdateCounters();
          }
        }
      },
    }),
    [
      apiUrl,
      authToken,
      organizationUuid,
      addSnackbar,
      assertGridReadiness,
      scheduleUpdateCounters,
    ]
  );

  const [pinnedTopRowData, setPinnedTopRowData] = useState<GridData[]>(
    GEN_PINNED_ROW_TEMPLATE()
  );

  const handleGridReady: (event: GridReadyEvent<GridData>) => void =
    useCallback(
      ({ api }) => {
        setGridIsReady(true);
        scheduleUpdateCounters();
        api.autoSizeAllColumns();
        onGridReady(); // signal to parent component that grid is ready
      },
      [onGridReady, scheduleUpdateCounters]
    );

  useImperativeHandle(
    ref,
    () => ({
      getValues: () => {
        const result: GridData[] = [];
        const api = assertGridReadiness();

        api.forEachNode((rowNode) => {
          if (rowNode.data) {
            result.push(rowNode.data);
          }
        });

        return result;
      },

      processCsvEntries: (parserResult) => {
        const api = assertGridReadiness();
        const updatedResults = parserResult.data.map((entry, index) => {
          const gridRowDataType = api.getRowNode(entry.Filename)!.data?.type;
          const gridRowData = api.getRowNode(entry.Filename)!.data!;
          if (gridRowDataType === undefined) {
            return {
              ...gridRowData,
            };
          }
          if (gridRowDataType === 'channel') {
            return {
              ...gridRowData,
            };
          }

          return {
            ...gridRowData,
            staining: entry.Staining,
            scannerId: entry.scannerId,
            tissue: entry.Localization,
            patientExternalId: entry['Patient ID'],
            block: entry.Block,
            section: entry.Section,
            caseId: entry['Case ID'],
            disease: entry.Disease,
            samplePreparation: entry['Preparation Type'],
            sampleType: entry['Sample Type'],
            morphology: entry.Morphology,
            cancerSite: entry['Cancer Site'],
            csvLineMapping: parserResult.csvLineMapping[index] ?? undefined,
            parentTmaRow: entry.parent_tma_row,
            parentTmaCol: entry.parent_tma_col,
            parentSlidePosX: entry.parent_slide_pos_x,
            parentSlidePosY: entry.parent_slide_pos_y,
            parentWsiUuid: entry.parent_wsi_uuid,
            caseUuid: entry.case_uuid,
            wsiUuid: entry.wsi_uuid,
          };
        });
        api.applyTransaction({
          update: updatedResults,
        });
        api.refreshCells({
          force: true,
          suppressFlash: true,
          columns: [slideNameColId],
        });
        api.autoSizeAllColumns();
        scheduleUpdateCounters();
      },

      reset() {
        const api = assertGridReadiness();
        addSnackbar({ type: 'info', message: 'Resetting to initial state' });
        const existingRowData: GridData[] = [];
        api.forEachNode(({ data }) => {
          if (data) {
            existingRowData.push(data);
          }
        });
        api.applyTransaction({
          remove: existingRowData,
        });
        api.applyTransaction({
          add: generateInitialRowData(),
        });
        api.refreshCells({
          force: true,
          suppressFlash: true,
        });
        scheduleUpdateCounters();
      },
    }),
    [
      addSnackbar,
      assertGridReadiness,
      generateInitialRowData,
      scheduleUpdateCounters,
    ]
  );

  const cellSelection = useMemo<GridOptions['cellSelection']>(
    () => ({
      suppressMultiRanges: true,
      handle: {
        mode: 'fill',
        suppressClearOnFillReduction: true,
        direction: 'y',
      },
    }),
    []
  );

  const stainingValues = useMemo(
    () => stainings.map((staining) => staining.name),
    [stainings]
  );
  const scannerValues = useMemo(
    () => scanners.map((scanner) => scanner.id),
    [scanners]
  );
  const localizationValues = useMemo(
    () => tissues.map((tissue) => tissue.name),
    [tissues]
  );
  const diseaseValues = useMemo(
    () => diseases.map((disease) => disease.name),
    [diseases]
  );
  const preparationTypeValues = useMemo(
    () => preparationTypes.map((preparationType) => preparationType.name),
    [preparationTypes]
  );
  const sampleTypeValues = useMemo(
    () => sampleTypes.map((sampleType) => sampleType.name),
    [sampleTypes]
  );
  const morphologyValues = useMemo(
    () => morphologies.map((morphology) => morphology.code),
    [morphologies]
  );
  const cancerSiteValues = useMemo(
    () => cancerSites.map((cancerSite) => cancerSite.name),
    [cancerSites]
  );

  const autoGroupColumnDef = {
    headerName: 'Slide Name',
    colId: slideNameColId,
    autoHeight: true,
    minWidth: 300,
    cellRenderer: 'agGroupCellRenderer',
    cellRendererParams: {
      suppressCount: true,
      innerRenderer: (params: ICellRendererParams<GridData>) => {
        if (
          params.data &&
          (params.data.type === 'single-channel' ||
            params.data.type === 'multi-channel')
        ) {
          const api = assertGridReadiness();
          const isValid = isValidRowDatum(params.data, api, stainings);
          return <CustomCellSlideName {...params} isValid={isValid} />;
        }
        return null;
      },
      context: {
        organizationUuid,
        apiUrl,
        authToken,
      },
    },
    editable: false,
    resizable: true,
  };

  const scannerColumnDef: ColDef<GridData, GridData['scannerId']> = useMemo(
    () =>
      generateSelectColDef('scannerId', {
        headerName: 'Scanner *',
        collection: scanners,
        collectionIdColumn: 'id',
        collectionValues: scannerValues,
        renderCollectionItem: renderScanner,
      }),
    [scanners, scannerValues]
  );

  const localizationColumnDef: ColDef<GridData, GridData['tissue']> = useMemo(
    () =>
      generateSelectColDef('tissue', {
        headerName: 'Localization *',
        collection: tissues,
        collectionIdColumn: 'name',
        collectionValues: localizationValues,
        renderCollectionItem: renderLocalization,
      }),
    [tissues, localizationValues]
  );

  const patientIdColumnDef: ColDef<GridData, GridData['patientExternalId']> =
    useMemo(
      () =>
        generateTextColDef('patientExternalId', { headerName: 'Patient ID' }),
      []
    );

  const caseIdColumnDef: ColDef<GridData, GridData['caseId']> = useMemo(
    () => generateTextColDef('caseId', { headerName: 'Case ID *' }),
    []
  );

  const blockColumnDef: ColDef<GridData, GridData['block']> = useMemo(
    () => generateTextColDef('block', { headerName: 'Block' }),
    []
  );

  const sectionColumnDef: ColDef<GridData, GridData['section']> = useMemo(
    () => generateTextColDef('section', { headerName: 'Section' }),

    []
  );

  const diseaseColumnDef: ColDef<GridData, GridData['disease']> = useMemo(
    () =>
      generateSelectColDef('disease', {
        headerName: 'Disease *',
        collection: diseases,
        collectionIdColumn: 'name',
        collectionValues: diseaseValues,
        renderCollectionItem: renderDisease,
      }),
    [diseases, diseaseValues]
  );

  const preparationTypeColumnDef: ColDef<
    GridData,
    GridData['samplePreparation']
  > = useMemo(
    () =>
      generateSelectColDef('samplePreparation', {
        headerName: 'Preparation Type *',
        collection: preparationTypes,
        collectionIdColumn: 'name',
        collectionValues: preparationTypeValues,
        renderCollectionItem: renderPreparationType,
      }),
    [preparationTypes, preparationTypeValues]
  );

  const sampleTypeColumnDef: ColDef<
    GridData,
    SingleChannelFormRow['sampleType']
  > = useMemo(
    () =>
      generateSelectColDef('sampleType', {
        headerName: 'Sample Type *',
        collection: sampleTypes,
        collectionIdColumn: 'name',
        collectionValues: sampleTypeValues,
        renderCollectionItem: renderSampleType,
      }),
    [sampleTypes, sampleTypeValues]
  );

  const morphologyColumnDef: ColDef<GridData, GridData['morphology']> = useMemo(
    () =>
      generateSelectColDef('morphology', {
        headerName: 'Morphology *',
        collection: morphologies,
        collectionIdColumn: 'code',
        collectionValues: morphologyValues,
        renderCollectionItem: renderMorphology,
      }),
    [morphologies, morphologyValues]
  );

  const cancerSiteColumnDef: ColDef<GridData, GridData['cancerSite']> = useMemo(
    () =>
      generateSelectColDef('cancerSite', {
        headerName: 'Cancer Site *',
        collection: cancerSites,
        collectionIdColumn: 'name',
        collectionValues: cancerSiteValues,
        renderCollectionItem: renderCancerSite,
      }),
    [cancerSites, cancerSiteValues]
  );

  const columnDefs: ColDef<GridData>[] = [
    {
      headerName: 'Staining*',
      field: 'staining',
      cellEditorSelector: () => {
        return {
          component: 'agRichSelectCellEditor',
          params: {
            values: stainingValues,
            formatValue: (value: string | null | undefined) =>
              collectionValueFormatter(
                stainings,
                'name',
                renderStaining,
                value
              ),
            allowTyping: true,
            searchType: 'matchAny',
            filterList: true,
            highlightMatch: true,
            valueListMaxHeight: 220,
          },
        };
      },
      editable: (params: EditableCallbackParams<GridData>) => {
        if (params.node?.data?.type === 'multi-channel') {
          return false;
        }
        return true;
      },
      valueGetter: (params) => {
        if (params.data?.type === 'channel') {
          return params.data.selectedOption || '';
        } else {
          return params.data?.staining;
        }
      },
      valueSetter: (params) => {
        if (params.data?.type === 'channel') {
          params.data.selectedOption = params.newValue;
        } else {
          params.data.staining = params.newValue;
        }
        return true;
      },
      cellRenderer: (params: ICellRendererParams<GridData>) => {
        const dataType = params.data?.type;
        const isMultiOrSingle =
          dataType === 'channel' || dataType === 'single-channel';

        return (
          <CustomCellSelectValue
            {...params}
            checkValidValue={(value: string) => {
              if (isMultiOrSingle) {
                return stainings.some((x) => x.name === value);
              }
              return true;
            }}
          />
        );
      },
      resizable: true,
    },

    scannerColumnDef,
    localizationColumnDef,
    patientIdColumnDef,
    caseIdColumnDef,
    blockColumnDef,
    sectionColumnDef,
    diseaseColumnDef,
    preparationTypeColumnDef,
    sampleTypeColumnDef,
    morphologyColumnDef,
    cancerSiteColumnDef,
    {
      headerName: 'Actions',
      cellRenderer: (params: ICellRendererParams<GridData>) => {
        if (params.data && params.data.type !== 'channel') {
          return <CustomCellDeleteSlide {...params} />;
        }
        return null;
      },
      editable: false,
      sortable: false,
      filter: false,
      minWidth: 100,
      suppressHeaderMenuButton: true,
      flex: 1,
    },
  ];

  const getDataPath = (data: GridData) => {
    return data.path ?? [];
  };

  const getRowClass: (params: RowClassParams<GridData>) => string = useCallback(
    ({ data }) => (data?.isHeaderRow ? 'ag-row-header' : ''),
    []
  );

  return (
    <$GridWrapper>
      <AgGridReact<GridData>
        treeData={true}
        autoGroupColumnDef={autoGroupColumnDef}
        getDataPath={getDataPath}
        ref={gridRef}
        rowData={rowData}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        pinnedTopRowData={pinnedTopRowData}
        cellSelection={cellSelection}
        copyGroupHeadersToClipboard
        onCellValueChanged={scheduleUpdateCounters}
        onCellEditingStopped={handleCellEditingStopped}
        onGridReady={handleGridReady}
        context={context}
        getRowId={getRowId}
        getRowClass={getRowClass}
        modules={GRID_MODULES}
        suppressColumnVirtualisation // so autosizing works for every column
        suppressRowVirtualisation={supressAgGridVirtualization}
        theme={gridTheme}
      />
    </$GridWrapper>
  );
});
