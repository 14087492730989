import {
  Button,
  Filter,
  FilterConfigs,
  FilterField,
  Pagination,
  PaginationInfo,
  getFiltersFromQueryParams,
  getPageFromQueryParams,
  useDisclosure,
  useFilters,
  usePagination,
  useSnackbarMutations,
} from '@aignostics/components';
import { useSetQueryParams } from '@aignostics/hooks';
import { pluralize } from '@aignostics/utils';
import { useMutation, useQuery } from '@apollo/client';
import React, { ReactElement, useEffect, useMemo } from 'react';
import { SortBy, SortByDirection, useSortBy } from '../../../hooks';
import {
  AnnotationCategorySet,
  AnnotationCategorySetSortByOptions,
} from '../../../types';
import { getQueryParams } from '../../../utils';
import {
  $FiltersWrapper,
  $PageHeaderWrapper,
  $PaginationContainer,
} from '../AnnotationManagement.component.style';
import { FETCH_ANNOTATION_CATEGORIES_FILTER_DATA } from '../Categories/FETCH_ANNOTATION_CATEGORIES_FILTER_DATA';
import { useCurrentAnnotationCategoriesQueryInformation } from '../hooks/useCurrentAnnotationCategoriesQueryInformation';
import { AnnotationCategorySetsTable } from './AnnotationCategorySetsTable.component';
import { CREATE_ANNOTATION_CATEGORY_SET } from './CreateAnnotationCategorySetModal/CREATE_ANNOTATION_CATEGORY_SET';
import { CreateAnnotationCategorySetModal } from './CreateAnnotationCategorySetModal/CreateAnnotationCategorySetModal.component';
import { FETCH_ANNOTATION_CATEGORY_SETS } from './FETCH_ANNOTATION_CATEGORY_SETS';

export const PAGE_SIZE = 20;
type FilterKeys = 'search';
const PAGE_FILTER_CONFIGS: FilterConfigs<FilterKeys> = {
  search: { fallbackValue: '', type: 'string' },
};

export const AnnotationCategorySets = (): ReactElement => {
  const [page, setPage] = usePagination(getPageFromQueryParams());
  const urlParams = getQueryParams(['sortBy', 'sortDirection']);
  const sortByParam = urlParams?.sortBy as AnnotationCategorySetSortByOptions;
  const sortDirectionParam = urlParams?.sortDirection as SortByDirection;

  const { sortBy, setSortByOptions } =
    useSortBy<AnnotationCategorySetSortByOptions>({
      column: sortByParam ?? 'name',
      sortDirection: sortDirectionParam || 'asc',
    });

  const { filters, filterProps } = useFilters(
    PAGE_FILTER_CONFIGS,
    getFiltersFromQueryParams(PAGE_FILTER_CONFIGS)
  );

  const PAGE_FILTER_FIELDS = {
    search: {
      icon: 'Search',
      type: 'search',
      label: 'Search',
      value: '',
      placeholder: 'Search Sets & Modules',
    },
  } as Record<FilterKeys, FilterField>;

  const queryParams = useMemo(
    () => ({
      page: page.toString(),
      ...filters,
      ...(sortBy?.column ? { sortBy: sortBy.column } : {}),
      ...(sortBy?.sortDirection ? { sortDirection: sortBy.sortDirection } : {}),
    }),
    [page, sortBy?.sortDirection, sortBy?.column, filters]
  );
  useSetQueryParams(queryParams);

  const variables = useMemo(
    () => ({
      page,
      pageSize: PAGE_SIZE,
      sortBy: sortBy?.column,
      sortDirection: sortBy?.sortDirection,
      search: filters.search as string,
    }),
    [page, sortBy, filters.search]
  );

  const { setAnnotationCategorySetsQueryVariables } =
    useCurrentAnnotationCategoriesQueryInformation();

  useEffect(() => {
    if (variables) setAnnotationCategorySetsQueryVariables(variables);
  }, [variables, setAnnotationCategorySetsQueryVariables]);

  const { data, loading } = useQuery<{
    annotationCategorySets: {
      nodes: AnnotationCategorySet[];
      pageInfo: {
        totalPages: number;
        totalElements: number;
      };
      collectionAttributes: {
        allAnnotationCategorySetNames: string[];
        allAnnotationCategorySetCodes: string[];
      };
    };
  }>(FETCH_ANNOTATION_CATEGORY_SETS, {
    variables,
  });

  const annotationCategorySets = data?.annotationCategorySets.nodes ?? [];
  const pageInfo = data?.annotationCategorySets.pageInfo;

  const createAnnotationCategorySetDisclosure = useDisclosure();

  const [createAnnotationCategorySet] = useMutation(
    CREATE_ANNOTATION_CATEGORY_SET,
    {
      refetchQueries: [
        {
          query: FETCH_ANNOTATION_CATEGORY_SETS,
          variables,
        },
        { query: FETCH_ANNOTATION_CATEGORIES_FILTER_DATA },
      ],
    }
  );

  const { addSnackbar } = useSnackbarMutations();

  const handleCreateCategory = async (
    annotationCategorySet: AnnotationCategorySet
  ) => {
    await createAnnotationCategorySet({
      variables: {
        annotationCategorySet,
      },
      onCompleted: () => {
        addSnackbar({
          type: 'success',
          message: `Annotation category set ${annotationCategorySet.name} was created successfully.`,
        });
        createAnnotationCategorySetDisclosure.close();
      },
      onError: (error) => {
        addSnackbar({
          type: 'error',
          message: error.message,
        });
      },
    });
  };

  const annotationCategorySetCodes =
    data?.annotationCategorySets.collectionAttributes
      .allAnnotationCategorySetCodes ?? [];

  const annotationCategorySetNames =
    data?.annotationCategorySets.collectionAttributes
      .allAnnotationCategorySetNames ?? [];

  return (
    <>
      <$FiltersWrapper>
        <Filter
          isOpen={false}
          fields={PAGE_FILTER_FIELDS}
          {...filterProps}
          onChange={(filters) => {
            filterProps.onChange(filters);
            setPage(1);
          }}
          onReset={() => {
            filterProps.onReset();
            setPage(1);
          }}
        />
      </$FiltersWrapper>
      <$PageHeaderWrapper>
        <PaginationInfo
          currentPage={page}
          itemsPerPage={PAGE_SIZE}
          totalCount={pageInfo?.totalElements ?? 0}
          itemType={pluralize('Set', annotationCategorySets.length)}
          loading={loading}
        />
        <Button
          onClick={createAnnotationCategorySetDisclosure.open}
          name="Create Set"
          small
          icon="PlusCircle"
        >
          Create Set
        </Button>
      </$PageHeaderWrapper>
      <AnnotationCategorySetsTable
        loading={loading}
        annotationCategorySets={annotationCategorySets}
        sortBy={sortBy}
        setSortByOptions={(
          sortBy: SortBy<AnnotationCategorySetSortByOptions>
        ) => {
          setSortByOptions(
            sortBy?.column as AnnotationCategorySetSortByOptions,
            sortBy?.sortDirection as SortByDirection
          );
        }}
        annotationCategorySetCodes={annotationCategorySetCodes}
        annotationCategorySetNames={annotationCategorySetNames}
      />
      {pageInfo && pageInfo.totalPages > 1 && (
        <$PaginationContainer>
          <Pagination
            currentPage={page}
            onPageChanged={setPage}
            totalPages={pageInfo.totalPages}
          />
        </$PaginationContainer>
      )}
      {createAnnotationCategorySetDisclosure.isOpen && (
        <CreateAnnotationCategorySetModal
          isVisible={createAnnotationCategorySetDisclosure.isOpen}
          onClose={createAnnotationCategorySetDisclosure.close}
          annotationCategorySetCodes={annotationCategorySetCodes}
          annotationCategorySetNames={annotationCategorySetNames}
          onCreateAnnotationCategorySet={async (newAnnotationCategorySet) =>
            handleCreateCategory(newAnnotationCategorySet)
          }
        />
      )}
    </>
  );
};
