import * as Sentry from '@sentry/react';
import React, {
  ReactElement,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useReducer,
} from 'react';
import { initialState, reducer } from './OnboardingMetricsProvider.reducer';
import {
  OnboardingMetricsContextProps,
  RecordFromInputHeaderInteraction,
  RecordTransferSlidesInteraction,
} from './types';

export const OnboardingMetricsContext = createContext<
  OnboardingMetricsContextProps | undefined
>(undefined);

interface OnboardingMetricsProviderProps {
  children: ReactNode;
}

export const OnboardingMetricsProvider = ({
  children,
}: OnboardingMetricsProviderProps): ReactElement => {
  const [, dispatch] = useReducer(reducer, initialState);

  const recordTransferSlidesInteraction = useCallback(
    (attributes?: { slidesCount: number; slidesUploadType: string }): void => {
      const span = Sentry.startInactiveSpan({
        name: 'transfer_slides_click',
        op: 'ui.transfer_slides_click',
        attributes,
      });
      dispatch({ type: 'RECORD_SPAN', span });
    },
    []
  );
  const recordFromInputHeaderInteraction = useCallback(
    (attributes?: { slidesCount: number }): void => {
      const span = Sentry.startInactiveSpan({
        name: 'update_batch_form',
        op: 'ui.update_batch_form',
        attributes,
      });
      dispatch({ type: 'RECORD_SPAN', span });
    },
    []
  );

  const endRecordFromInputHeaderInteraction = useCallback(() => {
    dispatch({ type: 'END_SPAN' });
  }, []);

  const endRecordTransferSlidesInteraction = useCallback(() => {
    dispatch({ type: 'END_SPAN' });
  }, []);

  return (
    <OnboardingMetricsContext.Provider
      value={{
        recordFromInputHeaderInteraction,
        endRecordFromInputHeaderInteraction,
        recordTransferSlidesInteraction,
        endRecordTransferSlidesInteraction,
      }}
    >
      {children}
    </OnboardingMetricsContext.Provider>
  );
};

const useOnboardingMetrics = (): OnboardingMetricsContextProps => {
  const context = useContext(OnboardingMetricsContext);
  if (context === undefined) {
    throw new Error(
      'useOnboardingMetrics must be used within an OnboardingMetricsProvider'
    );
  }
  return context;
};

export const useOnboardingMetricsHook = (): {
  recordFromInputHeaderInteraction: RecordFromInputHeaderInteraction;
  endRecordFromInputHeaderInteraction: () => void;
  recordTransferSlidesInteraction: RecordTransferSlidesInteraction;
  endRecordTransferSlidesInteraction: () => void;
} => {
  const {
    recordTransferSlidesInteraction,
    endRecordFromInputHeaderInteraction,
    recordFromInputHeaderInteraction,
    endRecordTransferSlidesInteraction,
  } = useOnboardingMetrics();

  return {
    recordFromInputHeaderInteraction,
    endRecordFromInputHeaderInteraction,
    recordTransferSlidesInteraction,
    endRecordTransferSlidesInteraction,
  };
};
