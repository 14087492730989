import { UploadModal } from '@aignostics/components';
import React, { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import { ACCEPTED_FILE_TYPES } from '../../utils/ACCEPTED_FILE_TYPES';
import { useSelectedOrganizationUuid } from '../../utils/useSelectedOrganizationUuid';
interface UploadSlidesModalProps {
  isVisible: boolean;
  onClose: () => void;
}

export const compareFilenames = (a: string, b: string): number =>
  a.toLowerCase().localeCompare(b.toLowerCase(), 'en');

const UploadSlidesModal = ({
  isVisible,
  onClose,
}: UploadSlidesModalProps): ReactElement => {
  const navigate = useNavigate();
  const organizationUuid = useSelectedOrganizationUuid();
  const handleDropAccepted = (files: File[]) => {
    const csv = files.find((file) => file.type === 'text/csv');
    const images = files
      .filter((file) => file.type !== 'text/csv')
      .sort(({ name: nameA }, { name: nameB }) =>
        // sort files in a stable order
        // slides returned from backend are sorted by slide names too
        compareFilenames(nameA, nameB)
      );
    void navigate(`/${organizationUuid}/admin/data-onboarding/upload-slides`, {
      state: { files: images, csv },
    });
  };

  return (
    <UploadModal
      isVisible={isVisible}
      accept={ACCEPTED_FILE_TYPES.join(',')}
      onClose={onClose}
      title="Add your slides"
      subtitle="*CSV document is optional and can be added later"
      onDropAccepted={handleDropAccepted}
    />
  );
};

export default UploadSlidesModal;
