import { AnimatePresence } from 'framer-motion';
import React, { useState } from 'react';
import styled from 'styled-components';
import Button from '../Button';
import { $OnboardingListItemFileLine, $OnboardingReadOnlyListItemWrapper, } from './OnboardingReadOnlyListItem.styles';
const $OnboardingReadOnlyListItemMultiplexChannelsWrapper = styled.div `
  border-top: 1px solid ${({ theme }) => theme.colors.light};
  display: flex;
  width: 100%;
  padding: 16px 6px;
`;
const $ToggleChannelsVisiblityButton = styled(Button) `
  font-size: 13px;
`;
const $Channels = styled.div `
  display: grid;
  gap: 8px;
  grid-template-columns: repeat(7, minmax(0, 1fr));
  width: calc(var(--table-content-width));
  overflow: hidden;
  align-items: center;
  > * {
    max-width: 100%;
    align-items: stretch;
  }
`;
export const OnboardingReadOnlyListItemMultiChannelFile = ({ children, channels, showExtraFields, onUpdateChannelsExpanded, }) => {
    const [isChannelsOpen, setChannelsOpen] = useState(true);
    return (React.createElement($OnboardingReadOnlyListItemWrapper, null,
        React.createElement($OnboardingListItemFileLine, { showExtraFields: showExtraFields },
            React.createElement($ToggleChannelsVisiblityButton, { "aria-expanded": isChannelsOpen, onClick: (event) => {
                    event.preventDefault();
                    setChannelsOpen(!isChannelsOpen);
                    onUpdateChannelsExpanded(isChannelsOpen);
                }, variant: "link" },
                isChannelsOpen ? 'Hide' : 'Show',
                " Channels"),
            children),
        React.createElement(AnimatePresence, { initial: false }, isChannelsOpen && (React.createElement($OnboardingReadOnlyListItemMultiplexChannelsWrapper, null,
            React.createElement($Channels, { showExtraFields: showExtraFields }, channels))))));
};
