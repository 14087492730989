import { Icon, VStack, WsiPlaceholder } from '@aignostics/components';
import React, { ReactElement, useEffect, useState } from 'react';

export interface WsiThumbnailProps {
  wsiId: string;
  rasterTileServerUrl: string;
  getToken: () => Promise<string>;
  size?: 'large' | 'small';
}

type ThumbnailState =
  | { state: 'idle' | 'loading' }
  | { state: 'error'; error: unknown }
  | { state: 'success'; url: string };

function isAbortError(error: unknown): error is DOMException {
  return error instanceof DOMException && error.name === 'AbortError';
}

export function WsiThumbnail({
  wsiId,
  rasterTileServerUrl,
  getToken,
  size = 'large',
}: WsiThumbnailProps): ReactElement {
  const [thumbnailState, setThumbnailState] = useState<ThumbnailState>({
    state: 'idle',
  });

  useEffect(() => {
    let isMounted = true;
    let imageUrl: string | null = null;
    const controller = new AbortController();
    const { signal } = controller;

    const fetchThumbnail = async () => {
      setThumbnailState({ state: 'loading' });
      try {
        const token = await getToken();
        const response = await fetch(
          `${rasterTileServerUrl}/thumbnail/wsi/${wsiId}`,
          {
            headers: { authorization: `Bearer ${token}` },
            signal,
          }
        );

        if (!response.ok) {
          throw new Error(`Failed to fetch thumbnail: ${response.statusText}`);
        }

        const blob = await response.blob();
        imageUrl = URL.createObjectURL(blob);

        if (isMounted) {
          setThumbnailState({ state: 'success', url: imageUrl });
        } else {
          URL.revokeObjectURL(imageUrl);
        }
      } catch (error) {
        if (isMounted && !isAbortError(error)) {
          setThumbnailState({ state: 'error', error });
        }
      }
    };

    void fetchThumbnail();

    return () => {
      isMounted = false;
      controller.abort();
      if (imageUrl) {
        URL.revokeObjectURL(imageUrl);
      }
    };
  }, [getToken, rasterTileServerUrl, wsiId]);

  if (thumbnailState.state === 'error') {
    return (
      <VStack
        alignItems="center"
        spacing="8"
        justifyContent="center"
        style={size === 'small' ? { height: '48px' } : {}}
      >
        <Icon icon="AlertTriangle" size={size} />
        {size === 'large' ? <span>Failed to load thumbnail</span> : null}
      </VStack>
    );
  }

  if (thumbnailState.state !== 'success') {
    return (
      <WsiPlaceholder
        state={thumbnailState.state}
        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
      />
    );
  }

  return (
    <img
      src={thumbnailState.url}
      alt={`Preview of whole slide image ${wsiId}`}
      loading="lazy"
      style={{
        width: '100%',
        height: '100%',
        objectFit: 'cover',
      }}
    />
  );
}
