import { isEqual } from 'lodash';
import { useMemo, useState } from 'react';

export interface SpreadFilterProps<Keys extends string> {
  value: Record<Keys, string | string[]>;
  onChange: (filters: Record<Keys, string | string[]>) => void;
  onReset: () => void;
  isDefault: boolean;
}

export type FilterConfig =
  | {
      type: 'array';
      fallbackValue: string[];
    }
  | {
      type: 'string';
      fallbackValue: string;
    };

export type FilterConfigs<Keys extends string> = Record<Keys, FilterConfig>;

/**
 * A hook to be used in conjunction with `Filter`.
 *
 * @returns an object with `filterProps` to pass on to a Filter component, and `filters` with the actual values.
 */
export function useFilters<Keys extends string>(
  filterConfigs: FilterConfigs<Keys>,
  initialFilters?: Record<Keys, string | string[]>
): {
  filterProps: SpreadFilterProps<Keys>;
  filters: Record<Keys, string | string[]>;
} {
  const fallbackFilters = useMemo(
    () =>
      Object.entries(filterConfigs).reduce<Record<Keys, string | string[]>>(
        (result, [key, value]) => {
          result[key as Keys] = (value as FilterConfig).fallbackValue;

          return result;
        },
        {} as Record<Keys, string | string[]>
      ),
    [filterConfigs]
  );

  const [filters, setFilters] = useState<Record<Keys, string | string[]>>(
    initialFilters ?? fallbackFilters
  );

  const filterProps: SpreadFilterProps<Keys> = useMemo(
    () => ({
      value: filters,
      onChange: (filters) => {
        setFilters(filters);
      },
      onReset: () => {
        setFilters(fallbackFilters);
      },
      isDefault: isEqual(filters, fallbackFilters),
    }),
    [fallbackFilters, filters]
  );

  return { filters, filterProps };
}
