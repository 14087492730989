import {
  Link,
  SortableTableHeader,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Toggle,
} from '@aignostics/components';
import { formatDate } from '@aignostics/utils';
import React from 'react';
import { CSSProperties } from 'styled-components';
import { SortBy, getSortDirection } from '../../../hooks/useSortBy';
import { Project } from '../../../types';
import { SortByOptions } from './Admin.Project.List.component';
import { useSetProjectVisibility } from './Details/useSetProjectVisibility';

type TableHeaders = {
  name: string;
  sortingBy?: SortByOptions;
  width?: CSSProperties['width'];
};

const TABLE_HEADERS: TableHeaders[] = [
  { name: 'Name', sortingBy: 'name', width: '35ch' },
  { name: 'Subprojects', sortingBy: 'subprojectsCount', width: '5ch' },
  { name: 'Users', sortingBy: 'usersCount', width: '5ch' },
  { name: 'Created By', sortingBy: 'createdBy', width: '12ch' },
  { name: 'Created At', sortingBy: 'createdAt', width: '15ch' },
  { name: 'Updated At', sortingBy: 'updatedAt', width: '15ch' },
  { name: 'Is Visible', sortingBy: 'isVisible', width: '5ch' },
];

export function ProjectsTable({
  projects,
  setSortBy,
  sortBy,
  loading,
}: {
  projects: Project[];
  setSortBy: (sortByOption: SortByOptions) => void;
  sortBy: SortBy<SortByOptions> | null;
  loading: boolean;
}): React.ReactElement {
  const setProjectVisibility = useSetProjectVisibility();

  const onChangeProjectVisibility = (project: string, value: boolean) => {
    void setProjectVisibility(project, value);
  };

  const onSortingIconClick = (sortingBy: SortByOptions | undefined) => {
    if (sortingBy) setSortBy(sortingBy);
  };

  return (
    <Table>
      <TableHead>
        <TableRow>
          {TABLE_HEADERS.map((header) => {
            return (
              <SortableTableHeader
                fixedWidth={header.width}
                key={header.name}
                sortDirection={getSortDirection(sortBy, header.sortingBy)}
                onClick={() => {
                  onSortingIconClick(header.sortingBy);
                }}
              >
                {header.name}
              </SortableTableHeader>
            );
          })}
        </TableRow>
      </TableHead>
      {!loading && projects && projects.length > 0 ? (
        <TableBody>
          {projects.map((project) => {
            return (
              <TableRow key={project.id}>
                <TableCell tooltipText={project.name}>
                  <Link href={project.id}>{project.name}</Link>
                </TableCell>
                <TableCell tooltipText={project.subProjectsCount.toString()}>
                  {project.subProjectsCount}
                </TableCell>
                <TableCell>{project.usersCount}</TableCell>
                <TableCell>
                  {project.createdBy?.name ||
                    project.createdBy?.email ||
                    'unknown'}
                </TableCell>
                <TableCell>{formatDate(project.createdAt)}</TableCell>
                <TableCell>{formatDate(project.updatedAt)}</TableCell>
                <TableCell>
                  <Toggle
                    componentTheme="transparent"
                    active={project.isVisible}
                    onChange={(value: boolean) => {
                      onChangeProjectVisibility(project.id, value);
                    }}
                    label={`Toggle ${project.id}`}
                  />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      ) : null}
    </Table>
  );
}
