import { motion } from 'framer-motion';
import { Link as RouterLink } from 'react-router-dom';
import styled from 'styled-components';

export const $CountWrapper = styled.div`
  padding: ${({ theme }) => `${theme.spacings[12]}px ${theme.spacings[16]}px`};
  display: flex;
`;
export const $MenuSidebar = styled(motion.nav)`
  margin-top: ${({ theme }) => theme.spacings.button}px;
  position: absolute;
  z-index: 10;
  display: flex;
  flex-direction: column;
  top: 0;
  left: 0;
  width: ${({ theme }) => theme.spacings.aside}px;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.text};
  color: ${({ theme }) => theme.colors.white};
`;

export const $MenuList = styled.ul`
  overflow: auto;
  background: ${({ theme }) => theme.colors.dark};
`;

export const $Leaf = styled(motion(RouterLink))`
  position: relative;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  height: ${({ theme }) => theme.spacings.touch}px;
  white-space: nowrap;
  padding: 0 ${({ theme }) => theme.spacings.base}px;
  text-decoration: none;
  &,
  &:visited {
    color: ${({ theme }) => theme.colors.light};
  }
`;
