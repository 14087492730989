import React from 'react';
import { useField } from 'react-final-form';
import { $FinalFormFieldWrapper, pickValidator, } from '../utils/finalForm/common';
import { SelectCreatable } from './SelectCreatable.component';
import { constructInvalidSelectOption } from './SelectSingle.component';
const FINAL_FORM_SELECT_SINGLE_METADATA = {
    fieldType: 'select',
};
export const FinalFormSelectCreatable = ({ name, label, options, required = false, validate, style, showErrorMessage = true, onChangeListener, disabled, isClearable, extraMessage, externalAsyncValidateFn = () => true, // since validate function can be only synchronous
 }) => {
    const { input, meta } = useField(name, {
        allowNull: true,
        validate: pickValidator(required, validate),
        format: (value) => {
            const result = options.find((option) => option.value === value);
            if (result === undefined) {
                return constructInvalidSelectOption(value);
            }
            return result;
        },
        data: FINAL_FORM_SELECT_SINGLE_METADATA,
    });
    return (React.createElement($FinalFormFieldWrapper, { style: style },
        React.createElement(SelectCreatable, { id: name, onChange: (newValue) => {
                input.onChange(newValue !== null ? newValue.value : null);
                if (onChangeListener)
                    onChangeListener(newValue?.value);
            }, label: label, value: input.value?.value ?? null, allowInvalidValue: true, options: options, disabled: disabled, isClearable: isClearable, required: required, onFocus: () => {
                input.onFocus();
            }, onBlur: input.onBlur, isInvalid: meta.touched &&
                showErrorMessage &&
                (meta.error || !externalAsyncValidateFn()) }),
        React.createElement("p", { style: {
                fontSize: '12px',
                paddingTop: '5px',
                color: meta.error || !externalAsyncValidateFn() ? '#CB0000' : 'inherit',
            } }, meta.touched && (extraMessage || meta.error))));
};
