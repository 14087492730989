import React from 'react';
import { Checkbox } from '../../../Checkbox';
import { RadioButton } from '../../../RadioButton';
import { HeaderArrowIcon, HeaderIconWrapper, RadioButtonWrapper, SelectAllWrapper, SelectMenuWrapper, } from '../../TableComponent.styles';
import SelectMenu from '../SelectMenu/SelectMenu.component';
const SelectAllCheckbox = ({ onSelectChange, isAllSelected, checked, partialCheck, }) => {
    const checkedState = isAllSelected || checked ? true : partialCheck ? 'indeterminate' : false;
    return (React.createElement(SelectMenu, { menuItems: React.createElement(SelectMenuWrapper, null,
            React.createElement(RadioButtonWrapper, { role: "button", "aria-label": "Select all in this page", onClick: () => {
                    onSelectChange('page');
                } },
                React.createElement(RadioButton, { id: "selectPage", label: "Select all in this page", name: "selectPage", checked: checked && !isAllSelected, value: "selectPage", labelPosition: 'right', small: true })),
            React.createElement(RadioButtonWrapper, { role: "button", "aria-label": "Select all in all pages", onClick: () => {
                    onSelectChange('all');
                } },
                React.createElement(RadioButton, { id: "selectAll", label: "Select all in all pages", name: "selectAll", checked: isAllSelected, value: "selectAll", labelPosition: 'right', small: true })),
            React.createElement(RadioButtonWrapper, { role: "button", "aria-label": "None", onClick: () => {
                    onSelectChange('none');
                } },
                React.createElement(RadioButton, { id: "selectNone", label: "None", name: "None", checked: !partialCheck && !checked && !isAllSelected, value: "None", labelPosition: 'right', small: true }))) },
        React.createElement(SelectAllWrapper, null,
            React.createElement(Checkbox, { "data-testid": "select-all-checkbox", checked: checkedState, onCheckedChange: (newChecked) => {
                    if (newChecked === true) {
                        onSelectChange('page');
                    }
                    else if (newChecked === false) {
                        onSelectChange('none');
                    }
                }, onClick: (e) => {
                    e.stopPropagation();
                } }),
            React.createElement(HeaderIconWrapper, { "data-testid": "select-all-checkbox-menu" },
                React.createElement(HeaderArrowIcon, { icon: 'ArrowDown', size: 'xsmall' })))));
};
export default SelectAllCheckbox;
