import React from 'react';
import { useTheme } from 'styled-components';
import { FormLabel } from '../FormLabel';
import Icon from '../Icon';
import { RadioButton, } from '../RadioButton/RadioButton.component';
import { HStack, VStack } from '../Stack';
export const RadioButtonWithLegend = ({ id, checked, onChange, icon, label, legend, disabled, ...props }) => {
    const theme = useTheme();
    return (React.createElement(HStack, { onClick: () => {
            if (!disabled && onChange)
                onChange(!checked);
        }, style: {
            alignItems: 'center',
            justifyContent: 'space-between',
        } },
        React.createElement(HStack, { style: { alignItems: 'center', gap: '12px' } },
            React.createElement(RadioButton, { id: id, checked: checked, ...props }),
            React.createElement(VStack, { spacing: "tiny" },
                label && React.createElement(FormLabel, { htmlFor: id }, label),
                legend && React.createElement("div", { style: { ...theme.fontStyles.small } }, legend))),
        icon && React.createElement(Icon, { style: { marginLeft: 12 }, icon: icon })));
};
