import { AdvancedFilterModule, CustomFilterModule, DateFilterModule, ExternalFilterModule, GridStateModule, LicenseManager, ModuleRegistry, MultiFilterModule, NumberFilterModule, PaginationModule, QuickFilterModule, ServerSideRowModelModule, SetFilterModule, TextFilterModule, themeQuartz, TooltipModule, } from 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import React, { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { DefaultLoadingRenderer } from './DefaultLoadingRenderer';
export const AG_GRID_LICENSE_KEY = 'Using_this_{AG_Grid}_Enterprise_key_{AG-074620}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Aignostics_GmbH}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{aignostics}_only_for_{9}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_{aignostics}_need_to_be_licensed___{aignostics}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{8_January_2026}____[v3]_[01]_MTc2NzgzMDQwMDAwMA==675428b68e91c883c23f04bab9ecd4f5';
ModuleRegistry.registerModules([
    TextFilterModule,
    NumberFilterModule,
    DateFilterModule,
    CustomFilterModule,
    ExternalFilterModule,
    QuickFilterModule,
    SetFilterModule,
    MultiFilterModule,
    AdvancedFilterModule,
    ServerSideRowModelModule,
    TooltipModule,
    PaginationModule,
    GridStateModule,
]);
LicenseManager.setLicenseKey(AG_GRID_LICENSE_KEY);
export const defaultTableTheme = themeQuartz.withParams({
    accentColor: '#9B95EC',
    browserColorScheme: 'light',
    fontFamily: 'inherit',
    foregroundColor: '#483569',
    headerBackgroundColor: '#ECEBEF',
    headerFontSize: 14,
    headerFontWeight: 800,
});
function onStateUpdated(event, persistParamsInUrl, setSearchParams) {
    if (!persistParamsInUrl)
        return;
    updateUrlParams(event.state, setSearchParams);
}
function updateUrlParams(state, setSearchParams) {
    const cleanState = Object.fromEntries(Object.entries(state).filter((arr) => {
        return arr[1] !== undefined;
    }));
    setSearchParams((prevParams) => {
        const newParams = new URLSearchParams(prevParams);
        newParams.set('gridState', JSON.stringify(cleanState));
        return newParams;
    });
}
/**
 * High-level Table component providing good defaults:
 * - Default theme
 * - Pagination enabled
 * - Default column definition
 * - Default loading state
 * - Easily override any of these via props
 */
export function Table({ loadingCellRenderer, loadingCellRendererParams, persistParamsInUrl = true, defaultPageSize = 20, pageSizeOptions = [20, 50, 100], defaultColDef: userDefaultColDef, rowModelType = 'serverSide', pagination = true, paginationPageSize: userPaginationPageSize, paginationPageSizeSelector = pageSizeOptions, suppressCellFocus = true, ...restProps }) {
    const [searchParams, setSearchParams] = useSearchParams();
    const gridState = searchParams.get('gridState')
        ? JSON.parse(searchParams.get('gridState'))
        : undefined;
    const page = gridState?.pagination?.page || 0;
    const pageSize = gridState?.pagination?.pageSize || defaultPageSize;
    const initialRowCount = (page + 1) * pageSize;
    const mergedDefaultColDef = useMemo(() => {
        return {
            flex: 1,
            resizable: true,
            suppressHeaderMenuButton: true,
            ...(userDefaultColDef ?? {}),
        };
    }, [userDefaultColDef]);
    const customLoadingCellRenderer = loadingCellRenderer ?? DefaultLoadingRenderer;
    const customLoadingCellRendererParams = loadingCellRendererParams ?? {
        loadingMessage: 'Loading...',
    };
    const paginationPageSize = userPaginationPageSize ?? defaultPageSize;
    return (React.createElement(AgGridReact, { theme: defaultTableTheme, initialState: { ...gridState, partialColumnState: true }, rowModelType: rowModelType, serverSideInitialRowCount: initialRowCount, defaultColDef: mergedDefaultColDef, pagination: pagination, paginationPageSize: paginationPageSize, paginationPageSizeSelector: paginationPageSizeSelector, cacheBlockSize: paginationPageSize, loadingCellRenderer: customLoadingCellRenderer, loadingCellRendererParams: customLoadingCellRendererParams, onStateUpdated: (e) => {
            onStateUpdated(e, persistParamsInUrl, setSearchParams);
        }, suppressCellFocus: suppressCellFocus, ...restProps }));
}
