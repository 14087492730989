/**
 * a function to convert checksum to binary string
 * @param checksum a valid checksum string
 * @returns        a number
 */

export const checksumToBinary = (checksum: string): number => {
  try {
    const decoded = atob(checksum);
    /* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
    const decoded_3 = decoded.charCodeAt(3)!;
    const decoded_2 = decoded.charCodeAt(2)!;
    const decoded_1 = decoded.charCodeAt(1)!;
    const decoded_0 = decoded.charCodeAt(0)!;
    /* eslint-enable */
    const binary =
      decoded_3 + (decoded_2 << 8) + (decoded_1 << 16) + (decoded_0 << 24);
    return binary;
  } catch {
    return -1;
  }
};
