import { gql } from '@apollo/client';

export type READ_SUBPROJECT_SLIDES_VARIABLES = {
  subProjectId: string;
  page: number;
  pageSize: number;
  search: string;
  associations: string[];
  batches: string[];
  case: string;
  objectivePowers: number[];
  scanners: number[];
  stainings: number[];
  tissues: number[];
  diseases: string[];
  sortBy: string | null;
  isAsc: boolean;
};

export const READ_SUBPROJECT_SLIDES = gql`
  query GET_ADMIN_SUBPROJECT_SLIDES(
    $subProjectId: ID!
    $page: Int!
    $pageSize: Int!
    $search: String
    $associations: [ID]!
    $batches: [ID]!
    $case: String
    $objectivePowers: [Float]!
    $scanners: [ID]!
    $stainings: [ID]!
    $tissues: [ID]!
    $diseases: [String]!
    $sortBy: sortSubprojectByValues
    $isAsc: Boolean!
  ) {
    subProject(id: $subProjectId) {
      id
      projectId
      wsisCount
      wsis(
        page: $page
        pageSize: $pageSize
        annotations: all
        overlays: all
        associations: $associations
        batches: $batches
        case: $case
        objectivePowers: $objectivePowers
        scanners: $scanners
        stainings: $stainings
        tissues: $tissues
        searchSlides: $search
        diseases: $diseases
        sortBy: $sortBy
        isAsc: $isAsc
      ) {
        nodes {
          id
          name
          disease
          regionsOfInterestCount
          overlaysCount
          taggersCount
          brightfieldCount
          fluorescenceCount
          isBlackLayer
          patientExternalId
          block
          annotationsCount
          case {
            id
            name
          }
        }
        pageInfo {
          page
          totalPages
          totalElements
        }
      }
    }
  }
`;
