import { Button, VisibleWithScope } from '@aignostics/components';
import { SlideRouteParams } from '@aignostics/viewer-ui';
import React, { ReactElement } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import useUserRole from '../../../hooks/useUserRole';
import { useSelectedOrganizationUuid } from '../Routes/useSelectedOrganizationUuid';

/**
 * Given the current pathname and params it returns the related admin route and
 * vice versa.
 */
const getPath = (
  pathname: string,
  organizationUuid: string,
  { projectId, subProjectId, wsiId }: Partial<SlideRouteParams>
): string | null => {
  // Don't return path for slide view
  if (wsiId) {
    return null;
  }

  const isAdminRoute = pathname.startsWith(`/${organizationUuid}/admin`);

  if (isAdminRoute) {
    if (subProjectId && projectId) {
      return `/${organizationUuid}/project/${projectId}/${subProjectId}`;
    } else if (projectId) {
      return `/${organizationUuid}/project/${projectId}`;
    } else {
      return `/${organizationUuid}`;
    }
  } else if (subProjectId && projectId) {
    return `/${organizationUuid}/admin/projects/${projectId}/subproject/${subProjectId}/wsis`;
  } else if (projectId) {
    return `/${organizationUuid}/admin/projects/${projectId}/subprojects`;
  } else {
    return `/${organizationUuid}/admin/projects`;
  }
};

const getButtonText = (pathname: string, organizationUuid: string) =>
  pathname.startsWith(`/${organizationUuid}/admin`) ? 'View' : 'Edit';

/** This allows admin users to quickly jump between client and admin ui. */
const ViewToggle = (): ReactElement | null => {
  const params = useParams();
  const organizationUuid = useSelectedOrganizationUuid();
  const role = useUserRole();

  const { pathname } = useLocation();
  const to = getPath(pathname, organizationUuid, params);

  if (to) {
    return (
      <VisibleWithScope role={role} scope="admin:access">
        <Button variant="warning" to={to} small>
          {getButtonText(pathname, organizationUuid)}
        </Button>
      </VisibleWithScope>
    );
  }

  return null;
};

export default ViewToggle;
