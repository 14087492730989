import { PopUp, useSnackbarMutations } from '@aignostics/components';
import React, { ReactElement, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { useSelectedOrganizationUuid } from '../../../utils/useSelectedOrganizationUuid';
import { BatchCreateForm } from '../Form/form.state.types';

const $OnboardingDuplicateErrorsInfoModalBody = styled.div`
  padding: ${({ theme }) => theme.spacings[24]}px;
`;

const $OnboardingDuplicateErrorsInfoModalListItem = styled.li`
  width: 100%;
  line-height: 42px;
  list-style-type: disc;
  list-style-position: inside;
  background: ${({ theme }) => theme.colors.light};
  color: ${({ theme }) => theme.colors.text};
  text-align: left;
  padding: ${({ theme }) => `0 ${theme.spacings[12]}px`};
  border-radius: ${({ theme }) => `${theme.spacings[2]}px`};
`;

const $OnboardingDuplicateErrorsInfoModalLabel = styled.span`
  word-break: break-all;
`;

const DuplicatesModal = ({
  slides,
  duplicates,
  updateSlides,
  isOpen,
  onClose,
  closeModal,
}: {
  slides: BatchCreateForm['slides'];
  duplicates: {
    checksum: string;
    name: string;
  }[];
  isOpen: boolean;
  onClose: () => void;
  closeModal: () => void;
  updateSlides: (slides: BatchCreateForm['slides']) => void;
}): ReactElement => {
  const { addSnackbar } = useSnackbarMutations();
  const navigate = useNavigate();
  const organizationUuid = useSelectedOrganizationUuid();
  const [showAffectedFiles, setShowAffectedFiles] = useState<boolean>(false);
  const filteredSlides = useMemo(
    () =>
      slides.filter(
        (file) =>
          !duplicates?.some(
            ({ checksum }) => checksum === file.slideFile.checksum
          )
      ),
    [slides, duplicates]
  );

  return (
    <PopUp
      isVisible={isOpen}
      title={`We found duplicated files`}
      onClose={onClose}
      shouldCloseOnBackdropClick={true}
      isLoading={false}
      secondaryAction={{
        label: 'Change Batch',
        onClick: onClose,
      }}
      primaryAction={{
        label: 'Continue with this Batch',
        onClick: () => {
          if (!filteredSlides.length) {
            void navigate(`/${organizationUuid}/admin/data-onboarding`);
          }

          updateSlides(filteredSlides);
          addSnackbar({
            message: filteredSlides.length
              ? 'Duplicated files were removed'
              : 'Nothing could be added to the batch. All the slides were duplicated.',
            type: filteredSlides.length ? 'success' : 'warning',
            closesAfter: 3000,
          });
          closeModal();
        },
      }}
    >
      <$OnboardingDuplicateErrorsInfoModalBody>
        <>
          <p style={{ margin: '0' }}>
            Some files are already present in this batch. Continuing with the
            selected batch, will ignore those duplicated. To keep all the files,
            create or choose a different batch.
          </p>
          <br />
          <p
            style={{ color: 'blue', cursor: 'pointer', marginBottom: 15 }}
            onClick={() => {
              setShowAffectedFiles(!showAffectedFiles);
            }}
          >
            {showAffectedFiles ? 'Hide' : 'Show'} affected files
          </p>
          <ul>
            {showAffectedFiles &&
              duplicates?.map((duplicate) => (
                <$OnboardingDuplicateErrorsInfoModalListItem
                  key={`${duplicate.name}-errors`}
                >
                  <$OnboardingDuplicateErrorsInfoModalLabel>
                    {duplicate.name}
                  </$OnboardingDuplicateErrorsInfoModalLabel>
                </$OnboardingDuplicateErrorsInfoModalListItem>
              ))}
          </ul>
        </>
      </$OnboardingDuplicateErrorsInfoModalBody>
    </PopUp>
  );
};
export default DuplicatesModal;
