import { curveBasisClosed, lineRadial, range, scaleLinear } from 'd3';
function roundPath(path, precision = 0.1) {
    const query = /[\d.-][\d.e-]*/g;
    return path.replace(query, (n) => (Math.round(parseFloat(n) * (1 / precision)) / (1 / precision)).toString());
}
function generateBlobShape(data) {
    const shapeGenerator = lineRadial()
        .angle((d, i) => (i / data.length) * 2 * Math.PI)
        .curve(curveBasisClosed)
        .radius((d) => d);
    return shapeGenerator(data.map((d) => Math.abs(d)));
}
function generateData(complexity, contrast) {
    const scale = scaleLinear()
        .domain([0, 1])
        .range([50 - ((50 / 12) * contrast - 0.01), 50]);
    // eslint-disable-next-line sonarjs/pseudo-random
    return range(complexity).map(() => scale(Math.random()));
}
export function getOrganicShape(angle, curve) {
    const initialData = generateData(angle, curve);
    return roundPath(`${generateBlobShape(initialData)}Z`);
}
