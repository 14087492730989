import {
  Button,
  Filter,
  FilterConfigs,
  FilterFunction,
  Section,
  VStack,
  filterAssigned,
  getFiltersFromQueryParams,
  useDisclosure,
  useFilteredCollection,
  useFilters,
  useSnackbarMutations,
} from '@aignostics/components';
import { useSetQueryParams } from '@aignostics/hooks';
import { useQuery } from '@apollo/client';
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { CreateAnnotationCategoryModal } from '../../../../../components';
import { SortBy } from '../../../../../hooks';
import {
  AnnotationCategoriesFilterKeys,
  AnnotationCategoriesFilterOptions,
  AnnotationCategoriesSortByOptions,
  AnnotationCategory,
  AnnotationCategoryChange,
  AnnotationCategoryInput,
  AnnotationCategorySet,
} from '../../../../../types';
import { FETCH_ANNOTATION_CATEGORY_SETS } from '../../../../AnnotationManagement';
import { sortAnnotationCategories } from '../utils';
import AdminSubProjectImportAnnotationCategoriesModal from './Admin.SubProject.ImportAnnotationCategoriesModal.component';
import { $AnnotationsCategoriesTableButtonsContainer } from './Admin.Subproject.Categories.styles';
import { AnnotationSubprojectCategoriesTable } from './Table';
import {
  filterModuleAnnotationCategories,
  filterSearchAnnotationCategories,
  filterSetCodeAnnotationCategories,
  filterSetNameAnnotationCategories,
  getAnnotationCategoriesFilters,
  getFilterOptions,
} from './utils';

const FILTER_FUNCTIONS: Record<
  AnnotationCategoriesFilterKeys,
  FilterFunction<AnnotationCategory>
> = {
  search: (annotationCategories, value) =>
    filterSearchAnnotationCategories(annotationCategories)(value as string),
  filterAssigned: (annotationCategories, value) =>
    filterAssigned<AnnotationCategory>(value as string)(annotationCategories),
  filterSetName: (annotationCategories, value) =>
    filterSetNameAnnotationCategories(value as string)(annotationCategories),
  filterSetCode: (annotationCategories, value) =>
    filterSetCodeAnnotationCategories(value as string)(annotationCategories),
  filterModule: (annotationCategories, value) =>
    filterModuleAnnotationCategories(value as string)(annotationCategories),
};
const PAGE_FILTER_CONFIGS: FilterConfigs<AnnotationCategoriesFilterKeys> = {
  search: { fallbackValue: '', type: 'string' },
  filterAssigned: { fallbackValue: 'all', type: 'string' },
  filterSetName: { fallbackValue: '', type: 'string' },
  filterSetCode: { fallbackValue: '', type: 'string' },
  filterModule: { fallbackValue: '', type: 'string' },
};

const AdminSubprojectCategories = ({
  onUpdateAnnotationCategories,
  onCreateAnnotationCategory,
  annotationCategories,
  onImportAnnotationCategoriesFromSubProject,
  sortBy,
  setSortByOptions,
}: {
  onUpdateAnnotationCategories: (
    categoryChanges: AnnotationCategoryChange[]
  ) => void;
  onCreateAnnotationCategory: (
    newAnnotationCategory: AnnotationCategoryInput
  ) => void;
  onImportAnnotationCategoriesFromSubProject: (
    importedCategories: { id: string; color: string }[]
  ) => void;
  annotationCategories: AnnotationCategory[];
  setSortByOptions: (sortByOption: AnnotationCategoriesSortByOptions) => void;
  sortBy: SortBy<AnnotationCategoriesSortByOptions>;
}): ReactElement => {
  const { addSnackbar } = useSnackbarMutations();

  const [isFiltersApplying, setIsFiltersApplying] = useState(false);
  const [filterOptions, setFilterOptions] =
    useState<AnnotationCategoriesFilterOptions>({
      setNames: [],
      setCodes: [],
      modules: [],
    });

  useQuery<{
    annotationCategorySets: {
      nodes: AnnotationCategorySet[];
    };
  }>(FETCH_ANNOTATION_CATEGORY_SETS, {
    onCompleted: (data: {
      annotationCategorySets: {
        nodes: AnnotationCategorySet[];
      };
    }) => {
      const { annotationCategorySets } = data;
      setLocalAnnotationCategorySets(annotationCategorySets.nodes);
    },
  });

  const [localAnnotationCategorySets, setLocalAnnotationCategorySets] =
    useState<AnnotationCategorySet[]>([]);

  const PAGE_FILTER_FIELDS = getAnnotationCategoriesFilters(filterOptions);

  const { filters, filterProps } = useFilters(
    PAGE_FILTER_CONFIGS,
    getFiltersFromQueryParams(PAGE_FILTER_CONFIGS)
  );

  const onSortAnnotationCategories = useCallback(
    (a: AnnotationCategory, b: AnnotationCategory) =>
      sortAnnotationCategories(a, b, sortBy),
    [sortBy]
  );

  const filteredAnnotationCategories = useFilteredCollection(
    FILTER_FUNCTIONS,
    filters,
    annotationCategories,
    onSortAnnotationCategories
  );

  useEffect(() => {
    if (localAnnotationCategorySets) {
      setFilterOptions(
        getFilterOptions({
          annotationCategorySets: localAnnotationCategorySets,
          ...(filters.filterSetName
            ? { selectedSetName: filters.filterSetName as string }
            : null),
          ...(filters.filterSetCode
            ? { selectedSetCode: filters.filterSetCode as string }
            : null),
        })
      );
    }
  }, [
    filterProps,
    localAnnotationCategorySets,
    setFilterOptions,
    filters.filterSetName,
    filters.filterSetCode,
  ]);

  useEffect(() => {
    setIsFiltersApplying(false);
  }, [filteredAnnotationCategories]);

  const createAnnotationCategoryModal = useDisclosure();
  const copyAnnotationCategoriesModal = useDisclosure();

  const queryParams = useMemo(
    () => ({
      ...filters,
    }),
    [filters]
  );

  useSetQueryParams(queryParams);

  const isAllFilteredCategoriesAssigned =
    filteredAnnotationCategories.length > 0 &&
    filteredAnnotationCategories.every(({ inParent }) => inParent);

  const isToggleAllCategoryAssignmentButtonDisabled =
    filterProps.isDefault || filteredAnnotationCategories.length === 0;

  const onToggleAllFilteredCategoriesAssignmentClick = () => {
    const annotationCategoriesUpdates = filteredAnnotationCategories.map(
      ({ color, id }) => ({
        action: isAllFilteredCategoriesAssigned ? 'remove' : 'add',
        annotationCategoryId: id,
        color,
      })
    ) as AnnotationCategoryChange[];
    onUpdateAnnotationCategories(annotationCategoriesUpdates);
    addSnackbar({
      type: 'info',
      message: `You ${isAllFilteredCategoriesAssigned ? 'unassigned' : 'assigned'} ${annotationCategoriesUpdates.length} annotation categories`,
      primaryAction: {
        label: 'Undo',
        onClick: () => {
          onUpdateAnnotationCategories(
            annotationCategoriesUpdates.map((categoryUpdate) => ({
              ...categoryUpdate,
              action: categoryUpdate.action === 'add' ? 'remove' : 'add',
            }))
          );
        },
      },
      closesAfter: 3000,
    });
  };

  const addAnnotationCategorySetInformationToLocalAnnotationCategorySets = (
    newAnnotationCategory: AnnotationCategoryInput
  ) => {
    if (newAnnotationCategory.annotationCategorySet?.kind === 'create') {
      const { code, name, module, id } =
        newAnnotationCategory.annotationCategorySet;
      const modules = module ? [module] : [];
      setLocalAnnotationCategorySets([
        ...localAnnotationCategorySets,
        {
          code,
          name,
          modules,
          id,
        } as unknown as AnnotationCategorySet,
      ]);
    }
    if (
      newAnnotationCategory.annotationCategorySet?.kind === 'assign' &&
      newAnnotationCategory.annotationCategorySet?.module
    ) {
      const id = newAnnotationCategory.annotationCategorySet.id;
      const existingAnnotationCategorySet = localAnnotationCategorySets.find(
        (set) => set.id === id
      );
      if (existingAnnotationCategorySet) {
        const { code, name, id } = existingAnnotationCategorySet;
        const { moduleName, moduleId } =
          newAnnotationCategory.annotationCategorySet.module;
        setLocalAnnotationCategorySets([
          ...localAnnotationCategorySets.filter((set) => set.id !== id),
          {
            id,
            name,
            code,
            modules: [
              ...existingAnnotationCategorySet.modules,
              { moduleName, moduleId },
            ],
          },
        ]);
      }
    }
  };

  const previousFilterSetNameValue = useRef<string>(
    filters.filterSetName as string
  );

  return (
    <>
      <Section
        title={`${filteredAnnotationCategories.length} Categories`}
        description="Assign annotation categories and set colors."
        style={{
          padding: `0`,
        }}
      >
        <VStack style={{ position: 'relative' }} spacing="line">
          <Filter
            isOpen={false}
            fields={PAGE_FILTER_FIELDS}
            {...filterProps}
            onReset={() => {
              setIsFiltersApplying(true);
              filterProps.onReset();
            }}
            onChange={(filters) => {
              setIsFiltersApplying(true);
              if (
                previousFilterSetNameValue.current !== filters.filterSetName
              ) {
                previousFilterSetNameValue.current =
                  filters.filterSetName as string;
                filterProps.onChange({
                  ...filters,
                  filterSetCode: '',
                  filterModule: '',
                });
              } else {
                filterProps.onChange(filters);
              }
            }}
          />
          <$AnnotationsCategoriesTableButtonsContainer>
            <Button
              onClick={() => {
                if (copyAnnotationCategoriesModal.isOpen) {
                  copyAnnotationCategoriesModal.close();
                } else {
                  copyAnnotationCategoriesModal.open();
                }
              }}
              small
            >
              Import categories
            </Button>
            <Button
              onClick={() => {
                if (createAnnotationCategoryModal.isOpen) {
                  createAnnotationCategoryModal.close();
                } else {
                  createAnnotationCategoryModal.open();
                }
              }}
              small
            >
              Create category
            </Button>
          </$AnnotationsCategoriesTableButtonsContainer>
          <AnnotationSubprojectCategoriesTable
            filteredAnnotationCategories={filteredAnnotationCategories}
            isAllFilteredCategoriesAssigned={isAllFilteredCategoriesAssigned}
            isFiltersApplying={isFiltersApplying}
            isToggleAllCategoryAssignmentButtonDisabled={
              isToggleAllCategoryAssignmentButtonDisabled
            }
            onToggleAllFilteredCategoriesAssignmentClick={
              onToggleAllFilteredCategoriesAssignmentClick
            }
            onUpdateAnnotationCategories={onUpdateAnnotationCategories}
            setSortByOptions={setSortByOptions}
            sortBy={sortBy}
          />
        </VStack>
      </Section>

      {localAnnotationCategorySets && (
        <CreateAnnotationCategoryModal
          isOpen={createAnnotationCategoryModal.isOpen}
          onClose={createAnnotationCategoryModal.close}
          onCreateAnnotationCategory={(newAnnotationCategory) => {
            if (newAnnotationCategory.annotationCategorySet?.kind !== 'unset') {
              addAnnotationCategorySetInformationToLocalAnnotationCategorySets(
                newAnnotationCategory
              );
            }
            onCreateAnnotationCategory(newAnnotationCategory);
            createAnnotationCategoryModal.close();
          }}
          annotationCategorySets={localAnnotationCategorySets}
          annotationCategoryNames={annotationCategories.map(({ name }) => name)}
        />
      )}
      <AdminSubProjectImportAnnotationCategoriesModal
        isVisible={copyAnnotationCategoriesModal.isOpen}
        onClose={() => {
          copyAnnotationCategoriesModal.close();
        }}
        onImportAnnotationCategoriesFromSubProject={(importedCategories) => {
          onImportAnnotationCategoriesFromSubProject(importedCategories);
        }}
      />
    </>
  );
};

export default AdminSubprojectCategories;
