import React from 'react';
import styled from 'styled-components';
import Button from '../Button';
import Icon from '../Icon';
import { $Input, $Select } from './OnboardingReadOnlyList.styles';
import { $OnboardingReadOnlyListHeaderWrapper } from './OnboardingReadOnlyListHeader.styles';
import { ONBOARDING_READ_ONLY_LIST_FIELDS_GRID_IDS } from './OnboardingReadOnlyListItem.styles';
const $ToggleExtraFieldsButton = styled(Button) `
  grid-area: ${ONBOARDING_READ_ONLY_LIST_FIELDS_GRID_IDS.deleteAction};
  align-self: end;
  padding: ${({ theme }) => `0 ${theme.spacings[4]}px`};
  width: ${({ theme }) => `${theme.spacings[24]}px`};
`;
export const OnboardingReadOnlyListHeader = ({ showExtraFields, onToggleExtraFieldsClick, }) => {
    return (React.createElement($OnboardingReadOnlyListHeaderWrapper, { showExtraFields: showExtraFields },
        React.createElement($Input, { id: "patientExternalId", label: "Patient ID", value: "-", style: {
                gridArea: ONBOARDING_READ_ONLY_LIST_FIELDS_GRID_IDS.patientExternalId,
            }, disabled: true }),
        React.createElement($Input, { id: "caseId", label: "Case ID*", value: "-", style: {
                gridArea: ONBOARDING_READ_ONLY_LIST_FIELDS_GRID_IDS.caseId,
            }, disabled: true }),
        React.createElement($Input, { id: "block", label: "Block", value: "-", style: {
                gridArea: ONBOARDING_READ_ONLY_LIST_FIELDS_GRID_IDS.block,
            }, disabled: true }),
        React.createElement($Input, { id: "section", label: "Section", value: "-", style: {
                gridArea: ONBOARDING_READ_ONLY_LIST_FIELDS_GRID_IDS.section,
            }, disabled: true }),
        React.createElement($Select, { id: "scanner", label: "Scanner*", value: "-", options: [{ value: '-', label: '-' }], style: {
                gridArea: ONBOARDING_READ_ONLY_LIST_FIELDS_GRID_IDS.scanner,
            }, disabled: true }),
        React.createElement($Select, { id: "tissue", label: "Localization*", value: "-", options: [{ value: '-', label: '-' }], style: {
                gridArea: ONBOARDING_READ_ONLY_LIST_FIELDS_GRID_IDS.tissue,
            }, disabled: true }),
        React.createElement($Select, { id: "staining", label: "Staining*", value: "-", options: [{ value: '-', label: '-' }], style: {
                gridArea: ONBOARDING_READ_ONLY_LIST_FIELDS_GRID_IDS.staining,
            }, disabled: true }),
        React.createElement($Select, { id: "disease", label: "Disease*", value: "-", options: [{ value: '-', label: '-' }], style: {
                gridArea: ONBOARDING_READ_ONLY_LIST_FIELDS_GRID_IDS.disease,
            }, disabled: true }),
        React.createElement($Select, { id: "sample-preparation", label: "Preparation Type*", value: "-", options: [{ value: '-', label: '-' }], style: {
                gridArea: ONBOARDING_READ_ONLY_LIST_FIELDS_GRID_IDS.samplePreparation,
            }, disabled: true }),
        React.createElement($Select, { id: "sample-type", label: "Sample Type*", value: "-", options: [{ value: '-', label: '-' }], style: {
                gridArea: ONBOARDING_READ_ONLY_LIST_FIELDS_GRID_IDS.sampleType,
            }, disabled: true }),
        React.createElement($Select, { id: "morphology", label: "Morphology*", value: "-", options: [{ value: '-', label: '-' }], style: {
                gridArea: ONBOARDING_READ_ONLY_LIST_FIELDS_GRID_IDS.morphology,
            }, disabled: true }),
        React.createElement($Select, { id: "cancerSite", label: "Cancer Site*", value: "-", options: [{ value: '-', label: '-' }], style: {
                gridArea: ONBOARDING_READ_ONLY_LIST_FIELDS_GRID_IDS.cancerSite,
            }, disabled: true }),
        React.createElement($ToggleExtraFieldsButton, { "aria-label": "toggle display extra table fields", "aria-expanded": showExtraFields, onClick: onToggleExtraFieldsClick, small: true },
            React.createElement(Icon, { icon: !showExtraFields ? 'TableExpand' : 'TableCollapse' }))));
};
export const $OnboardingReadOnlyListFixedColumnHeader = styled.div `
  width: 100%;
  height: ${({ theme }) => theme.spacings[72]}px;
  padding: ${({ theme }) => theme.spacings[4]}px 0;
  background: ${({ theme }) => theme.colors.light};
  position: sticky;
  box-sizing: content-box;
`;
