import { Reorder } from 'framer-motion';
import React from 'react';
import { $ListHeader } from '../ColorSelectorModal/ColorSelectorModal.styles';
import { Tag } from '../ColorSelectorModal/ColorSelectorModal.types';
import { TagItem } from '../ColorSelectorModal/TagItem';
import { $ModalTitle } from './interactiveOverlay.modal.styles';

export interface InteractiveOverlayClassesProps {
  classes: Tag[];
  setInteractiveOverlayClasses: React.Dispatch<React.SetStateAction<Tag[]>>;
  updateInteractiveOverlayClassColor: (
    index: number
  ) => (color: string) => void;
  loading: boolean;
}

export const InteractiveOverlayClasses = ({
  classes,
  updateInteractiveOverlayClassColor,
  setInteractiveOverlayClasses,
  loading,
}: InteractiveOverlayClassesProps): React.ReactElement => {
  return (
    <>
      <$ModalTitle>Classes</$ModalTitle>
      <$ListHeader>
        <p>Class name</p>
        <p>Class color</p>
      </$ListHeader>
      <Reorder.Group
        style={{ width: '100%' }}
        values={classes}
        onReorder={(newOrder) => {
          setInteractiveOverlayClasses(
            newOrder.map((item, index) => ({
              ...item,
              sort: index,
            }))
          );
        }}
      >
        {!loading &&
          classes.map((tag: Tag, index: number) => (
            <Reorder.Item key={tag.id} value={tag}>
              <TagItem
                tag={tag}
                updateColor={updateInteractiveOverlayClassColor(index)}
              />
            </Reorder.Item>
          ))}
      </Reorder.Group>
    </>
  );
};
