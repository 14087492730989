import { Button, PopUp, useDisclosure } from '@aignostics/components';
import React, { ReactElement, useState } from 'react';

import { OnboardingBatchStatus } from '../../graphql/api.types';
import { useDeleteOnboardingBatch } from '../../hooks/useDeleteOnboardingBatch';
import { useValidateOnboardingBatch } from '../../hooks/useValidateOnboardingBatch';

export const mapBatchStatusToActionLabel: Record<
  OnboardingBatchStatus,
  string
> = {
  pending: 'Process Slides',
  completed: 'Assign to project',
  failed: 'Remove',
  uploading: 'Remove',
  validating: '',
  'completed-with-errors': '',
};

interface OnboardingBatchActionButtonProps {
  batchStatus: OnboardingBatchStatus;
  batchId: string;
  onAssignToProject: (batchId: string) => void;
  ariaLabel: string;
}

const OnboardingBatchActionButton = ({
  batchStatus,
  batchId,
  onAssignToProject,
  ariaLabel,
}: OnboardingBatchActionButtonProps): ReactElement | null => {
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const { validateOnboardingBatch, isValidating } = useValidateOnboardingBatch({
    onSuccess: () => {
      setButtonDisabled(true);
    },
    batchId,
  });
  const processSlidesDialog = useDisclosure();
  const processRemoveDialog = useDisclosure();
  const { deleteOnboardingBatch, isDeleteLoading } = useDeleteOnboardingBatch();

  switch (batchStatus) {
    case 'pending':
      return (
        <>
          <Button
            small
            aria-label={ariaLabel}
            type="button"
            variant="primary"
            disabled={isButtonDisabled || isValidating}
            onClick={processSlidesDialog.open}
          >
            {mapBatchStatusToActionLabel[batchStatus]}
          </Button>
          <PopUp
            isVisible={processSlidesDialog.isOpen}
            title={`We are processing your slides. \n It might take some time...`}
            onClose={() => {
              processSlidesDialog.close();
            }}
            primaryAction={{
              label: 'Continue',
              onClick: () => {
                processSlidesDialog.close();
                validateOnboardingBatch();
              },
            }}
            secondaryAction={{
              label: 'Cancel',
              onClick: () => {
                processSlidesDialog.close();
              },
            }}
          >
            <p style={{ margin: '0' }}>
              Depending on the number of slides and their quality, it can be
              time-consuming. Once this step has started, the slides info cannot
              be edited anymore.
            </p>
          </PopUp>
        </>
      );
    case 'completed':
      return (
        <Button
          small
          aria-label={ariaLabel}
          type="button"
          variant="primary"
          disabled={isButtonDisabled}
          onClick={() => {
            onAssignToProject(batchId);
          }}
        >
          {mapBatchStatusToActionLabel[batchStatus]}
        </Button>
      );
    case 'failed':
    case 'uploading':
      return (
        <>
          <Button
            small
            aria-label={ariaLabel}
            type="button"
            variant="primaryOutline"
            disabled={isButtonDisabled || isValidating}
            onClick={processRemoveDialog.open}
          >
            {mapBatchStatusToActionLabel[batchStatus]}
          </Button>
          <PopUp
            isVisible={processRemoveDialog.isOpen}
            title={`Attention`}
            onClose={() => {
              processRemoveDialog.close();
            }}
            shouldCloseOnBackdropClick={true}
            isLoading={isDeleteLoading}
            secondaryAction={{
              label: 'No',
              onClick: () => {
                processRemoveDialog.close();
              },
            }}
            primaryAction={{
              label: 'Yes',
              onClick: () => {
                void deleteOnboardingBatch(batchId).then(() => {
                  processRemoveDialog.close();
                });
              },
            }}
          >
            <p style={{ margin: '0' }}>
              Are you sure you want to delete these slides?
            </p>
          </PopUp>
        </>
      );
    case 'validating':
    case 'completed-with-errors':
      return null;
  }
};
export default OnboardingBatchActionButton;
