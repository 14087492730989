import { OneLiner } from '@aignostics/components';
import {
  AccessorKeyColumnDef,
  createColumnHelper,
} from '@tanstack/react-table';
import React from 'react';
import { Link } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { StaticOverlayAll } from '../../AdminSubprojectAssignStaticOverlays/AdminSubprojectAssignStaticOverlays.types';
import { TableData } from '../Overlays.types';

export function useBaseColumns({
  organizationUuid,
  projectId,
  subprojectId,
}: {
  organizationUuid: string;
  projectId: string;
  subprojectId: string;
}): AccessorKeyColumnDef<TableData<StaticOverlayAll>, string>[] {
  const theme = useTheme();
  const columnHelper = createColumnHelper<TableData<StaticOverlayAll>>();

  return [
    columnHelper.accessor('name', {
      cell: ({ row, getValue }) => {
        return (
          <OneLiner>
            <Link
              to={`/${organizationUuid}/project/${projectId}/${subprojectId}/wsi/${row.original.id}`}
            >
              {getValue()}
            </Link>
          </OneLiner>
        );
      },
      header: 'Slide',
      size: theme.spacings.aside,
    }),
  ];
}
