import {
  Button,
  Icon,
  Input,
  Loader,
  useSnackbarMutations,
  VStack,
} from '@aignostics/components';
import { checkCode, confirmResetPassword } from '@aignostics/core';
import { Link2 } from '@aignostics/icons';
import { Flex } from '@radix-ui/themes';
import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { useValidation } from './Login.component';
import {
  $FormContainer,
  $FormContainerWithBackground,
  $LoginMain,
  $LoginMainHeader,
  $LoginMainSubHeader,
  $LoginWrapper,
  $Logo,
} from './Login.styles';
import {
  $PasswordRule,
  $PasswordRules,
  $PasswordRulesTitle,
  $ResetPasswordForm,
  $ResetPasswordMessageDescription,
  $ResetPasswordMessageTitle,
  $ResetPasswordSent,
} from './ResetPassword.styles';

type PasswordResetStatus = 'loader' | 'initial' | 'success' | 'error-link';
const ResetPassword = ({
  logo,
  loginBackgroundSrc,
}: {
  logo: JSX.Element;
  loginBackgroundSrc: string | undefined;
}): ReactElement => {
  const [password, changePassword] = useValidation('');
  const [email, setEmail] = useState<string | null>('');
  const [passwordVisible, setPasswordVisible] = useState<boolean>(false);

  const [passwordResetStatus, setPasswordResetStatus] =
    useState<PasswordResetStatus>('initial');
  const urlParams = new URLSearchParams(window.location.search);
  const { addSnackbar } = useSnackbarMutations();

  const navigate = useNavigate();
  const oobCode = urlParams.get('oobCode');

  const theme = useTheme();

  useEffect(() => {
    if (!oobCode) {
      void navigate('/login/reset-password', { replace: true });
      return;
    }
    setPasswordResetStatus('loader');
    checkCode(oobCode)
      .then((r) => {
        if (r.data.email) setEmail(r.data.email);
        setPasswordResetStatus('initial');
      })
      .catch(() => {
        setPasswordResetStatus('error-link');
        setTimeout(() => {
          void navigate('/login/reset-password', { replace: true });
        }, 2000);
      });
  }, [navigate, oobCode]);

  const isValidPassword = useMemo(() => {
    if (!password) {
      return {
        isValid: null,
        rulesStatus: {
          hasGoodLength: null,
          hasLowerCase: null,
          hasUpperCase: null,
          hasDigits: null,
          hasSymbols: null,
          isNotEmail: null,
        },
      };
    }

    const hasGoodLength = password.length >= 8;
    const hasLowerCase = /[a-z]/.test(password);
    const hasUpperCase = /[A-Z]/.test(password);
    const hasDigits = /\d/.test(password);
    const hasSymbols = /\W/.test(password);
    const isNotEmail = password !== email;

    const rulesStatus = {
      hasGoodLength,
      hasLowerCase,
      hasUpperCase,
      hasDigits,
      hasSymbols,
      isNotEmail,
    };

    const isValid = Object.values(rulesStatus).every((r) => r);

    return { isValid, rulesStatus };
  }, [email, password]);

  const handleSubmit = (e: React.FormEvent) => {
    if (!oobCode) return;
    e.preventDefault();
    confirmResetPassword(oobCode, password)
      .then(() => {
        setPasswordResetStatus('success');

        setTimeout(() => {
          void navigate('/login?expanded=true', { replace: true });
        }, 2000);
      })
      .catch((error) => {
        addSnackbar({ type: 'error', message: error.message });
      });
  };

  const displayComponent = () => {
    switch (passwordResetStatus) {
      case 'loader':
        return <Loader color="white" />;
      case 'initial':
        return (
          <$LoginMain>
            <$LoginMainHeader>Set new password*</$LoginMainHeader>
            {email && (
              <$LoginMainSubHeader>{`for ${email}`}</$LoginMainSubHeader>
            )}
            <$ResetPasswordForm
              id="reset-password-form"
              onSubmit={handleSubmit}
              noValidate={true}
            >
              <$FormContainer>
                <VStack spacing="base" alignItems="stretch">
                  <Input
                    id="password"
                    sizeVariant="large"
                    key="aignx-password"
                    name="aignx-password"
                    type={passwordVisible ? 'text' : 'password'}
                    autoComplete="current-password"
                    aria-label="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => {
                      changePassword(e);
                    }}
                    innerIcon={passwordVisible ? 'EyeOff' : 'Eye'}
                    innerIconProps={{
                      size: 'input',
                      style: { cursor: 'pointer' },
                      onClick: () => {
                        setPasswordVisible((previous) => !previous);
                      },
                    }}
                  />
                  <Button
                    id="user-sign-in-button"
                    type="submit"
                    disabled={!isValidPassword.isValid}
                  >
                    Save password
                  </Button>
                </VStack>
              </$FormContainer>

              <$FormContainerWithBackground>
                <$PasswordRulesTitle>
                  * Your new password must:
                </$PasswordRulesTitle>
                <$PasswordRules>
                  <$PasswordRule
                    isValid={isValidPassword.rulesStatus.hasGoodLength}
                  >
                    be at least 8 characters in length
                  </$PasswordRule>
                  <$PasswordRule
                    isValid={isValidPassword.rulesStatus.hasUpperCase}
                  >
                    be at least 1 uppercase character
                  </$PasswordRule>
                  <$PasswordRule
                    isValid={isValidPassword.rulesStatus.hasLowerCase}
                  >
                    contain at least 1 lowercase character
                  </$PasswordRule>
                  <$PasswordRule
                    isValid={isValidPassword.rulesStatus.hasDigits}
                  >
                    contain at least 1 number{' '}
                  </$PasswordRule>
                  <$PasswordRule
                    isValid={isValidPassword.rulesStatus.hasSymbols}
                  >
                    contain at least 1 special character (~!@...)
                  </$PasswordRule>
                  <$PasswordRule
                    isValid={isValidPassword.rulesStatus.isNotEmail}
                  >
                    not be identical to your email
                  </$PasswordRule>
                </$PasswordRules>
              </$FormContainerWithBackground>
            </$ResetPasswordForm>
          </$LoginMain>
        );
      case 'success':
        return (
          <$ResetPasswordSent>
            <Icon
              icon="CheckCircle"
              color="white"
              size="large"
              strokeWidth={2}
            />
            <$ResetPasswordMessageTitle>
              Password successfully set
            </$ResetPasswordMessageTitle>
            <$ResetPasswordMessageDescription>
              Redirecting to our Sign In page...
            </$ResetPasswordMessageDescription>
          </$ResetPasswordSent>
        );
      case 'error-link':
        return (
          <$ResetPasswordSent>
            <Link2 stroke="white" />
            <$ResetPasswordMessageTitle>
              Invalid or expired Link
            </$ResetPasswordMessageTitle>
            <$ResetPasswordMessageDescription>
              Please request new link
            </$ResetPasswordMessageDescription>
          </$ResetPasswordSent>
        );
    }
  };

  return (
    <Flex
      direction="column"
      style={{
        width: '100vw',
        position: 'relative',
        zIndex: '0',
        height: '100dvh',
        backgroundColor: theme.colors.primary,
        backgroundImage: `url(${loginBackgroundSrc})`,
        backgroundSize: 'cover',
      }}
    >
      <div
        style={{
          overflow: 'hidden',
          flexGrow: '1',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div
          style={{
            padding: `${theme.spacings[56]}px ${theme.spacings[72]}px 0 `,
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <$Logo>{logo}</$Logo>
        </div>
        <$LoginWrapper>{displayComponent()}</$LoginWrapper>
      </div>
    </Flex>
  );
};

export default ResetPassword;
