import React from 'react';
import { useTheme } from 'styled-components';
import Loader from '../Loader';
export const DefaultLoadingRenderer = (props) => {
    const theme = useTheme();
    if (props.node.failedLoad) {
        return (React.createElement("div", { className: "ag-custom-loading-cell", style: { paddingLeft: `${theme.spacings[8]}px`, lineHeight: '25px' } },
            React.createElement("span", null, " Data failed to load")));
    }
    return (React.createElement("div", { className: "ag-custom-loading-cell", style: { paddingLeft: '10px', lineHeight: '25px' } },
        React.createElement(Loader, { sizeContainer: 8 }),
        React.createElement("span", { style: { paddingLeft: `${theme.spacings[8]}px` } }, props.loadingMessage ?? 'Loading...')));
};
