import {
  Container,
  FieldValue,
  Form,
  FormField,
  Section,
  useSnackbarMutations,
} from '@aignostics/components';
import { OrganizationRole, OrganizationUser } from '@aignostics/core';
import { gql, useMutation } from '@apollo/client';
import React, { ReactElement, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { Project } from '../../types';
import { DELETE_USER_MUTATION } from './DELETE_USER';
import { DeleteUserSection } from './DeleteSection';
import FETCH_USERS_LIST from './FETCH_USERS_LIST';
import FETCH_USER_DETAILS from './FETCH_USER_DETAILS';
import { UPDATE_USER_DETAILS } from './UPDATE_USER_DETAILS';

const updateUserFragment = gql`
  fragment UPDATE_USER on User {
    name
    roleName
    isDisabled
  }
`;

export interface ProjectItem
  extends Pick<
    Project,
    | 'id'
    | 'name'
    | 'usersCount'
    | 'subProjectsCount'
    | 'createdAt'
    | 'updatedAt'
    | 'isVisible'
  > {
  createdBy: string | null;
}

export interface UserWithProjects extends OrganizationUser {
  projects: ProjectItem[];
}

interface AdminUserDetailsProps {
  user: UserWithProjects;
  organizationUuid: string;
  currentUserRole: OrganizationRole;
}

const AdminUserDetails = ({
  user,
  organizationUuid,
  currentUserRole,
}: AdminUserDetailsProps): ReactElement => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { addSnackbar } = useSnackbarMutations();

  const [updateUserDetails, { loading: updateLoading, error: updateError }] =
    useMutation(UPDATE_USER_DETAILS, {
      awaitRefetchQueries: true,
      refetchQueries: [FETCH_USER_DETAILS],
    });

  const [deleteUser, { loading: deletingUser, error: deleteUserError }] =
    useMutation<unknown, { userId: string }>(DELETE_USER_MUTATION, {
      onCompleted: () => {
        addSnackbar({
          type: 'success',
          message: 'User deleted',
          closesAfter: 500,
        });
        void navigate(`/${organizationUuid}/admin/users`);
      },
    });

  const canEdit = currentUserRole.scopes['user:edit'] && !user.deleted;

  // Submit the user form
  const handleUserEdited = useCallback(
    (values: Record<string, FieldValue>) => {
      if (user) {
        const update = {
          id: user.id,
          name: values.name,
          roleName: values.roleName,
          isDisabled: values.isDisabled,
        };
        void updateUserDetails({
          variables: { user: update },
          update: (cache) => {
            // Update cached user entity
            cache.writeFragment({
              id: `User:${user.id}`,
              fragment: updateUserFragment,
              data: update,
            });
          },
        });
      }
    },
    [updateUserDetails, user]
  );

  const handleUserDeleted = useCallback(
    (user: UserWithProjects) => {
      void deleteUser({
        variables: { userId: user.id },
        refetchQueries: [
          {
            query: FETCH_USERS_LIST,
            variables: {
              page: 1,
              pageSize: 20,
              search: '',
              roleName: 'all',
              isDisabled: 'all',
            },
          },
        ],
        update: (cache) => {
          cache.evict({ id: `User:${user.id}` });
          cache.gc();
        },
      });
    },
    [deleteUser]
  );

  const formFields = useMemo<FormField[]>(
    () => [
      {
        label: 'ID',
        id: 'id',
        value: user?.id || '',
        type: 'text',
        disabled: true,
      },
      {
        label: 'Name',
        id: 'name',
        value: user?.name || '',
        type: 'text',
        disabled: !canEdit,
        isRequired: true,
      },
      {
        label: 'Email',
        id: 'email',
        value: user?.email || '',
        type: 'text',
        disabled: true,
      },
      {
        label: 'Role',
        id: 'roleName',
        value: user?.role.roleName || '',
        type: 'select',
        disabled:
          user?.role.roleName === 'admin' &&
          !currentUserRole.scopes['user:toggleAdmin'],
        options: [
          { value: 'viewer', label: 'Viewer' },
          { value: 'developer', label: 'Developer' },
          { value: 'editor', label: 'Editor' },
          {
            value: 'admin',
            label: 'Admin',
            disabled: !currentUserRole.scopes['user:toggleAdmin'],
          },
        ],
      },
      {
        label: 'Disabled',
        id: 'isDisabled',
        value: user?.role.isDisabled ?? false,
        type: 'checkbox',
      },
    ],
    [canEdit, user, currentUserRole]
  );
  return (
    <Section error={updateError || deleteUserError}>
      <Container size="small">
        <Form
          onSubmit={handleUserEdited}
          loading={updateLoading}
          fields={formFields}
          reset
        />
        {user ? (
          <div style={{ marginTop: theme.spacings.touch }}>
            <DeleteUserSection
              user={user}
              loading={deletingUser}
              onDelete={handleUserDeleted}
            />
          </div>
        ) : null}
      </Container>
    </Section>
  );
};

export default AdminUserDetails;
