import { Checkbox } from '@aignostics/components';
import React, { useEffect, useState } from 'react';
import { Wsi } from '../../../../types';

export interface OverlaySlideCheckboxProps {
  overlayName: string;
  isCurrentWsiAssigned: boolean;
  wsi: Pick<Wsi, 'id' | 'name'>;
  onInteractiveOverlayWsiToggled: (checked: boolean) => void;
}

export const OverlaySlideCheckbox = ({
  overlayName,
  isCurrentWsiAssigned,
  wsi,
  onInteractiveOverlayWsiToggled,
}: OverlaySlideCheckboxProps): React.ReactElement => {
  const [checked, setChecked] = useState(isCurrentWsiAssigned);
  useEffect(() => {
    setChecked(isCurrentWsiAssigned);
  }, [isCurrentWsiAssigned]);

  const onCheckboxChange = (checked: boolean) => {
    onInteractiveOverlayWsiToggled(checked);
    setChecked(checked);
  };

  return (
    <Checkbox
      id={wsi.id}
      onCheckedChange={onCheckboxChange}
      checked={checked}
      aria-label={`Toggle ${overlayName} for wsi ${wsi.name}`}
    />
  );
};
