import { useEventListener } from '@aignostics/hooks';
import { motion, useMotionValue } from 'framer-motion';
import React, { useRef } from 'react';
import { createPortal } from 'react-dom';
const node = document.getElementById('tooltip');
const TooltipModal = ({ children, xOffset, yOffset, }) => {
    const ref = useRef(null);
    const x = useMotionValue(0);
    const y = useMotionValue(0);
    useEventListener('pointermove', ({ pageX, pageY }) => {
        const elementWidth = ref.current?.offsetWidth || 0;
        const halfElementWidth = elementWidth / 2;
        const xPosDefault = pageX - halfElementWidth;
        const xPosMin = Math.max(xPosDefault, 0);
        const xPosMax = window.innerWidth - elementWidth;
        const xPos = Math.min(xPosMin, xPosMax);
        x.set(xPos);
        y.set(pageY + 10);
    });
    return (node &&
        createPortal(React.createElement(motion.div, { ref: ref, style: {
                x,
                y,
                position: 'fixed',
                top: xOffset ?? 0,
                left: yOffset ?? 0,
                pointerEvents: 'none',
                zIndex: 1000,
            } }, children), node));
};
export default TooltipModal;
