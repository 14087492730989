import { Theme } from '@aignostics/theme';
import { z } from 'zod';

const BUILD_ENVS = [
  'feature',
  'develop',
  'staging',
  'production',
  'test',
  'local',
] as const;
export const buildEnvSchema = z.enum(BUILD_ENVS);
export type BuildEnv = z.infer<typeof buildEnvSchema>;

interface FirebaseCredentials {
  apiKey: string;
  authDomain: string;
  databaseURL: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
  measurementId: string;
}

interface Sentry {
  /** Sentry dsn for client environment. */
  dsn: string;
}

interface PortalServices {
  rasterTileServerUrl: string;
  vectorTileServerUrl: string;
  apiUrl: string;
}

export interface Sources {
  header?: {
    img?: string;
  };
}

export interface Texts {
  home: {
    title: string;
    description: string;
  };
}

export interface AppBranding {
  name: string;
  brand: string;
  logo: JSX.Element;
  banner?: JSX.Element;
  texts: Texts;
  theme: Theme;
  favicon: string;
  loginLogo?: JSX.Element;
  loginBackgroundSrc?: string;
}

export interface AppConfig extends AppBranding {
  firebaseCredentials: FirebaseCredentials;
  name: string;
  version: string;
  origin: string;
  routerBasename: string;
  nodeEnv: string;
  buildEnv: BuildEnv;
  sentry: Sentry;
  portalServices: PortalServices;
  tracking: {
    domain: string;
  };
  otelCollectorEndpoint: string;
}
