import React from 'react';
import { RadioButton } from '../RadioButton';
import { HeaderArrowIcon, HeaderCell, HeaderIconWrapper, RadioButtonWrapper, SelectMenuWrapper, SortableHeader, } from './TableComponent.styles';
import SelectMenu from './components/SelectMenu/SelectMenu.component';
export const SortableTableHeader = ({ enableSelection, sortDirection, onSorting, header, }) => {
    const sortAreaLabel = sortDirection
        ? sortDirection
            ? 'ascending'
            : 'descending'
        : 'none';
    const isDisabled = Boolean(header.disableSorting);
    return (React.createElement(HeaderCell, { key: header.id, enableSelection: enableSelection, maxWidth: header.maxWidth, minWidth: header.minWidth, align: header.align, "aria-label": `Header column ${header.id}` }, !header.disableSorting ? (React.createElement(SelectMenu, { menuItems: React.createElement(SelectMenuWrapper, null,
            React.createElement(RadioButtonWrapper, { role: "button", "aria-label": `${header.id} Ascending sorting`, onClick: () => {
                    if (onSorting) {
                        onSorting({
                            column: header.id,
                            direction: 'asc',
                        });
                    }
                } },
                React.createElement(RadioButton, { id: "asc", label: "Ascending", name: "ascSorting", checked: sortDirection === 'asc', value: "selectPage", labelPosition: 'right', small: true })),
            React.createElement(RadioButtonWrapper, { role: "button", "aria-label": `${header.id} Descending sorting`, onClick: () => {
                    if (onSorting) {
                        onSorting({
                            column: header.id,
                            direction: 'desc',
                        });
                    }
                } },
                React.createElement(RadioButton, { id: "desc", label: "Descending", name: "descSorting", checked: sortDirection === 'desc', value: "descSorting", labelPosition: 'right', small: true })),
            React.createElement(RadioButtonWrapper, { role: "button", "aria-label": `${header.id} Disable sorting`, onClick: () => {
                    onSorting(null);
                } },
                React.createElement(RadioButton, { id: "noSorting", label: "None", name: `NoSorting_${header.id}`, checked: sortDirection !== 'asc' && sortDirection !== 'desc', value: "None", labelPosition: 'right', small: true }))) },
        React.createElement(SortableHeader, { role: "button", "aria-sort": sortAreaLabel, isDisabled: isDisabled },
            header.label,
            !header.disableSorting && (React.createElement(HeaderIconWrapper, null,
                React.createElement(HeaderArrowIcon, { icon: 'ArrowDown', size: 'xsmall' })))))) : (React.createElement(SortableHeader, { role: "button", "aria-sort": sortAreaLabel, isDisabled: header.disableSorting }, header.label))));
};
